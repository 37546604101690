import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import NavButton from "./NavButton";
import Grow from "@mui/material/Grow";
import { useState, useRef, useLayoutEffect } from "react";
import NotificationMenu from "./components/NotificationMenu";
import isAuthenticated from "../Utils/isAuthenticated";
import GiveRoleForm from "../../pages/Access/GiveRoleForm";
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";
import { fetchResultsEmailNotifications } from "../Api/Getters";
import { useQuery } from "react-query";

const reportButtons = [
	"/My-SPOT-Reports",
	"/My-USRI-Reports",
	"/SPOT-DDC-Reports",
	"/SPOT-Student-Search",
	"/USRI-Student-Search",
];

const SPOTButtons = [
	"/ViewBlocks",
	"/DepartmentSummary",
	"/SPOT-Ordering",
	"/SPOT-Grades-Submitted",
];

const multipleChoiceTestButtons = ["/request-for-service"];

const utilitiesButtons = ["/AddBlocks", "/AddItem", "/AddUser", "/InstancedClasses"];

const adminButtonRoutes = [
	"/SPOTnotSetup",
	"/TrackRequisitions",
	"/AdminUtils",
	"/ReportGeneration",
	"/SPOT-Faculty-Department-Search",
	"/My-USRI-Reports",
	"/USRI-DDC-Search",
	"/USRI-Student-Search",
	"/MidtermView",
];

const studentRoutes = [
	"/SPOT-Student-Search",
	"/SPOT-Student-Search-Results",
	"/USRI-Student-Search",
	"/USRI-Student-Search-Results",
	"/ReportView",
	"/USRI-Student-Search",
];

const instructorRoutes = [
	"/My-SPOT-Reports",
	"/My-USRI-Reports",
	"/UserSettings",
	"/request-for-service",
	"/success",
	"/newordisplay",
	"/display",
	"/FAQ",
	"/ReportView",
];

const ddcRoutes = [
	"/SPOT-DDC-Reports",
	"/USRI-DDC-Search-Results",
	"/USRI-DDC-Search",
	"/SPOT-DDC-Search",
	"/ReportView",
];

const webRoutes = [
	"/AddRequisition",
	"/ManageRequisitions",
	"/InstancedClasses",
	"/ViewBlocks",
	"/DepartmentSummary",
	"/UserSettings",
	"/request-for-service",
	"/success",
	"/newordisplay",
	"/display",
	"/SPOT-Ordering",
	"/SPOT-Grades-Submitted",
];

const adminRoutes = [
	"/SPOTnotSetup",
	"/TrackRequisitions",
	"/AdminUtils",
	"/MidtermView",
	"/AddBlocks",
	"/AddItem",
	"/AddUser",
	"/ReportGeneration",
	"/SPOT-Faculty-Department-Search",
	"/SPOT-Faculty-Department-Reports",
	"/SPOT-Ordering",
];

const tempRoutes = ["/request-for-service", "/success", "/newordisplay", "/display"];

const allRoutes = [
	...new Set([...studentRoutes, ...instructorRoutes, ...ddcRoutes, ...webRoutes, ...adminRoutes]),
];

function AppBarNav() {
	const auth = isAuthenticated();
	const adminUser = Boolean(auth.access && auth.access.includes("ADMIN"));
	const token = auth.token;
	const [expanded, setExpanded] = React.useState(false);
	const timerRef = React.useRef(null);
	const reportingRefX = useRef(null);
	const spotRefX = useRef(null);
	const utilitiesRefX = useRef(null);
	const adminRefX = useRef(null);
	const multipleChoiceTestRefX = useRef(null);
	const [widthReporting, setWidthReporting] = useState(0);
	const [widthSPOT, setWidthSPOT] = useState(0);
	const [widthUtilities, setWidthUtilities] = useState(0);
	const [widthAdmin, setWidthAdmin] = useState(0);
	const [widthMultipleChoiceTest, setWidthMultipleChoiceTest] = useState(0);
	const [reportingX, setReportingX] = useState(0);
	const [spotX, setSpotX] = useState(0);
	const [utilitiesX, setUtilitiesX] = useState(0);
	const [adminX, setAdminX] = useState(0);
	const [multipleChoiceTestX, setMultipleChoiceTestX] = useState(0);
	const [mouseOverButtonState, setMouseOverButtonState] = useState("");
	const navBarElementMarginLeft = "4rem";
	const navBarElementGap = 10;
	const [maxHeight, setMaxHeight] = useState(0);

	const [resultsEmailNotificationData, setResultsEmailNotificationData] = useState([]);

	const fetchNotifications = async () => {
		const resultsEmailNotifications = await fetchResultsEmailNotifications(token);

		setResultsEmailNotificationData(resultsEmailNotifications);
		return {
			resultsEmailNotifications,
		};
	};

	const { data, isLoading, isError, refetch } = useQuery("notifications", fetchNotifications, {
		enabled: adminUser,
		staleTime: 1000 * 30,
	});

	const access = auth.access;

	useLayoutEffect(() => {
		if (
			access?.includes("STD") ||
			access?.includes("INST") ||
			access?.includes("DDC") ||
			access?.includes("ADMIN")
		) {
			setWidthReporting(reportingRefX.current.offsetWidth);
			setReportingX(reportingRefX.current.offsetLeft);
		}

		if (access?.includes("ADMIN")) {
			setWidthAdmin(adminRefX.current.offsetWidth);
			setAdminX(adminRefX.current.offsetLeft);
		}
		if (access?.includes("WEB") || access?.includes("ADMIN")) {
			setSpotX(spotRefX.current.offsetLeft);
			setWidthSPOT(spotRefX.current.offsetWidth);
			setUtilitiesX(utilitiesRefX.current.offsetLeft);
			setWidthUtilities(utilitiesRefX.current.offsetWidth);
			setWidthMultipleChoiceTest(multipleChoiceTestRefX.current.offsetWidth);
			setMultipleChoiceTestX(multipleChoiceTestRefX.current.offsetLeft);
		}
		if (access?.includes("INST") || access?.includes("ADMIN")) {
			setSpotX(spotRefX.current.offsetLeft);
			setWidthSPOT(spotRefX.current.offsetWidth);

			setWidthMultipleChoiceTest(multipleChoiceTestRefX.current.offsetWidth);
			setMultipleChoiceTestX(multipleChoiceTestRefX.current.offsetLeft);
		}
		if (access?.includes("TEMP") || access?.includes("ADMIN")) {
			setWidthMultipleChoiceTest(multipleChoiceTestRefX.current.offsetWidth);
			setMultipleChoiceTestX(multipleChoiceTestRefX.current.offsetLeft);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [access]);

	const handleMouseOver = (buttonName) => {
		let routes = [];
		if (buttonName === "reporting") {
			routes = reportButtons;
		} else if (buttonName === "SPOT") {
			routes = SPOTButtons;
		} else if (buttonName === "multipleChoiceTest") {
			routes = multipleChoiceTestButtons;
		} else if (buttonName === "utilities") {
			routes = utilitiesButtons;
		} else if (buttonName === "admin") {
			routes = adminButtonRoutes;
		}

		// Determine the routes based on the access
		let accessRoutes = [];
		if (access.includes("STD")) {
			accessRoutes = [...accessRoutes, ...studentRoutes];
		}
		if (access.includes("INST")) {
			accessRoutes = [...accessRoutes, ...instructorRoutes];
		}
		if (access.includes("DDC")) {
			accessRoutes = [...accessRoutes, ...ddcRoutes];
		}
		if (access.includes("WEB")) {
			accessRoutes = [...accessRoutes, ...webRoutes];
		}
		if (access.includes("TEMP")) {
			accessRoutes = [...accessRoutes, ...tempRoutes];
		}
		if (access.includes("ADMIN")) {
			accessRoutes = [...allRoutes];
		}
		// Remove duplicates from accessRoutes
		accessRoutes = [...new Set(accessRoutes)];

		// Get the intersection of the routes and the accessRoutes
		routes = routes.filter((route) => accessRoutes.includes(route));

		// Calculate the maximum height based on the number of routes
		const newMaxHeight = 15 + routes.length * 36; // Assuming each route takes up 50px
		setMaxHeight(newMaxHeight);
		setExpanded(true);
		if (timerRef.current) {
			clearTimeout(timerRef.current); // Cancel the timer if it exists
			timerRef.current = null;
		}
		setMouseOverButtonState(buttonName);
	};

	const handleMouseOut = () => {
		timerRef.current = setTimeout(() => {
			setExpanded(false);
		}, 10);
	};

	const handleBoxMouseOver = () => {
		if (timerRef.current) {
			clearTimeout(timerRef.current); // Cancel the timer if it exists
			timerRef.current = null;
		}
	};

	const handleAccessChange = (e) => {
		if (e.target.name === "NONE") {
			sessionStorage.setItem("access", "");
			window.location.reload();
		} else {
			sessionStorage.setItem("access", e.target.name);
			window.location.reload();
		}
	};

	const testing = false;

	if (auth.access && auth.access?.length > 0) {
		return (
			<AppBar position="static" color="invertedPrimary" sx={{ width: "100%", zIndex: 11 }}>
				<Toolbar
					sx={{
						display: "flex",
						flexDirection: "row",
					}}
				>
					<Box
						sx={{
							display: "flex",

							marginLeft: navBarElementMarginLeft,
							gap: navBarElementGap,
						}}
					>
						{(access?.includes("STD") ||
							access?.includes("INST") ||
							access?.includes("DDC") ||
							access?.includes("ADMIN")) && (
							<Button
								ref={reportingRefX}
								onMouseOver={() => handleMouseOver("reporting")}
								onMouseOut={handleMouseOut}
								sx={{
									"&:hover": {
										cursor: "pointer",
										backgroundColor: "#FFFFFF",

										"& .button-text": {
											textDecoration: "underline",
										},
									},
								}}
							>
								<Typography className="button-text" color="black" variant="h6" fontWeight={500}>
									Reporting
								</Typography>
							</Button>
						)}
						{(access?.includes("WEB") || access?.includes("INST") || access?.includes("ADMIN")) && (
							<Button
								ref={spotRefX}
								onMouseOver={() => handleMouseOver("SPOT")}
								onMouseOut={handleMouseOut}
								sx={{
									"&:hover": {
										cursor: "pointer",
										backgroundColor: "#FFFFFF",

										"& .button-text": {
											textDecoration: "underline",
										},
									},
								}}
							>
								<Typography className="button-text" color="black" variant="h6" fontWeight={500}>
									SPOT
								</Typography>
							</Button>
						)}

						{(access?.includes("INST") ||
							access?.includes("ADMIN") ||
							access?.includes("WEB") ||
							access?.includes("TEMP")) && (
							<Button
								ref={multipleChoiceTestRefX}
								component={RouterLink}
								to="/request-for-service"
								/* onMouseOver={() => handleMouseOver("multipleChoiceTest")}
								onMouseOut={handleMouseOut} */
								sx={{
									"&:hover": {
										cursor: "pointer",
										backgroundColor: "#cfcfd1",
										outline: "2px solid #275D38",
										color: "white",

										/* "& .button-text": {
											textDecoration: "underline",
										}, */
									},
									borderRadius: "5px",
									whiteSpace: "nowrap",
								}}
							>
								<Typography
									className="button-text"
									color="black"
									variant="h6"
									fontWeight={500}
									sx={{
										whiteSpace: "nowrap",
									}}
								>
									Test Scoring
								</Typography>
							</Button>
						)}
						{(access?.includes("WEB") || access?.includes("ADMIN")) && (
							<Button
								ref={utilitiesRefX}
								onMouseOver={() => handleMouseOver("utilities")}
								onMouseOut={handleMouseOut}
								sx={{
									"&:hover": {
										cursor: "pointer",
										backgroundColor: "#FFFFFF",
										"& .button-text": {
											textDecoration: "underline",
										},
									},
								}}
							>
								<Typography className="button-text" color="black" variant="h6" fontWeight={500}>
									Utilities
								</Typography>
							</Button>
						)}

						{access?.includes("ADMIN") && (
							<Button
								ref={adminRefX}
								onMouseOver={() => handleMouseOver("admin")}
								onMouseOut={handleMouseOut}
								sx={{
									"&:hover": {
										cursor: "pointer",
										backgroundColor: "#FFFFFF",
										"& .button-text": {
											textDecoration: "underline",
										},
									},
								}}
							>
								<Typography className="button-text" color="black" variant="h6" fontWeight={500}>
									Admin
								</Typography>
							</Button>
						)}
						{!access?.includes("ADMIN") &&
							!access?.includes("INST") &&
							!access?.includes("DDC") &&
							!access?.includes("WEB") &&
							!access?.includes("TEMP") && (
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
									}}
								>
									<GiveRoleForm
										buttonVariant="outlined"
										buttonTitle="Need to Submit a Request for Service?"
										buttonWidth="300px"
									/>
								</Box>
							)}

						<Box sx={{ display: testing ? "flex" : "none" }}>
							<Button
								onClick={handleAccessChange}
								name="INST"
								sx={{ display: testing ? "flex" : "none" }}
							>
								INST
							</Button>
							<Button
								onClick={handleAccessChange}
								name="STD"
								sx={{ display: testing ? "flex" : "none" }}
							>
								STD
							</Button>
							<Button
								onClick={handleAccessChange}
								name="DDC"
								sx={{ display: testing ? "flex" : "none" }}
							>
								DDC
							</Button>
							<Button
								onClick={handleAccessChange}
								name="WEB"
								sx={{ display: testing ? "flex" : "none" }}
							>
								WEB
							</Button>
							<Button
								onClick={handleAccessChange}
								name="ADMIN"
								sx={{ display: testing ? "flex" : "none" }}
							>
								ADMIN
							</Button>
							<Button
								onClick={handleAccessChange}
								name="NONE"
								sx={{ display: testing ? "flex" : "none" }}
							>
								NONE
							</Button>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							width: "100%",
							justifyContent: "flex-end",
							height: "100%",
							gap: 2,
						}}
					>
						<NotificationMenu resultsEmailNotificationData={resultsEmailNotificationData} />
					</Box>
				</Toolbar>

				<Grow in={expanded} style={{ transformOrigin: "0 0 0" }}>
					<Box
						sx={{
							backgroundColor: "white",
							width: "100%",
							height: `${maxHeight}px`,
							top: 150,
							p: 1,
							position: "absolute",
							zIndex: "5",
							display: expanded ? "flex" : "none",
							boxShadow: "0 6px 4px -2px rgba(50,50,50,.3)",
						}}
						onMouseOver={handleBoxMouseOver}
						onMouseOut={handleMouseOut}
					>
						{mouseOverButtonState === "reporting" && (
							<Grow in={expanded} style={{ transformOrigin: "0 0 0" }}>
								<Box
									sx={{
										position: "absolute",
										left: reportingX,
										width: { widthReporting },
										height: "100%",
										display: "flex",
										flexDirection: "column",
									}}
								>
									{(access?.includes("INST") || access?.includes("ADMIN")) && (
										<NavButton navTo="My SPOT Reports" link="/My-SPOT-Reports" />
									)}
									{(access?.includes("INST") || access?.includes("ADMIN")) && (
										<NavButton navTo="My USRI Reports" link="/My-USRI-Reports" />
									)}

									{(access?.includes("STD") || access?.includes("ADMIN")) && (
										<NavButton navTo="Search SPOT Reports" link="/SPOT-Student-Search" />
									)}
									{(access?.includes("STD") || access?.includes("ADMIN")) && (
										<NavButton navTo="Search USRI Reports" link="/USRI-Student-Search" />
									)}
									{(access?.includes("DDC") || access?.includes("ADMIN")) && (
										<NavButton navTo="Dean and Chair SPOT Reports" link="/SPOT-DDC-Search" />
									)}
								</Box>
							</Grow>
						)}
						{mouseOverButtonState === "SPOT" && (
							<Grow in={expanded} style={{ transformOrigin: "0 0 0" }}>
								<Box
									sx={{
										position: "absolute",
										width: { widthSPOT },
										left: spotX,
										height: "100%",
										display: "flex",
										flexDirection: "column",
									}}
								>
									{/* {(access?.includes("INST") || access?.includes("ADMIN")) && (
										<NavButton
											navTo="Confirm Exam Dates"
											link="/confirm-exam-dates"
										/>
									)} */}
									{(access?.includes("WEB") || access?.includes("ADMIN")) && (
										<>
											<NavButton navTo="SPOT Ordering" link="/SPOT-Ordering" />

											<NavButton navTo="SPOT Summary" link="/DepartmentSummary" />
											<NavButton navTo="Grades Submitted" link="/SPOT-Grades-Submitted" />
											<NavButton navTo="View Blocks" link="/ViewBlocks" />
										</>
									)}
								</Box>
							</Grow>
						)}
						{mouseOverButtonState === "multipleChoiceTest" && (
							<Grow in={expanded} style={{ transformOrigin: "0 0 0" }}>
								<Box
									sx={{
										position: "absolute",
										width: { widthMultipleChoiceTest },
										left: multipleChoiceTestX,
										height: "100%",
										display: "flex",
										flexDirection: "column",
									}}
								>
									<NavButton navTo="Request Grading" link="/request-for-service" />
								</Box>
							</Grow>
						)}

						{mouseOverButtonState === "utilities" && (
							<Grow in={expanded} style={{ transformOrigin: "0 0 0" }}>
								<Box
									sx={{
										position: "absolute",
										width: { widthUtilities },
										left: utilitiesX,
										height: "100%",
										display: "flex",
										flexDirection: "column",
									}}
								>
									{access?.includes("ADMIN") && <NavButton navTo="Add Blocks" link="/AddBlocks" />}
									{access?.includes("ADMIN") && <NavButton navTo="Add Item" link="/AddItem" />}
									{access?.includes("ADMIN") && <NavButton navTo="Add User" link="/AddUser" />}
									{(access?.includes("ADMIN") || access?.includes("WEB")) && (
										<NavButton navTo="Instanced Classes" link="/InstancedClasses" />
									)}
								</Box>
							</Grow>
						)}
						{mouseOverButtonState === "admin" && (
							<Grow in={expanded} style={{ transformOrigin: "0 0 0" }}>
								<Box
									sx={{
										position: "absolute",
										width: { widthAdmin },
										left: adminX,
										height: "100%",
										display: "flex",
										flexDirection: "column",
									}}
								>
									<NavButton navTo="Courses Without SPOTs" link="/SPOTnotSetup" />
									<NavButton navTo="Track Requisitions" link="/TrackRequisitions" />
									<NavButton navTo="Admin Utils" link="/AdminUtils" />
									<NavButton navTo="Report Generation" link="/ReportGeneration" />
									<NavButton
										navTo="Reports By Faculty/Department"
										link="/SPOT-Faculty-Department-Search"
									/>
									<NavButton navTo="Midterm View" link="/MidtermView" />
									{(access?.includes("INST") || access?.includes("ADMIN")) && (
										<NavButton navTo="My USRI Reports" link="/My-USRI-Reports" />
									)}

									{(access?.includes("DDC") || access?.includes("ADMIN")) && (
										<NavButton navTo="DDC USRI Reports" link="/USRI-DDC-Search" />
									)}
									{(access?.includes("STD") || access?.includes("ADMIN")) && (
										<NavButton navTo="Student USRI Reports" link="/USRI-Student-Search" />
									)}
								</Box>
							</Grow>
						)}
					</Box>
				</Grow>
			</AppBar>
		);
	}
}

export default AppBarNav;
