import React from "react";
import { useState, useEffect } from "react";
import { Button, Tooltip, CircularProgress } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForever from "@mui/icons-material/DeleteForever";
import WarningIcon from "@mui/icons-material/Warning";
import dayjs from "dayjs";
import { DatePickerComponent } from "./DatePickerComponent";
import ToggleExclude from "./ToggleExclude";
import TabsClassAdjustment from "./TabsClassAdjustment";
import BlockIdSelect from "./BlockIdSelect";
import EditIcon from "@mui/icons-material/Edit";
import PublishIcon from "@mui/icons-material/Publish";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
export default function ClassPanel({
	selectedClass,
	handleSaveChanges,
	handleDiscardChanges,
	handleDateChangeEndDate,
	handleDateChangeExamDate,
	handleDateChangeSurveyStart,
	handleDateChangeSurveyEnd,
	handleToggleExclude,
	handleCombineClass,
	handleCancelCombine,
	handleChangeRole,
	isTermHighest,
	isAdmin,
	deleteInstructor,
	roles,
	combiningMode,
	setCombiningMode,
	classesForCombining,
	setClassesForCombining,
	setOpenClassAdjustCombineDialog,
	teamTeachingAgree,
	setTeamTeachingAgree,
	checkboxSelectionState,
	undoCombine,
	handleSaveAttempt,
	hasNoRoles,
	hasNoBlockId,
	isClassSizeTooSmall,
	isClassSizeZero,
	validateClass,
	unsavedChanges,
	setUnsavedChanges,
	blockIdsByDept,
	allBlockItems,
	handleChangeBlkId,
	rows,
	setRows,
	setAddInstructor,
	addInstructor,
	allBlockItemsLoading,
	blockItemsStatus,
	handleOpenAddInstancedClass,
	isSurveyStartAfterEnd,
	customCheckboxSelection,
	setCustomCheckboxSelection,
	reviewingClasses,
	handleCancelSubmitAllSuitable,
	handleConfirmSubmitAllSuitable,
	submitAllLoading,
	confirmSubmitAllSelected,
	handleClickAddClassCheckbox,
	handleCancelSubmitAllSelected,
	handleReviewAllSelected,
	setOpenBatchUpdateDialog,
	handleSubmitAllSelected,
	handleSubmitAllSuitable,
	handleClearSelection,
	listOfSuitableCourses,
	listOfUnsuitableCourses,
	setBatchUpdated,
	allBlockItemsPI,
	allBlockItemsTA,
	combiningPrecombined,
	setCombiningPrecombined,
	handleClick,
}) {
	const [listOfUniqueBlockIDs, setListOfBlockIDs] = useState([]);
	const [listOfUniqueDates, setListOfUniqueDates] = useState([]);

	const [blockIDsCustomCheckbox, setBlockIDsCustomCheckbox] = useState([]);
	const [uniqueDatesCustomCheckbox, setUniqueDatesCustomCheckbox] = useState([]);

	useEffect(() => {
		if (listOfSuitableCourses && listOfSuitableCourses.length > 0) {
			const uniqueBlockIDs = new Set(listOfSuitableCourses.map((course) => course.blkid));
			let dateTuples = listOfSuitableCourses.map((course) => [
				course.survey_start,
				course.survey_end,
			]);
			const uniqueDates = new Set(dateTuples.map((date) => date.join(" to ")));
			setListOfBlockIDs([...uniqueBlockIDs]);
			setListOfUniqueDates([...uniqueDates]);
		}
	}, [listOfSuitableCourses]);

	useEffect(() => {
		if (customCheckboxSelection && customCheckboxSelection.length > 0) {
			//get blkids from rows using classid from customCheckboxSelection
			const blockIDs = customCheckboxSelection.map((classid) => {
				const selectedRow = rows.find((row) => row.classid === classid);
				return selectedRow.blkid;
			});
			let dateTuples = customCheckboxSelection.map((classid) => {
				const selectedRow = rows.find((row) => row.classid === classid);
				return [selectedRow.survey_start, selectedRow.survey_end];
			});
			const uniqueDates = new Set(dateTuples.map((date) => date.join(" to ")));
			const uniqueBlockIDs = new Set(blockIDs);
			setBlockIDsCustomCheckbox([...uniqueBlockIDs]);
			setUniqueDatesCustomCheckbox([...uniqueDates]);
		}
	}, [customCheckboxSelection, rows]);

	const [batchSelectedCourses, setBatchSelectedCourses] = useState([]);
	const [batchEndDate, setBatchEndDate] = useState("");
	const [batchExamDate, setBatchExamDate] = useState("");
	const [batchSurveyStartDate, setBatchSurveyStartDate] = useState("");
	const [batchSurveyEndDate, setBatchSurveyEndDate] = useState("");
	const [batchBlockId, setBatchBlockId] = useState("");
	const mostCommon = (arr) => {
		return arr.sort(
			(a, b) => arr.filter((v) => v === a).length - arr.filter((v) => v === b).length
		)[arr.length - 1];
	};
	useEffect(() => {
		//using customCheckboxSelection which has classid and props.rows, filter for selectedCourse

		const selectedCourses = rows.filter((row) => customCheckboxSelection.includes(row.classid));
		setBatchSelectedCourses(selectedCourses);

		//find the most common c_edate in selectedCourses
		const mostCommonEndDate = mostCommon(selectedCourses.map((course) => course.c_edate));
		setBatchEndDate(mostCommonEndDate);

		//find the most common examdate in selectedCourses
		const mostCommonExamDate = mostCommon(selectedCourses.map((course) => course.examdate));
		setBatchExamDate(mostCommonExamDate);

		//find the most common survey_start in selectedCourses
		const mostCommonSurveyStartDate = mostCommon(
			selectedCourses.map((course) => course.survey_start)
		);
		setBatchSurveyStartDate(mostCommonSurveyStartDate);

		//find the most common survey_end in selectedCourses

		const mostCommonSurveyEndDate = mostCommon(selectedCourses.map((course) => course.survey_end));
		setBatchSurveyEndDate(mostCommonSurveyEndDate);
		const mostCommonBlkId = mostCommon(selectedCourses.map((course) => course.blkid));
		setBatchBlockId(mostCommonBlkId);
	}, [customCheckboxSelection, rows]);
	const handleChangeBulkBlockId = (event) => {
		const updatedRows = rows.map((row) => {
			// Check if this row is selected based on customCheckboxSelection
			if (customCheckboxSelection.includes(row.classid)) {
				// Update the necessary fields based on the checkbox state
				return {
					...row,
					blkid: event.target.value,
					form: event.target.value,
				};
			}
			// Return the original row if it is not selected
			return row;
		});

		// Assuming you have a prop function to set the updated rows
		setRows(updatedRows);
		setBatchUpdated(true);
		setUnsavedChanges(true);
	};
	const handleBulkDateChangeSurveyStart = (date) => {
		const updatedRows = rows.map((row) => {
			// Check if this row is selected based on customCheckboxSelection
			if (customCheckboxSelection.includes(row.classid)) {
				// Update the necessary fields based on the checkbox state
				return {
					...row,

					survey_start: dayjs(date).format("YYYY-MM-DD"),
				};
			}
			// Return the original row if it is not selected
			return row;
		});

		// Assuming you have a prop function to set the updated rows
		setRows(updatedRows);
		setBatchUpdated(true);
		setUnsavedChanges(true);
	};

	const handleBulkDateChangeSurveyEnd = (date) => {
		const updatedRows = rows.map((row) => {
			// Check if this row is selected based on customCheckboxSelection
			if (customCheckboxSelection.includes(row.classid)) {
				// Update the necessary fields based on the checkbox state
				return {
					...row,

					survey_end: dayjs(date).format("YYYY-MM-DD"),
				};
			}
			// Return the original row if it is not selected
			return row;
		});

		// Assuming you have a prop function to set the updated rows
		setRows(updatedRows);
		setBatchUpdated(true);
		setUnsavedChanges(true);
	};

	const [openBulkEdit, setOpenBulkEdit] = useState(false);

	return (
		<div
			className={`flex flex-col  items-center w-[350px] h-full border-2 ${
				selectedClass ? (unsavedChanges ? "border-red-800 " : "border-green-800") : ""
			} rounded-e-md overflow-x-hidden`}
		>
			<div className="w-full bg-white flex flex-col h-full justify-between relative">
				{selectedClass ? (
					<>
						{selectedClass.isCombined && (
							<div>
								<div className="border-red-600 border-2 rounded-md bg-red-100 text-red-900 text-sm max-w-[90%] max-h-[70%] p-4 absolute m-auto left-0 right-0 top-[25%] z-20 flex flex-col items-center justify-center gap-4 ">
									<div className="max-w-[90%] flex flex-col gap-4">
										<p className="">
											This class is currently combined with{" "}
											<span className="font-bold">{selectedClass.combinedWith}</span>
										</p>
										<p className="">
											Classes that have been combined with another cannot be modified as they will
											not be surveyed.
										</p>
										<p className="">
											If you would like to modify this class and evaluate it separately, please undo
											the combine operation on the parent class{" "}
											<span className="font-bold">{selectedClass.combinedWith}</span>
										</p>
										<p className="">
											This can be done by selecting the parent class and clicking the "Undo Combine"
											button under the "Combining" tab.
										</p>
									</div>
								</div>
							</div>
						)}
						<div className="flex flex-col  h-full border-b-2 px-2 py-1">
							<div className="flex flex-col    ">
								<div className="w-full mx-auto  flex gap-2 " onClick={handleSaveAttempt}>
									{hasNoRoles ||
									hasNoBlockId ||
									isClassSizeTooSmall ||
									isClassSizeZero ||
									isSurveyStartAfterEnd ? (
										<Tooltip title={validateClass(selectedClass).message} placement="bottom">
											<Button
												variant="contained"
												/* onClick={handleSaveChanges} */
												disabled={!isTermHighest && !isAdmin}
												color="warning"
												className="flex items-center gap-1 w-full"
											>
												<WarningIcon />
											</Button>
										</Tooltip>
									) : (
										<Button
											variant="contained"
											onClick={handleSaveChanges}
											disabled={
												(!isTermHighest && !isAdmin) ||
												(!unsavedChanges && selectedClass.evaluate === "Yes") ||
												(!unsavedChanges && selectedClass.evaluate === "No")
											}
											className="flex items-center gap-1 w-full"
										>
											SUBMIT COURSE
											<SaveIcon />
										</Button>
									)}

									<Button
										disabled={!unsavedChanges || (!isTermHighest && !isAdmin)}
										variant="contained"
										color="error"
										onClick={handleDiscardChanges}
										className="flex items-center gap-1 w-full"
									>
										RESET
										<DeleteForever />
									</Button>
								</div>
								<div className=" grid grid-cols-6 p-2">
									<span
										className={`font-bold col-span-4 antialiased ${
											selectedClass.ctitle.length > 20 ? "text-md " : "text-lg "
										}`}
									>
										{selectedClass.ctitle}{" "}
									</span>
									<span className="font-bold text-lg col-span-2">ID: {selectedClass.classid}</span>
								</div>

								<div className="w-full border-b-2"></div>
								<div className="grid grid-cols-9 p-2">
									<p className="text-sm flex justify-between col-span-4">
										# of students: <span className="font-bold">{selectedClass.csize}</span>
									</p>
									<div className="h-full w-full col-span-1 grid grid-cols-2">
										<div className="h-full w-full border-r-2 col-span-1"></div>
										<div className="h-full w-full col-span-1"></div>
									</div>
									<p className=" text-sm flex justify-between col-span-4">
										# of instructors: <span className="font-bold">{selectedClass.teamsize}</span>
									</p>
								</div>

								<div className="grid grid-cols-2 gap-2">
									<DatePickerComponent
										disabled={selectedClass.isCombined || (!isTermHighest && !isAdmin)}
										width="100%"
										label="Class End Date"
										value={dayjs(selectedClass.c_edate)}
										onChange={handleDateChangeEndDate}
									/>
									<DatePickerComponent
										disabled={selectedClass.isCombined || (!isTermHighest && !isAdmin)}
										width="100%"
										label="Exam Date"
										value={dayjs(selectedClass.examdate)}
										onChange={handleDateChangeExamDate}
										confirm
									/>

									<DatePickerComponent
										disabled={selectedClass.isCombined || (!isTermHighest && !isAdmin)}
										width="100%"
										label="Survey Start Date"
										value={dayjs(selectedClass.survey_start)}
										minDate={dayjs().add(1, "day")}
										maxDate={dayjs(selectedClass.survey_end)}
										onChange={handleDateChangeSurveyStart}
									/>
									<DatePickerComponent
										disabled={selectedClass.isCombined || (!isTermHighest && !isAdmin)}
										width="100%"
										label="Survey End Date"
										value={dayjs(selectedClass.survey_end)}
										minDate={dayjs(selectedClass.survey_start).add(1, "day")}
										maxDate={
											dayjs(selectedClass.examdate)
												? dayjs(selectedClass.examdate).subtract(1, "day")
												: null
										}
										onChange={handleDateChangeSurveyEnd}
									/>
								</div>
							</div>
							<div className="flex justify-between gap-2 bg-gray-100   border-2 px-1 py-1 my-2 shadow-md rounded-md">
								<div className="flex flex-col gap-2 ">
									<label className="text-zinc-900 text-sm font-semibold">Block ID:</label>
									<BlockIdSelect
										disabled={allBlockItemsLoading || combiningMode || (!isTermHighest && !isAdmin)}
										blockIds={
											selectedClass.roles.some((role) => role.class_role !== "TA")
												? allBlockItemsPI
												: allBlockItemsTA
										}
										value={selectedClass.blkid ? selectedClass.blkid : ""}
										onChange={(event) => handleChangeBlkId(event)}
										selectedClass={selectedClass}
										width="50%"
										marginTop="0px"
										marginBottom="0px"
										blockItemsStatus={blockItemsStatus}
									/>
								</div>
								<ToggleExclude
									row={selectedClass}
									handleToggleExclude={handleToggleExclude}
									disabled={selectedClass.isCombined || (!isTermHighest && !isAdmin)}
								/>
							</div>

							<TabsClassAdjustment
								isTermHighest={isTermHighest}
								isAdmin={isAdmin}
								handleCancelCombine={handleCancelCombine}
								handleCombineClass={handleCombineClass}
								handleChangeBlkId={handleChangeBlkId}
								disabled={selectedClass.isCombined}
								blockIdsByDept={blockIdsByDept}
								blockIds={allBlockItems}
								selectedClass={selectedClass}
								row={selectedClass}
								rows={rows}
								setRows={setRows}
								setAddInstructor={setAddInstructor}
								addInstructor={addInstructor}
								handleChangeRole={handleChangeRole}
								deleteInstructor={deleteInstructor}
								roles={roles}
								setUnsavedChanges={setUnsavedChanges}
								unsavedChanges={unsavedChanges}
								combiningMode={combiningMode}
								setCombiningMode={setCombiningMode}
								classesForCombining={classesForCombining}
								setClassesForCombining={setClassesForCombining}
								setOpenClassAdjustCombineDialog={setOpenClassAdjustCombineDialog}
								teamTeachingAgree={teamTeachingAgree}
								setTeamTeachingAgree={setTeamTeachingAgree}
								checkboxSelectionState={checkboxSelectionState}
								undoCombine={undoCombine}
								handleOpenAddInstancedClass={handleOpenAddInstancedClass}
								combiningPrecombined={combiningPrecombined}
								setCombiningPrecombined={setCombiningPrecombined}
								handleClick={handleClick}
							/>
						</div>
					</>
				) : (
					<div className="w-full h-full bg-white flex flex-col justify-between items-center ">
						{reviewingClasses ? (
							<div className="flex flex-col gap-2 w-full p-2">
								<p className="text-center border-b-2 pb-2 text-xl">Submit Whole Department</p>
								<p className="border-b-2 pb-2">
									<span
										className={`${
											listOfSuitableCourses.length !== rows.length
												? "text-red-500"
												: "text-green-600"
										}`}
									>
										{listOfSuitableCourses.length} of {rows.length}
									</span>{" "}
									classes suitable for submission
								</p>
								<p className=" border-b-2 pb-2">
									Table is sorted by suitability. Please review the classes at the bottom before
									submission.
								</p>
								<div className="border-b-2 pb-2">
									Block IDs Used:{" "}
									{listOfUniqueBlockIDs.map((id, index) => (
										<p key={index} className="font-semibold pl-4">
											{id}
										</p>
									))}
								</div>
								<div className="border-b-2 pb-2">
									Unique Survey Windows:{" "}
									{listOfUniqueDates.map((id, index) => (
										<p key={index} className="font-semibold pl-4">
											{id}
										</p>
									))}
								</div>
								<Button
									disabled={unsavedChanges || (!isTermHighest && !isAdmin)}
									onClick={handleConfirmSubmitAllSuitable}
									variant="contained"
									className="w-full h-10 bg-green-800 hover:bg-green-900 text-white font-semibold rounded-br-md"
								>
									{submitAllLoading ? (
										<div className="flex justify-center gap-2">
											<p>Please Wait</p> <CircularProgress size={20} sx={{ color: "white" }} />
										</div>
									) : (
										"Confirm Submission"
									)}
								</Button>
								<Button
									disabled={unsavedChanges || (!isTermHighest && !isAdmin)}
									onClick={handleCancelSubmitAllSuitable}
									variant="contained"
									color="error"
									className="w-full h-10  text-white font-semibold rounded-br-md"
								>
									Cancel
								</Button>
							</div>
						) : (
							customCheckboxSelection.length > 0 && (
								<div
									//onClick={handleClickAddClassCheckbox}
									className="flex flex-col justify-between h-full w-full  "
								>
									<div className="flex flex-col gap-2 border-b-2 p-2  ">
										<div className="flex flex-col gap-2 bg-gray-100 border-2 text-gray-600  border-gray-300 rounded-xl">
											<div className="  p-2 gap-2 flex items-center ">
												<ErrorOutlineIcon />
												<div>
													<p>Editing/submission of individual courses is disabled.</p>
												</div>
											</div>
										</div>
										<Button
											onClick={handleClearSelection}
											disabled={(!isTermHighest && !isAdmin) || submitAllLoading}
											variant="outlined"
											color="error"
											sx={{ color: "red" }}
											className="w-full   text-white font-semibold "
										>
											<DeleteForever />
											Clear Selection and Refresh
										</Button>
									</div>
									{confirmSubmitAllSelected ? (
										<div className="flex flex-col gap-2 p-4" onClick={handleClickAddClassCheckbox}>
											<div className="flex flex-col gap-2 py-2  h-[330px] ">
												<div>
													Block IDs:{" "}
													{blockIDsCustomCheckbox.map((id, index) => (
														<p key={index} className="font-semibold pl-4">
															{id}
														</p>
													))}
												</div>
												<div>
													Survey Windows:{" "}
													{uniqueDatesCustomCheckbox.map((id, index) => (
														<p key={index} className="font-semibold pl-4">
															{id}
														</p>
													))}
												</div>
											</div>
											<div className="flex flex-col gap-2 bg-gray-100 border-2 text-gray-600  border-gray-300 rounded-xl">
												<div className="  p-2 gap-2 flex items-center ">
													<ErrorOutlineIcon />
													<div>
														<p>
															All courses in the table will be submitted as you see them. Please
															confirm their accuracy prior to submission.
														</p>
													</div>
												</div>
											</div>
											<Button
												onClick={handleSubmitAllSelected}
												disabled={(!isTermHighest && !isAdmin) || submitAllLoading}
												variant="contained"
												className="w-full  bg-green-800 hover:bg-green-900 text-white font-semibold rounded-br-md"
											>
												{submitAllLoading ? (
													<div className="flex justify-center gap-2">
														<p>Please Wait</p>{" "}
														<CircularProgress size={20} sx={{ color: "white" }} />
													</div>
												) : (
													<>
														<PublishIcon />
														Submit Selected Courses ({customCheckboxSelection.length})
													</>
												)}
											</Button>
											<Button
												onClick={handleCancelSubmitAllSelected}
												disabled={(!isTermHighest && !isAdmin) || submitAllLoading}
												variant="contained"
												color="error"
												className="w-full  text-white font-semibold rounded-br-md"
											>
												<CancelIcon /> Return to Edit
											</Button>
										</div>
									) : (
										<div className="flex flex-col gap-2  h-[80%] justify-between  ">
											<div className="flex flex-col gap-2 flex-1 overflow-y-scroll p-2">
												{customCheckboxSelection.length > 0 &&
													customCheckboxSelection.map((classid, index) => {
														const selectedRow = rows.find((row) => row.classid === classid);
														return (
															<div
																key={index}
																className="flex  gap-2  bg-gray-100 border-2 text-gray-600  border-gray-300 px-4 py-1 rounded-xl relative"
															>
																<div className="flex flex-col gap-[1px]  w-full text-primary">
																	<div className="font-semibold">{selectedRow.ctitle}</div>
																	{selectedRow.roles.map((role, index) => (
																		<div key={index} className="flex gap-2 text-black">
																			<p className="text-sm">
																				{role.name} - {role.class_role}
																			</p>
																		</div>
																	))}
																</div>
																<button
																	onClick={() => {
																		//remove from customCheckboxSelection
																		const updatedSelection = customCheckboxSelection.filter(
																			(selected) => selected !== classid
																		);
																		setCustomCheckboxSelection(updatedSelection);
																	}}
																	className="text-red-600 hover:text-red-800 absolute -top-[14px] right-3 rounded-full  "
																>
																	<CloseIcon
																		sx={{
																			width: "16px",
																			height: "16px",
																		}}
																		className="border-[1px] bg-gray-100 border-gray-400 rounded-full hover:ring-2"
																	/>
																</button>
															</div>
														);
													})}
											</div>

											<div className="flex flex-col gap-2 p-4 justify-start border-t-2">
												{openBulkEdit && (
													<div className="flex flex-col gap-2 ">
														<p className="font-semibold text-primary">
															Modify {customCheckboxSelection.length} selected courses:
														</p>
														<div className="flex gap-2">
															<DatePickerComponent
																width="100%"
																label="Survey Start Date"
																value={dayjs(batchSurveyStartDate)}
																onChange={handleBulkDateChangeSurveyStart}
															/>

															<DatePickerComponent
																width="100%"
																label="Survey End Date"
																value={dayjs(batchSurveyEndDate)}
																onChange={handleBulkDateChangeSurveyEnd}
															/>
														</div>
														<div className="flex p-2 bg-gray-200 rounded-md flex-col items-start justify-between border-2 px-2 py-1 ">
															<label className="text-zinc-900 text-sm font-semibold">
																Block ID:
															</label>
															<BlockIdSelect
																value={batchBlockId}
																inBatch
																customCheckboxSelection={customCheckboxSelection}
																rows={rows}
																blockIds={
																	//all classes from rows that are in customCheckboxselection, check roles, if any are PI, use PI blockids, else use TA blockids
																	customCheckboxSelection.some((classid) =>
																		rows
																			.find((row) => row.classid === classid)
																			.roles.some((role) => role.class_role !== "TA")
																	)
																		? allBlockItemsPI
																		: allBlockItemsTA
																}
																width="100%"
																marginTop="0px"
																marginBottom="0px"
																onChange={handleChangeBulkBlockId}
															/>
														</div>
													</div>
												)}
												{openBulkEdit ? (
													<div className="flex flex-col gap-2">
														<Button
															className="w-full   text-white font-semibold "
															color="primary"
															variant="contained"
															onClick={() => setOpenBulkEdit(false)}
														>
															Done Editing
														</Button>
													</div>
												) : (
													<div className="flex flex-col gap-2">
														<Button
															className="w-full   text-white font-semibold "
															color="info"
															variant="contained"
															onClick={() => setOpenBulkEdit(true)}
														>
															<EditIcon />
															Edit ({customCheckboxSelection.length}) Courses
														</Button>

														<Tooltip
															title="Submit selected courses as they are presented."
															placement="bottom"
														>
															<Button
																onClick={handleReviewAllSelected}
																variant="contained"
																className="w-full  text-white font-semibold"
															>
																Review/Submit ({customCheckboxSelection.length}) Courses
															</Button>
														</Tooltip>
													</div>
												)}
											</div>
										</div>
									)}
								</div>
							)
						)}
					</div>
				)}
			</div>
		</div>
	);
}
