import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import GroupedSelect from "../../../components/Utils/GroupedListSelect";
import isAuthenticated from "../../../components/Utils/isAuthenticated";
import { getBlockIds } from "../../../components/Api/Getters";

export default function ViewBlocks() {
	let url = process.env.REACT_APP_BACKEND_HOST;
	const [fetched, setFetched] = useState(false);
	const [blockIDs, setBlockIDs] = useState([]);
	const [selectedBlock, setSelectedBlock] = useState("");
	const [disableAccordian, setDisableAccordian] = useState(true);
	const [expandAccordian, setExpandAccordian] = useState(false);
	const [blockInfo, setBlockInfo] = useState([]);
	const [blockPreview, setBlockPreview] = useState([]);
	const auth = isAuthenticated();
	const token = auth.token;
	const [blockIds, setBlockIds] = useState([]);
	const [blockID, setBlockID] = useState("");

	useEffect(() => {
		const blockIdsArray = [];
		async function fetchBlockIds() {
			if (token) {
				const blockIds = await getBlockIds(token);
				setBlockIDs(blockIds);
				blockIds.forEach((blockid) => {
					if (blockid.status === "C") {
						blockIdsArray.push(
							`${blockid.blkid}:${blockid.ctype}:${blockid.blkid3}:${blockid.baddr1} `
						);
					}
				});
				//sort blockIdsArray by first character descending
				const blockIds3 = blockIdsArray.filter((blockid) => blockid.startsWith("3"));
				const blockIds1 = blockIdsArray.filter((blockid) => blockid.startsWith("1"));
				//sort blockIds3 desc
				blockIds3.sort((a, b) => {
					if (a[1] < b[1]) {
						return 1;
					}
					if (a[1] > b[1]) {
						return -1;
					}
					return 0;
				});
				/* blockIds3.sort(); */
				blockIds1.sort();
				blockIdsArray.length = 0;
				blockIdsArray.push(...blockIds3, ...blockIds1);
				setBlockIds(blockIdsArray);
			}
		}
		fetchBlockIds();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	async function fetchData(url) {
		const response = await fetch(url, {
			headers: { Authorization: `Token ${sessionStorage.token}` },
		});
		const data = await response.json();
		return data;
	}

	async function selectBlock(selected) {
		// console.log(blockIDs);
		for (const block of blockIDs) {
			console.log(block.blkid);
			if (block.blkid === selected) {
				setBlockInfo(block);
			}
		}

		const block_data = await fetchData(url.concat("/spot/block_info/", selected, "/"));
		setBlockPreview(block_data);
		setDisableAccordian(false);
		setExpandAccordian(true);
	}

	const handleChange = (event) => {
		selectBlock(event.target.value.split(":")[0]);
		setBlockID(event.target.value);
		//console.log(event.target.value);
	};
	// console.log(blockInfo);

	return (
		<div style={{ height: "100%", maxHeight: "calc(100% - 160px)" }}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					marginTop: "20px",
				}}
			>
				<Typography variant="h4">View Blocks</Typography>

				<Box
					style={{
						paddingTop: "10px",
						alignItems: "center",
						flexDirection: "column",
						width: "500px",
					}}
				>
					<GroupedSelect
						list={blockIds}
						value={blockID || ""}
						onChange={handleChange}
						label="Choose Your Block"
						width="400px"
						marginTop="0px"
						marginBottom="0px"
					/>
				</Box>

				<Box
					style={{
						paddingTop: "10px",
						alignItems: "center",
						flexDirection: "column",
						width: "80%",
					}}
				>
					<Accordion disabled={disableAccordian} expanded={expandAccordian}>
						<AccordionSummary aria-controls="panel1a-content" id="Block-info">
							<Typography variant="body1" style={{ fontWeight: "bold" }}>
								Block Info
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Box
								justify="center"
								alignItems="flex-start"
								style={{
									paddingLeft: "80px",
									paddingRight: "80px",
									textAlign: "center",
								}}
							>
								<Grid container style={{ paddingBottom: "10px" }}>
									<Grid
										item
										md={2.4}
										style={{
											paddingLeft: "35px",
											paddingRight: "15px",
											paddingBottom: "5px",
										}}
									>
										<Typography component={"div"} sx={{ fontSize: "1rem" }}>
											<b>Block ID:</b>
										</Typography>
										<Typography component={"div"} variant="body2" sx={{ fontSize: "1rem" }}>
											{blockInfo.blkid}
										</Typography>
									</Grid>
									<Grid
										item
										md={2.4}
										style={{
											paddingLeft: "35px",
											paddingRight: "15px",
											paddingBottom: "5px",
										}}
									>
										<Typography component={"div"} sx={{ fontSize: "1rem" }}>
											<b>Used For:</b>
										</Typography>
										<Typography component={"div"} variant="body2" sx={{ fontSize: "1rem" }}>
											{blockInfo.baddr1 === "" || blockInfo.baddr1 === null
												? "N/A"
												: blockInfo.baddr1}
										</Typography>
									</Grid>
									<Grid
										item
										md={2.4}
										style={{
											paddingLeft: "35px",
											paddingRight: "15px",
											paddingBottom: "5px",
										}}
									>
										<Typography component={"div"} sx={{ fontSize: "1rem" }}>
											<b>Faculty:</b>
										</Typography>
										<Typography component={"div"} variant="body2" sx={{ fontSize: "1rem" }}>
											{blockInfo.faculty === "" || blockInfo.faculty === null
												? "N/A"
												: blockInfo.faculty}
										</Typography>
									</Grid>
									<Grid
										item
										md={2.4}
										style={{
											paddingLeft: "35px",
											paddingRight: "15px",
											paddingBottom: "5px",
										}}
									>
										<Typography component={"div"} sx={{ fontSize: "1rem" }}>
											<b>Department ID:</b>
										</Typography>
										<Typography component={"div"} variant="body2" sx={{ fontSize: "1rem" }}>
											{blockInfo.dept_id === "" || blockInfo.dept_id === null
												? "N/A"
												: blockInfo.dept_id}
										</Typography>
									</Grid>
									<Grid
										item
										md={2.4}
										style={{
											paddingLeft: "35px",
											paddingRight: "15px",
											paddingBottom: "5px",
										}}
									>
										<Typography component={"div"} sx={{ fontSize: "1rem" }}>
											<b>language:</b>
										</Typography>
										<Typography component={"div"} variant="body2" sx={{ fontSize: "1rem" }}>
											{blockInfo.lang === "" || blockInfo.lang === null ? "N/A" : blockInfo.lang}
										</Typography>
									</Grid>
								</Grid>
								<Grid container style={{ paddingBottom: "10px" }}>
									<Grid
										item
										md={2.4}
										style={{
											paddingLeft: "35px",
											paddingRight: "15px",
											paddingBottom: "5px",
										}}
									>
										<Typography component={"div"} sx={{ fontSize: "1rem" }}>
											<b>Blkid3:</b>
										</Typography>
										<Typography component={"div"} variant="body2" sx={{ fontSize: "1rem" }}>
											{blockInfo.blkid3 === "" || blockInfo.blkid3 === null
												? "N/A"
												: blockInfo.blkid3}
										</Typography>
									</Grid>
									<Grid
										item
										md={2.4}
										style={{
											paddingLeft: "35px",
											paddingRight: "15px",
											paddingBottom: "5px",
										}}
									>
										<Typography component={"div"} sx={{ fontSize: "1rem" }}>
											<b>Origin:</b>
										</Typography>
										<Typography component={"div"} variant="body2" sx={{ fontSize: "1rem" }}>
											{blockInfo.origin === "" || blockInfo.origin === null
												? "N/A"
												: blockInfo.origin}
										</Typography>
									</Grid>
									<Grid
										item
										md={2.4}
										style={{
											paddingLeft: "35px",
											paddingRight: "15px",
											paddingBottom: "5px",
										}}
									>
										<Typography component={"div"} sx={{ fontSize: "1rem" }}>
											<b>Class Types:</b>
										</Typography>
										<Typography component={"div"} variant="body2" sx={{ fontSize: "1rem" }}>
											{blockInfo.ctype === "" || blockInfo.ctype === null ? "N/A" : blockInfo.ctype}
										</Typography>
									</Grid>
									<Grid
										item
										md={2.4}
										style={{
											paddingLeft: "35px",
											paddingRight: "15px",
											paddingBottom: "5px",
										}}
									>
										<Typography component={"div"} sx={{ fontSize: "1rem" }}>
											<b>Specific Course:</b>
										</Typography>
										<Typography component={"div"} variant="body2" sx={{ fontSize: "1rem" }}>
											{blockInfo.course === "" || blockInfo.course === null
												? "N/A"
												: blockInfo.course}
										</Typography>
									</Grid>
									<Grid
										item
										md={2.4}
										style={{
											paddingLeft: "35px",
											paddingRight: "15px",
											paddingBottom: "5px",
										}}
									>
										<Typography component={"div"} sx={{ fontSize: "1rem" }}>
											<b>Class Size:</b>
										</Typography>
										<Typography component={"div"} variant="body2" sx={{ fontSize: "1rem" }}>
											{blockInfo.csize === "" || blockInfo.csize === null ? "N/A" : blockInfo.csize}
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</AccordionDetails>
					</Accordion>
					<Accordion disabled={disableAccordian} expanded={expandAccordian}>
						<AccordionSummary aria-controls="panel2a-content" id="block-preivew">
							<Typography variant="body1" style={{ fontWeight: "bold" }}>
								Block Preview
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ paddingLeft: "80px", paddingRight: "80px" }}>
							{Object.values(blockPreview).reduce((acc, question, index, array) => {
								/* console.log(question);
								console.log(array); */
								// Add question to the display
								acc.push(
									<Box
										key={`question-${question.Itemno}`}
										style={{ display: "flex", flexDirection: "column" }}
									>
										<Typography variant="body2" style={{ fontWeight: "bold" }}>
											({question.Itemno}) - {question.Q_Text}
										</Typography>
										{question.Q_Type === "RATE" ? (
											<FormControl>
												<RadioGroup
													row
													style={{
														paddingLeft: "80px",
														paddingRight: "80px",
													}}
												>
													{(question.Scale
														? question.Scale.split(";")
														: [
																"I strongly disagree",
																"I disagree",
																"I neither agree nor disagree",
																"I agree",
																"I strongly agree",
														  ]
													).map((value, idx) => (
														<FormControlLabel
															key={idx}
															value={idx}
															control={<Radio size="small" />}
															label={value}
															style={{ marginRight: "60px" }}
														/>
													))}
												</RadioGroup>
												<br />
											</FormControl>
										) : (
											<TextField variant="outlined" />
										)}
										<br />
										<br />
									</Box>
								);

								// Check if the next question has a different Categeory or if it's the last question, then add comment box
								if (
									(index === array.length - 1 || array[index + 1].Category !== question.Category) &&
									question.Cat_id < 7
								) {
									acc.push(
										<Box
											key={`comment-${question.Cat_id}`}
											style={{
												display: "flex",
												flexDirection: "column",
												paddingTop: "10px",
											}}
										>
											<Typography variant="body2" style={{ fontWeight: "bold" }}>
												Comment (optional):
											</Typography>
											<TextField variant="outlined" />
											<br />
											<br />
										</Box>
									);
								}

								return acc;
							}, [])}
						</AccordionDetails>
					</Accordion>
				</Box>
			</div>
		</div>
	);
}
