import React, {useEffect, useState} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {CustomPagination, CustomNoRowsOverlay} from "../SecondaryFunctions";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import IconButton from '@mui/material/IconButton';


// datagrid columns
const columns = [
    {field: "term", headerName: "TERM", width: 100, align: "center", headerAlign: "center"},
    {field: "department", headerName: "DEPARTMENT", width: 250, align: "center", headerAlign: "center"},
    {field: "classid", headerName: "CLASSID", width: 100, align: "center", headerAlign: "center"},
    {field: "ctitle", headerName: "COURSE TITLE", width: 400, align: "center", headerAlign: "center"},
    {field: "instructor", headerName: "INSTRUCTOR", width: 250, align: "center", headerAlign: "center"},
    {field: "csize", headerName: "CLASS SIZE", width: 100, align: "center", headerAlign: "center"},
    {field: "course_start", headerName: "COURSE START", width: 125, align: "center", headerAlign: "center"},
    {field: "course_end", headerName: "COURSE END", width: 125, align: "center", headerAlign: "center"},
    {field: "email", headerName: "EMAIL", width: 200, align: "center", headerAlign: "center"},
    
];

const outLookOptions = {7: "week", 14: "two weeks", 21: "three weeks", 42: "six weeks", 63: "nine weeks", 84: "twelve weeks"};

export default function SPOTnotSetup(){

    const [loadingBar, setLoadingBar] = useState(true); // To show/hide loading bar
    const [loaded,setLoaded] = useState(false)
    const [termsFetched, setTermsFetched] = useState(false)
    const [classesFetched,setClassesFetched] = useState(false)
    const [classData,setClassData] = useState([])
    const [filteredClassData, setFilteredClassData] = useState([])
    const [terms, setTerms] = useState([])
    const [selectedTerm, setSelectedTerm] = useState(null)
    const [outLook, setOutLook] = useState(7)
    const [deptFilter,setDeptFilter] = useState([])
    const [ctypeFilter,setCtypeFilter] = useState([])
    const [openWindow, setOpenWindow] = useState(false); 
    const [selectedClasses, setSelectedClasses] = useState([])
    const [emailTabs, setEmailTabs] = useState([])
    const [value, setValue] = useState("1");
    const [emailsSent, setEmailsSent] = useState([])

    const [emailInfo,setEmailInfo] = useState([])




    useEffect(() => {
        let url = process.env.REACT_APP_BACKEND_HOST
        async function fetch_terms() {
            const response = await fetch(url.concat('/spot/terms_list'), {
                headers: { Authorization: `Token ${sessionStorage.token}` },
              });
            const json = await response.json();

            let apiData = [];
            for (let i = 0; i < json.length; i++) {
                apiData.push(json[i].term);
            }
          setTermsFetched(true)
          return apiData
        }

        

        async function fetch_classes() {
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", 
                                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const response = await fetch(url.concat('/spot/spot_not_setup/',selectedTerm,'/',outLook,'/'), {
                headers: { Authorization: `Token ${sessionStorage.token}` },
              });
            const json = await response.json();
            let apiData = [];
            let idValue = 0;
            for (let i = 0; i < json.length; i++) {
                const c_sdate = new Date(json[i].COURSE_START)
                const c_edate = new Date(json[i].COURSE_END)
                let entry = {
                    id: idValue,
                    term: selectedTerm,
                    classid: json[i].CLASSID,
                    instructor: json[i].INSTRUCTOR,
                    department: json[i].DEPARTMENT.toString(),
                    ctitle: json[i].CTITLE.toString(),
                    course_start: `${monthNames[c_sdate.getMonth()]} ${c_sdate.getDate()}, ${c_sdate.getFullYear()}`,
                    course_end: `${monthNames[c_edate.getMonth()]} ${c_edate.getDate()}, ${c_edate.getFullYear()}`,
                    csize: json[i].CSIZE,
                    ctype: json[i].CTYPE.toString(),
                    // evaluate: json[i].EVALUATE ? json[i].EVALUATE.toString() : 'N/A',
                    // form: json[i].FORM ? json[i].FORM.toString() : 'N/A'
                    email: json[i].EMAIL ? json[i].EMAIL : 'N/A'
                  };
                  apiData.push(entry);
                  idValue++;
            }
          return apiData
        }
        
        if (!termsFetched) {
            let apiData = [fetch_terms()];
        
              Promise.all(apiData).then((values) => {
                let data = values[0].slice().sort((a, b) => b - a).map(item => item.toString())
                setTerms(data.slice(0, 3))
                setSelectedTerm(data[0])
              });
              
        }
        if (termsFetched && !classesFetched){
            let apiData = [fetch_classes()];
        
              Promise.all(apiData).then((values) => {
                let data = values[0]
                setClassData(data)
                setFilteredClassData(data)
                setEmailTabs([...new Set(classData.map(item => item.department))].sort())
              });
              // Mark the data as loaded to prevent repeated fetching
              setLoaded(true);
              setClassesFetched(true)
        }

        if (loaded && loadingBar) {
            setTimeout(() => {
                setLoadingBar(false);
            }, 3000);
        }
    
    },[loaded, terms, termsFetched, loadingBar,classesFetched,selectedTerm, outLook, emailTabs, classData])

    const handleRefresh = (event, newValue) => {
        // Update the selectedTerm state when the value changes
        setClassesFetched(false)
        setLoaded(false)
        setLoadingBar(true)
    };

    const handleTermChange = (event, newValue) => {
        // Update the selectedTerm state when the value changes
        setClassesFetched(false)
        setLoaded(false)
        setLoadingBar(true)
        setSelectedTerm(newValue);
    };

    const handleOutlookChange = (event, newValue) => {
        setClassesFetched(false)
        setLoaded(false)
        setLoadingBar(true)
        setOutLook(event.target.value);
    };


    const handleDepartmentChange = (event, newValue) => {
        if (ctypeFilter.length > 0){
            if (newValue.length > 0){
                const filteredList_1 = classData.filter(item => newValue.includes(item.department))
                const filteredList_2 = classData.filter(item => ctypeFilter.includes(item.ctype))
                const intersection = filteredList_1.filter(value => filteredList_2.includes(value));
                setDeptFilter(newValue)
                setFilteredClassData(intersection)
            }else{
                const filteredList = classData.filter(item => ctypeFilter.includes(item.ctype))
                setDeptFilter([])
                setFilteredClassData(filteredList)
            }
        }else{
            if (newValue.length > 0){
                const filteredList = classData.filter(item => newValue.includes(item.department));
                setDeptFilter(newValue)
                setFilteredClassData(filteredList)
            }else{
                setDeptFilter([])
                setFilteredClassData(classData)
            }
        }
    };

    const handleCtypeChange = (event, newValue) => {
        if (deptFilter.length > 0){
            if (newValue.length > 0){
                const filteredList_1 = classData.filter(item => newValue.includes(item.ctype))
                const filteredList_2 = classData.filter(item => deptFilter.includes(item.department))
                const intersection = filteredList_1.filter(value => filteredList_2.includes(value));
                setCtypeFilter(newValue)
                setFilteredClassData(intersection)
            }else{
                const filteredList = classData.filter(item => deptFilter.includes(item.department))
                setCtypeFilter([])
                setFilteredClassData(filteredList)
            }
        }else{
            if (newValue.length > 0){
                setCtypeFilter(newValue)
                const filteredList = classData.filter(item => newValue.includes(item.ctype));
                setFilteredClassData(filteredList)
            }else{
                setCtypeFilter([])
                setFilteredClassData(classData)
            }
        }
    };

    const handleClickOpen = () => {
        // if (selectedReqnum.length > 0){setOpenWindow(true);}
        setOpenWindow(true)
    };

    const handleClose = () => {
        setOpenWindow(false);
    };

    function select_classes(items){
        const selectedItems = []
        for (let i = 0; i < items.length; i++) {
            let entry = filteredClassData.filter((x) => x.id === items[i]);
            selectedItems.push(entry[0])
        }
        setSelectedClasses(selectedItems)

        // Create a set of unique departments
        const uniqueDepartments = new Set(selectedItems.map(item => item.department));
        const uniqueCount = uniqueDepartments.size;

        // Initialize emailsSent array with false values
        setEmailsSent(new Array(uniqueCount).fill(false));

        // Prepare email info based on unique departments
        const emailInfo = Array.from(uniqueDepartments).map(department => {
        const departmentClasses = selectedItems.filter(item => item.department === department);
        const courseTitles = Array.from(new Set(departmentClasses.map(item => item.ctitle))).join('\n\t- ');

        return {
            department,
            address: Array.from(new Set(departmentClasses.map(item => item.email))).join(', '),
            subject: 'SPOT Surveys Not Ordered - Action Required',
            body: `Hello,\n
As per the Student Input to the Evaluation of Teaching and Learning Procedure, “The SPOT survey will be used each time that a course is offered...”.  

Currently, the following course(s) are not set up for SPOT surveys, and according to Campus Solutions, their end date is within the next ${outLookOptions[outLook]} or sooner:
\t- ${courseTitles}\n
Please set up SPOT surveys for these courses or confirm if they do not require a SPOT survey.\n
If you have any questions or encounter issues, please don't hesitate to reach out. Thank you for your attention to this matter!

Best regards,
Online SPOT Administrator`
    };
    });

    setEmailInfo(emailInfo);
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue); // Update the state with the new tab value
      };


    // Helper function to get unique departments
    const getUniqueDepartments = (classes) => {
        return Array.from(new Map(classes.map(item => [item.department, item])).values());
    };

    function handleEmailAddressChange(index){
        return (event) => {
            setEmailInfo(prevInfo => {
                const updatedInfo = [...prevInfo]; // Create a new array (avoids mutation)
                updatedInfo[index].address = event.target.value; // Update the specific index
                return updatedInfo; // Return new state
            });
        };
    };


    function handleEmailSubjectChange(index){
        return (event) => {
            setEmailInfo(prevInfo => {
                const updatedInfo = [...prevInfo]; // Create a new array (avoids mutation)
                updatedInfo[index].subject = event.target.value; // Update the specific index
                return updatedInfo; // Return new state
            });
        };
    };


    function handleEmailBodyChange(index){
        return (event) => {
            setEmailInfo(prevInfo => {
                const updatedInfo = [...prevInfo]; // Create a new array (avoids mutation)
                updatedInfo[index].body = event.target.value; // Update the specific index
                return updatedInfo; // Return new state
            });
        };
    };

    function sendEmail(index){
        setEmailsSent(prevEmails => {
            const updatedEmails = [...prevEmails]; // Create a new array (avoids mutation)
            updatedEmails[index] = true; // Update the specific index
            return updatedEmails; // Return new state
        });


        fetch(process.env.REACT_APP_BACKEND_HOST.concat('/spot/SPOTsNotSetup_email/'), {
            method: "POST", // Specify the method explicitly
            headers: {
                "Authorization": `Token ${sessionStorage.token}`,
                "Content-Type": "application/json" // Ensure the backend expects JSON
            },
            body: JSON.stringify(emailInfo[index]),
        })
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error('Error:', error));
    }
    

    return(
    
        <div>
            <Box 
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '15px',
                }}
                >
                <Box sx={{ paddingLeft: '12px' }}>
                    <Button
                        style={{ backgroundColor: "#275d38",maxWidth:'400px'}}
                        variant="contained"
                        endIcon={<RefreshIcon />}
                        onClick={handleRefresh}
                        >
                        <Typography style ={{fontSize: '16px'}}>REFRESH</Typography>
                    </Button>
                </Box>
                <Autocomplete
                    disablePortal
                    value={selectedTerm}
                    onChange={handleTermChange}
                    size="small"
                    options={terms}
                    sx={{ width: 200, paddingLeft: '12px'}}
                    renderInput={(params) => (
                    <TextField 
                        {...params} 
                        label="TERM" 
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingBar ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )}}
                    />
                    )}
                />
                <Box sx={{ paddingLeft: '12px' }}>
                    <FormControl sx={{ width: 200 }} size="small">
                        <InputLabel id="outlook-label">OUTLOOK</InputLabel>
                        <Select
                            labelId="outlook-label"
                            value={outLook} // Replace with your state variable
                            onChange={handleOutlookChange}
                            label="OUTLOOK"
                            sx={{ textAlign: 'left'}}
                        >
                            <MenuItem value={7}>1 Week</MenuItem>
                            <MenuItem value={14}>2 Weeks</MenuItem>
                            <MenuItem value={21}>3 Weeks</MenuItem>
                            <MenuItem value={42}>6 Weeks</MenuItem>
                            <MenuItem value={63}>9 Weeks</MenuItem>
                            <MenuItem value={84}>12 Weeks</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Autocomplete
                    multiple
                    limitTags={2}
                    onChange={handleDepartmentChange}
                    size="small"
                    options={[...new Set(classData.map(item => item.department))].sort()} // Ensure unique and sorted departments
                    disableCloseOnSelect
                    sx={{ width:  550, paddingLeft:'12px' }}
                    renderInput={(params) => (
                        <TextField 
                            {...params} 
                            label="DEPARTMENT" 
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loadingBar ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )}}
                        />
                        )}
                />
                <Autocomplete
                    multiple
                    limitTags={2}
                    onChange={handleCtypeChange}
                    size="small"
                    options={[...new Set(classData.map(item => item.ctype))].sort()} // Ensure unique and sorted departments
                    disableCloseOnSelect
                    sx={{ width:  300, paddingLeft:'12px' }}
                    renderInput={(params) => (
                        <TextField 
                            {...params} 
                            label="CTYPE" 
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loadingBar ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )}}
                        />
                        )}
                />
               
                <Box sx={{ paddingLeft: '12px' }}>
                    <Button
                        style={{ backgroundColor: "#275d38",maxWidth:'400px'}}
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={handleClickOpen}
                        >
                        <Typography style ={{fontSize: '16px'}}>EMAIL</Typography>
                    </Button>
                </Box>
            </Box>
            <Box 
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '15px',
                    paddingLeft: '100px',
                    paddingRight: '100px'
                }}
                >
                <DataGrid
                        getRowHeight={() => 'auto'}
                        style={{ textAlign: "center" }}
                        columns={columns}
                        rows={filteredClassData}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                        getRowClassName={(params) => {
                            return params.row.classes !== params.row.loaded ? "highlight" : "";
                          }}
                        checkboxSelection
                        onSelectionModelChange={(item) => select_classes(item)}
                        density={"compact"}
                        slots={{
                            Pagination: CustomPagination,
                            // LoadingOverlay: LinearProgress,
                            LoadingOverlay: CircularProgress,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        loading={loadingBar} 
                        sx={{
                            ".highlight": {
                            color: "red"
                            },
                            height: '65vh', width:'100%'}}
                />
                <Box sx={{ }}>
                    <Dialog open={openWindow} onClose={handleClose} fullWidth={true} maxWidth={'xl'}>
                        <DialogTitle sx={{m: 0, p: 2,fontWeight: "bold"}}>
                            EMAIL WEB USERS
                        </DialogTitle>
                        <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                        >
                        <CloseIcon />
                        </IconButton>
                        <DialogContent dividers>
                        {selectedClasses.length < 1 ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Typography style ={{fontSize: '16px', color: 'red' }}>
                                    NO CLASSES SELECTED
                                </Typography>
                            </Box>
                        ):(
                            <Box 
                                sx={{
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'top',
                                    overflowY: 'auto', 
                                    // maxHeight: '720px',
                                    // minHeight: '720px',
                                    maxHeight: '80vh',
                                    minHeight: '80vh',
                                    // border: '2px solid black'
                                }}
                                >
                                <TabContext value={value}>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleTabChange}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                        {getUniqueDepartments(selectedClasses).map((item, index) => (
                                            <Tab key={index} label={item.department} value={(index + 1).toString()} />
                                        ))}
                                    </Tabs>
                                        
                                    <Box sx={{ width: '100%' }}>
                                        {Array.from(new Map(selectedClasses.map(item => [item.department, item])).values()).map((item, index) => {
                                            return (
                                                <TabPanel key={index} value={(index + 1).toString()}>
                                                    <Box sx={{ display:'flex', textAlign: 'left',flexDirection: 'column', justifyContent: 'start', alignItems: 'center',
                                                        // border: '1px solid black' 
                                                    }}>
                                                    {emailsSent[index] === false? (
                                                        <>
                                                            <TextField
                                                                sx = {{width: '100%',paddingBottom:"10px"}}
                                                                required
                                                                id="outlined-required"
                                                                label="To"
                                                                size="small"
                                                                // defaultValue={`${emails}`}
                                                                value={emailInfo[index].address}
                                                                onChange={handleEmailAddressChange(index)}
                                                            />
                                                            <TextField
                                                                sx = {{width: '100%',paddingBottom:"10px"}}
                                                                required
                                                                id="outlined-required"
                                                                label="Email Subject"
                                                                size="small"
                                                                value = {emailInfo[index].subject}
                                                                onChange={handleEmailSubjectChange(index)}
                                                            />
                                                            <TextField
                                                                sx={{ width: '100%' ,paddingBottom:"10px"}}
                                                                required
                                                                id="outlined-required"
                                                                label="Email Body"
                                                                multiline
                                                                size="small"
                                                                value = {emailInfo[index].body}
                                                                onChange={handleEmailBodyChange(index)}
                                                                rows={20}
                                                            />
                                                            <Box 
                                                                sx={{
                                                                    textAlign: 'center',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingTop: '20px',
                                                                }}>
                                                                <Button
                                                                    style={{ backgroundColor: "#275d38",width:'200px'}}
                                                                    variant="contained"
                                                                    endIcon={<SendIcon />}
                                                                    onClick={() => sendEmail(index)}
                                                                    >
                                                                    <Typography style ={{fontSize: '16px'}}>SEND</Typography>
                                                                </Button>
                                                            </Box>
                                                        </>
                                                    ):(
                                                        <Box 
                                                            sx={{
                                                                textAlign: 'center',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                paddingTop: '15px',
                                                            }}
                                                        >
                                                            <Typography style ={{fontSize: '16px', color: 'green' }}>
                                                                EMAIL SENT
                                                            </Typography>
                                                        </Box>
                                                )} 
                                                    </Box>
                                                </TabPanel>
                                            );
                                        })}
                                    </Box>

                                </TabContext>
                            </Box>
                            )}
                        </DialogContent>
                    </Dialog>

                </Box>
            </Box>
        </div>
    )
}