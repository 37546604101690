import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { toggleBulkGradesSubmitted } from "../../../components/Api/Updates";

export default function ConfirmUndoDialog(props) {
	const handleClose = () => {
		props.setOpen(false);
	};

	const handleConfirmGradesSubmitted = async () => {
		const response = await toggleBulkGradesSubmitted(
			props.token,
			[props.cls_uqid],
			props.operation
		);
		console.log(response);
		props.refreshData();
		props.setOpen(false);
	};
	return (
		<div>
			<Dialog
				open={props.open}
				onClose={handleClose}
				maxWidth="2xl"
			>
				<DialogTitle textAlign={"center"}>Undo Grade Finalization</DialogTitle>

				<div className="flex flex-col gap-5 max-w-3xl p-4 ">
					<p>
						If results have not been delivered to instructors, you can undo a
						grade finalization.
					</p>
					<div className="grid grid-cols-1 max-h-[300px] overflow-y-auto text-left bg-gray-100 p-1 rounded-md divide-y divide-gray-300">
						{props.ctitle}
					</div>

					<p sx={{ maxWidth: "600px", color: "black" }}>
						If you would like to undo grade finalization for the above class,
						click on "Confirm".
					</p>
				</div>

				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button
						onClick={handleConfirmGradesSubmitted}
						autoFocus
						variant="contained"
						color="primary"
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
