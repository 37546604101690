import { useEffect, useState } from "react";
import {
	TextField,
	Button,
	IconButton,
	Menu,
	MenuItem,
	Switch,
	FormControlLabel,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	listOfAnswerOptions,
	listOfKeys,
	listOfKeysComp,
	listOfKeysFormula,
} from "../utils/common";
import ClassDropDown from "../components/ClassDropDown";
import axios from "axios";
import { setDate, getInitials } from "../utils/helpers";
import { postRFSData } from "../../../components/Api/Post";

export default function GPScor(props) {
	const {
		state,
		setState,
		navigate,
		ccid,
		token,
		inputProps,
		handlePrint,
		classNumList,
		instructorClasses,
	} = props;
	const [numQuestionsHelpText, setNumQuestionsHelpText] = useState(" ");
	const [classNumHelpText, setClassNumHelpText] = useState(" ");
	const [notesError, setNotesError] = useState(false);
	const [notesHelpText, setNotesHelpText] = useState(" ");
	const [multiple, setMultiple] = useState(false);
	const notesText =
		"Enter your comments here: Omitted questions, multiple answers accepted, etc.";

	const [advancedSettings, setAdvancedSettings] = useState({
		differentStarting: false,
		formulaScoring: false,
		compositeScoring: false,
	});

	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const isMenuOpen = Boolean(menuAnchorEl);

	const [errorState, setErrorState] = useState({
		lqNum: false,
		hqNum: false,
		qLen: false,
		yesOrNo: false,
		weightingSingle: false,
		weightingMultiple: false,
		partial: false,
		overanswering: false,
		penalties: false,
		notes: false,
		classNum: false,
		examTotalMarks: false,
	});
	const [classSearch, setClassSearch] = useState(false);
	const [searchString, setSearchString] = useState("");
	const [courseList, setCourseList] = useState([]);

	useEffect(() => {
		if (searchString.length > 0) {
			const filteredCourses = classNumList.filter(
				(item) =>
					item.label.toLowerCase().includes(searchString.toLowerCase()) ||
					(item.name &&
						item.name.toLowerCase().includes(searchString.toLowerCase()))
			);
			setCourseList(filteredCourses);
		} else {
			setCourseList(classNumList);
		}
	}, [searchString, classNumList]);

	useEffect(() => {
		if (state.valueNumKeys >= "2" || advancedSettings.compositeScoring) {
			setState({ ...state, c: "1" });
		} else {
			setState({
				...state,
				c: "0",
			});
		}
	}, [state.valueNumKeys, advancedSettings.compositeScoring]);

	useEffect(() => {
		if (advancedSettings.compositeScoring) {
			setState({ ...state, e: Number(state.valueNumKeys) + 1 });
		}
	}, [state.valueNumKeys, advancedSettings.compositeScoring]);

	useEffect(() => {
		if (advancedSettings.formulaScoring) {
			setState({ ...state, f: "1" });
		} else {
			setState({ ...state, f: "0" });
		}
	}, [advancedSettings.formulaScoring]);

	const handleMenuClick = (event) => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setMenuAnchorEl(null);
	};

	const handleChangeClassDropdown = (event) => {
		const isMultiple = event.target.checked;
		setMultiple(isMultiple);
		// If multiple is false, use an empty string; if true, use an empty array.
		setState({ ...state, valueClassNum: isMultiple ? [] : "" });
	};
	const handleGPScorChange = (event) => {
		const value = event.target.value;

		setState({ ...state, [event.target.name]: value });
		if (event.target.name === "lqNum" && value <= 250) {
			setErrorState((prev) => ({ ...prev, lqNum: false }));
			setNumQuestionsHelpText(" ");
		}
		if (event.target.name === "hqNum" && value <= 250) {
			setErrorState((prev) => ({ ...prev, hqNum: false }));
			setNumQuestionsHelpText(" ");
		}
		if (event.target.name === "valueClassNum") {
			setErrorState((prev) => ({ ...prev, classNum: false }));
			setClassNumHelpText(" ");
		}
	};

	const handleChange = (event) => {
		const value = event.target.value;
		setState({
			...state,
			[event.target.name]: value,
		});

		if (event.target.name === "notes") {
			setNotesError(false);
			setNotesHelpText(" ");
		}
		if (event.target.name === "valueNumKeys") {
			setErrorState((prev) => ({ ...prev, valueNumKeys: false }));
			setNumQuestionsHelpText(" ");
		}
	};
	const addLeadingZerosQuestions = (numQuestions) => {
		let numQuestionsString = numQuestions.toString();

		if (numQuestionsString.length === 1) {
			numQuestionsString = "00".concat(numQuestionsString);
		} else if (numQuestionsString.length === 2) {
			numQuestionsString = "0".concat(numQuestionsString);
		}
		return numQuestionsString;
	};

	const addLeadingZerosAnswers = (numAnswers) => {
		let numAnswersString = numAnswers.toString();
		if (numAnswersString.length === 1) {
			numAnswersString = "0".concat(numAnswersString);
		}
		return numAnswersString;
	};

	const [finalNotes, setFinalNotes] = useState("");

	useEffect(() => {
		if (advancedSettings.compositeScoring) {
			let notes = state.notes;
			let additionalNotes = `COMP: ${state.valueNumKeys} keys : Weights: ${
				state.compKey1Weight || ""
			} ${state.compKey2Weight || ""} ${state.compKey3Weight || ""} ${
				state.compKey4Weight || ""
			} ${state.compKey5Weight || ""}`;
			setFinalNotes(additionalNotes.concat(notes));
		} else if (advancedSettings.formulaScoring) {
			let notes = state.notes;
			let additionalNotes = `FORMULA SCORING: `;
			setFinalNotes(additionalNotes.concat(notes));
		} else {
			setFinalNotes(state.notes);
		}
	}, [
		state.notes,
		state.valueNumKeys,
		state.compKey1Weight,
		state.compKey2Weight,
		state.compKey3Weight,
		state.compKey4Weight,
		state.compKey5Weight,
	]);

	const postData = async () => {
		let hqNum = addLeadingZerosQuestions(String(state.hqNum));
		let lqNum = addLeadingZerosQuestions(String(state.lqNum));
		let numAnswers = addLeadingZerosAnswers(state.qLen);

		let classTitle = classNumList.find(
			(item) =>
				item.value === state.valueClassNum[0] ||
				item.value === state.valueClassNum
		).label;

		if (classTitle.split(" ").length > 4) {
			//combine the first two indexes
			let classTitle1 = classTitle.split(" ").slice(0, 2).join("").trim();
			let classTitle2 = classTitle.split(" ").slice(2).join(" ").trim();
			classTitle = classTitle1 + " " + classTitle2;
		}

		const data = {
			userid: ccid,
			caddr1: state.instructorName,
			accountnum: 502306,
			coursetitle: classTitle.substring(0, 16),
			dept: state.deptID,
			program: state.program,
			caddr2: state.department
				.trim()
				.replace(/ *\([^)]*\) */g, "")
				.substring(0, 32),
			caddr3: state.address.substring(0, 60),
			rqdate: setDate(),
			lqnum: lqNum,
			hqnum: hqNum,
			qlen: numAnswers,
			nkeys: state.valueNumKeys,
			a: "3",
			b: "1",
			c: state.c,
			d: "2",
			e: state.e,
			f: state.f,
			k: "7",
			l: "0",
			m: "0",
			n: "1",
			o: "0",
			p: "0",
			pocketflag: "1",
			initials: getInitials(state.instructorName),
			instructor_name: state.instructorName,
			email1: state.email1,
			email2: state.email2,
			phone: state.phone,
			speedcode: 84311,
			numopen: state.numOpen,
			docsize: state.docSize,
			notes: finalNotes,
			status: "Received",
			classNum: Array.isArray(state.valueClassNum)
				? state.valueClassNum.join(",")
				: String(state.valueClassNum),
		};

		const response = await postRFSData(token, data);

		return response;
	};

	const handleSubmit = async () => {
		if (state.lqNum === "") {
			setNumQuestionsHelpText("Please enter a value");
			setErrorState((prev) => ({ ...prev, lqNum: true }));
		}
		if (state.hqNum === "" || Number(state.hqNum) > 250) {
			setNumQuestionsHelpText(
				"Please enter a valid number. Must be 250 or less."
			);
			setErrorState((prev) => ({ ...prev, hqNum: true }));
		}
		if (
			state.valueNumKeys === "" ||
			state.valueNumKeys === "Select an option"
		) {
			setErrorState((prev) => ({ ...prev, valueNumKeys: true }));
		}
		if (
			(state.valueClassNum.length === 0 || !state.valueClassNum) &&
			classNumList.length > 1
		) {
			setErrorState((prev) => ({ ...prev, classNum: true }));
			setClassNumHelpText("Please select a class.");
		} else if (
			(state.valueClassNum.length === 0 || !state.valueClassNum) &&
			classNumList.length <= 1
		) {
			setNotesError(true);
			setNotesHelpText("Please enter a class as a note.");
		} else if (
			Number(state.lqNum) >= 1 &&
			Number(state.hqNum) !== 0 &&
			Number(state.hqNum) <= 250 &&
			state.instructorName.length > 4 &&
			state.deptID !== "" &&
			state.email1 !== "" &&
			state.address !== "" &&
			(state.valueClassNum > 0 || state.valueClassNum.length > 0)
		) {
			postData()
				.then(async (response) => {
					let reqnum = response.reqnum;
					await handlePrint(reqnum);
					navigate("/success");
				})
				.catch((error) => {
					console.log("error", error);
					alert(
						"There was an error submitting your request, please try again. If the problem persists, please contact TSQS for assistance."
					);
				});
		} else {
			alert("Please fill out all required fields.");
		}
	};

	useEffect(() => {
		if (advancedSettings.compositeScoring) {
			if (state.valueNumKeys >= "6") {
				setState({ ...state, valueNumKeys: "5" });
			} else if (state.valueNumKeys === "1") {
				setState({ ...state, valueNumKeys: "2" });
			}
		}
	}, [advancedSettings.compositeScoring]);

	useEffect(() => {
		if (advancedSettings.formulaScoring) {
			if (state.valueNumKeys >= "5") {
				setState({ ...state, valueNumKeys: "4" });
			}
		}
	}, [advancedSettings.formulaScoring]);

	const handleChangeSelectClass = (event) => {
		const value = event.target.value;

		if (
			typeof state.valueClassNum === "object" &&
			state.valueClassNum.length >= 1
		) {
			setMultiple(true);
		}
		if (
			typeof state.valueClassNum === "object" &&
			state.valueClassNum.includes(Number(value))
		) {
			const valueClassNum = state.valueClassNum.filter(
				(item) => Number(item) !== Number(value)
			);
			setState({ ...state, valueClassNum });
			setErrorState((prev) => ({ ...prev, classNum: false }));
			if (valueClassNum.length <= 1) {
				setMultiple(false);
			}
		} else {
			setState((prev) => ({
				...prev,
				valueClassNum: [...prev.valueClassNum, Number(value)],
			}));
			setErrorState((prev) => ({ ...prev, classNum: false }));
		}
	};

	return (
		<div className="relative w-full">
			{/* Advanced Settings Menu Icon */}
			<div className="absolute top-0 right-0 border-2 rounded-full hover:bg-gray-100">
				<IconButton
					onClick={handleMenuClick}
					aria-controls={isMenuOpen ? "advanced-settings-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={isMenuOpen ? "true" : undefined}
				>
					<MoreVertIcon />
				</IconButton>

				{/* Advanced Settings Menu */}
				<Menu
					id="advanced-settings-menu"
					anchorEl={menuAnchorEl}
					open={isMenuOpen}
					onClose={handleMenuClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<MenuItem>
						<FormControlLabel
							control={
								<Switch
									checked={advancedSettings.differentStarting}
									onChange={(e) => {
										setAdvancedSettings((prev) => ({
											...prev,
											differentStarting: e.target.checked,
										}));
										//if different starting gets unchecked, set state.lqNum to 1
										// if (!e.target.checked) {
										// 	setState((prev) => ({
										// 		...prev,
										// 		lqNum: "1",
										// 	}));
										// }
									}}
								/>
							}
							label="Different lowest question #"
						/>
					</MenuItem>
					<MenuItem>
						<FormControlLabel
							control={
								<Switch
									disabled={advancedSettings.compositeScoring}
									checked={advancedSettings.formulaScoring}
									onChange={(e) =>
										setAdvancedSettings((prev) => ({
											...prev,
											formulaScoring: e.target.checked,
										}))
									}
								/>
							}
							label="Formula Scoring"
						/>
					</MenuItem>
					<MenuItem>
						<FormControlLabel
							control={
								<Switch
									disabled={advancedSettings.formulaScoring}
									checked={advancedSettings.compositeScoring}
									onChange={(e) => {
										// if (e.target.checked) {
										// 	setState((prev) => ({
										// 		...prev,
										// 		e: Number(state.valueNumKeys) + 1,
										// 	}));
										// }
										setAdvancedSettings((prev) => ({
											...prev,
											compositeScoring: e.target.checked,
										}));
									}}
								/>
							}
							label="Composite Scoring"
						/>
					</MenuItem>
				</Menu>
			</div>

			{/* Main Form Content */}
			<div className="flex flex-col w-full gap-4 pt-8">
				{advancedSettings.differentStarting && (
					<div className="flex flex-col">
						<label
							htmlFor="lqNum"
							className="font-medium text-primary"
						>
							What is the lowest question number on your exam?
						</label>
						<input
							type="number"
							id="lqNum"
							name="lqNum"
							value={state.lqNum || ""}
							onChange={handleGPScorChange}
							className={`mt-1 p-2 border rounded ${
								errorState.lqNum ? "border-red-500" : "border-gray-300"
							}`}
						/>
						{errorState.lqNum && (
							<span className="text-red-500 text-xs mt-1">
								{numQuestionsHelpText}
							</span>
						)}
					</div>
				)}

				{/* Highest question number */}
				<div className="flex flex-col">
					<label
						htmlFor="hqNum"
						className="font-medium text-primary"
					>
						What is the highest question number on your exam?
					</label>
					<input
						type="number"
						id="hqNum"
						name="hqNum"
						value={state.hqNum || ""}
						onChange={handleGPScorChange}
						className={`mt-1 p-2 border rounded ${
							errorState.hqNum ? "border-red-500" : "border-gray-300"
						}`}
					/>
					{errorState.hqNum && (
						<span className="text-red-500 text-xs mt-1">
							{numQuestionsHelpText}
						</span>
					)}
				</div>

				<div className="flex flex-col">
					<label
						htmlFor="qLen"
						className="font-medium text-primary"
					>
						How many answer options for each question?{" "}
						<span className="text-sm text-gray-500">
							(If varied, select the highest)
						</span>
					</label>
					<select
						id="qLen"
						name="qLen"
						value={state.qLen || ""}
						onChange={handleGPScorChange}
						className={`mt-1 hover:cursor-pointer p-2 border rounded ${
							errorState.qLen ? "border-red-500" : "border-gray-300"
						}`}
					>
						{listOfAnswerOptions.map((option) => (
							<option
								key={option.key}
								value={option.value}
							>
								{option.label}
							</option>
						))}
					</select>
					{errorState.qLen && (
						<span className="text-red-500 text-xs mt-1">
							{numQuestionsHelpText}
						</span>
					)}
				</div>

				<div className="flex flex-col">
					<label
						htmlFor="valueNumKeys"
						className="font-medium text-primary"
					>
						How many answer keys for your exam?
					</label>
					{advancedSettings.compositeScoring && (
						<select
							id="valueNumKeys"
							name="valueNumKeys"
							value={state.valueNumKeys || ""}
							onChange={handleGPScorChange}
							className={`mt-1 hover:cursor-pointer p-2 border rounded ${
								errorState.valueNumKeys ? "border-red-500" : "border-gray-300"
							}`}
						>
							<option
								value=""
								key="996"
							>
								Select an option
							</option>
							{listOfKeysComp.map((option) => (
								<option
									key={option.key}
									value={option.value}
								>
									{option.label}
								</option>
							))}
						</select>
					)}
					{advancedSettings.formulaScoring && (
						<select
							id="valueNumKeys"
							name="valueNumKeys"
							value={state.valueNumKeys || ""}
							onChange={handleGPScorChange}
							className={`mt-1 hover:cursor-pointer p-2 border rounded ${
								errorState.valueNumKeys ? "border-red-500" : "border-gray-300"
							}`}
						>
							<option
								value=""
								key="996"
							>
								Select an option
							</option>
							{listOfKeysFormula.map((option) => (
								<option
									key={option.key}
									value={option.value}
								>
									{option.label}
								</option>
							))}
						</select>
					)}

					{!advancedSettings.formulaScoring &&
						!advancedSettings.compositeScoring && (
							<select
								id="valueNumKeys"
								name="valueNumKeys"
								value={state.valueNumKeys || ""}
								onChange={handleGPScorChange}
								className={`mt-1 hover:cursor-pointer p-2 border rounded ${
									errorState.valueNumKeys ? "border-red-500" : "border-gray-300"
								}`}
							>
								<option
									value=""
									key="997"
								>
									Select an option
								</option>
								{listOfKeys.map((option) => (
									<option
										key={option.key}
										value={option.value}
									>
										{option.label}
									</option>
								))}
							</select>
						)}

					{errorState.valueNumKeys && (
						<span className="text-red-500 text-xs mt-1">
							{numQuestionsHelpText}
						</span>
					)}
				</div>
				{advancedSettings.compositeScoring &&
					Array.from({ length: Number(state.valueNumKeys) }).map((_, index) => (
						<div
							key={index + 1}
							className="flex flex-col"
						>
							<label
								htmlFor={`compKeyWeight-${index}`}
								className="font-medium text-primary"
							>
								Weight for key {index + 1}:
							</label>
							<select
								id={`compKey${index + 1}Weight`}
								name={`compKey${index + 1}Weight`}
								value={state[`compKey${index + 1}Weight`] || ""}
								onChange={handleGPScorChange}
								className={`mt-1 p-2 border rounded ${
									errorState[`compKey${index + 1}Weight`]
										? "border-red-500"
										: "border-gray-300"
								}`}
							>
								<option
									value=""
									key="998"
								>
									Select an option
								</option>
								{listOfKeys.map((option) => (
									<option
										key={`compKey-${index}-${option.label}`}
										value={option.value}
									>
										{option.label}
									</option>
								))}
							</select>
						</div>
					))}
				<label
					htmlFor="valueClassNum"
					className="col-span-3 font-medium text-primary"
				>
					Select your class(es) from the list below (REQUIRED):
				</label>
				<div
					className={`flex flex-col gap-2 bg-gray-200 p-2 rounded-md ${
						errorState.classNum ? "border-red-500 border-2" : ""
					}`}
				>
					{!classSearch &&
						instructorClasses &&
						instructorClasses.length >= 1 && (
							<div className="font-medium text-primary flex flex-col gap-2">
								{/* <label htmlFor="valueClassNum">
							Select your class(es) (REQUIRED):
						</label> */}
								{instructorClasses.map((classItem) => (
									<label
										key={classItem.value}
										htmlFor={classItem.value}
										className="flex items-center gap-2 border-2 rounded-md px-2 py-1 cursor-pointer bg-white"
									>
										<input
											type="checkbox"
											id={classItem.value}
											name="valueClassNum"
											value={classItem.value}
											onChange={handleChangeSelectClass}
											checked={
												(typeof state.valueClassNum === "object" &&
													state.valueClassNum.includes(classItem.value)) ||
												(typeof state.valueClassNum === "number" &&
													state.valueClassNum === classItem.value)
											}
										/>
										<span className="text-primary">{classItem.label}</span>
									</label>
								))}
								<label className="flex items-center gap-2 underline  py-1 cursor-pointer text-gray-500">
									<button onClick={() => setClassSearch(!classSearch)}>
										Click here if your class is not listed
									</button>
								</label>
							</div>
						)}
					{(classSearch ||
						!instructorClasses ||
						instructorClasses.length === 0) && (
						<div className="pl-[3px]">
							<input
								type="text"
								placeholder="Start typing here to filter for class or instructor"
								className="rounded-md px-2 py-2 placeholder:font-semibold placeholder:text-gray-500 focus:ring-primary focus:ring-2 border-0 outline-none w-full ring-2"
								value={searchString}
								onChange={(e) => setSearchString(e.target.value)}
							/>
						</div>
					)}
					{(classSearch ||
						!instructorClasses ||
						(instructorClasses && instructorClasses.length === 0)) && (
						<div className="grid grid-cols-3 gap-2 max-h-[40dvh] overflow-y-auto pr-2 pl-[3px] py-[3px]  font-medium">
							{courseList.length >= 1 &&
								courseList.map((classItem) => (
									<label
										key={classItem.value}
										htmlFor={classItem.value}
										className={` bg-white flex items-center gap-2  rounded-md px-2 py-1 cursor-pointer hover:ring-2 ${
											state.valueClassNum === classItem.value ||
											state.valueClassNum.includes(classItem.value)
												? "ring-primary ring-2"
												: ""
										}`}
									>
										<input
											type="checkbox"
											className=""
											id={classItem.value}
											name="valueClassNum"
											value={classItem.value}
											onChange={handleChangeSelectClass}
											checked={
												(typeof state.valueClassNum === "object" &&
													state.valueClassNum.includes(classItem.value)) ||
												(typeof state.valueClassNum === "number" &&
													state.valueClassNum === classItem.value)
											}
										/>
										<div className="flex flex-col">
											<span className="">{classItem.label}</span>
											<p className="text-sm font-normal">{classItem.name}</p>
										</div>
									</label>
								))}
						</div>
					)}
					{state.valueClassNum && state.valueClassNum.length >= 1 && (
						// display selected classes
						<div className="bg-green-200 p-2 rounded-md">
							<span className="font-semibold">Classes Selected: </span>
							<span className="">
								{state.valueClassNum
									.map(
										(item) =>
											classNumList.find((classItem) => classItem.value === item)
												.label
									)
									.join(", ")}
							</span>
						</div>
					)}
				</div>
				{/* <ClassDropDown
						listValues={classNumList}
						onChange={handleGPScorChange}
						multiple={multiple}
						inputlabel={`Click here to select your class${
							multiple ? "es" : ""
						} (REQUIRED):  `}
						onChangeSwitch={handleChangeClassDropdown}
						helperText={classNumHelpText}
						error={errorState.classNum}
						value={state.valueClassNum}
						name="valueClassNum"
						className="w-full col-span-2 "
					/> */}
				<div className="flex flex-col gap-2">
					<label
						htmlFor="notes"
						className="font-medium text-primary"
					>
						Additional Comments:
					</label>
					<TextField
						inputProps={inputProps}
						multiline
						value={state.notes}
						error={notesError}
						helperText={notesHelpText}
						onChange={handleChange}
						name="notes"
						placeholder={notesText}
						className="w-full"
					/>
				</div>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSubmit}
					className="w-full col-span-2"
				>
					Submit
				</Button>
			</div>
		</div>
	);
}
