import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Footer from "./components/Footer";

export default function ReqSuccess() {
	const navigate = useNavigate();

	useEffect(() => {
		const timer = setTimeout(() => {
			navigate("/newordisplay");
		}, 7000);
		return () => clearTimeout(timer);
	}, [navigate]);

	return (
		<div className="min-h-screen flex flex-col bg-gray-50">
			{/* Main content */}
			<main className="flex-grow flex items-center justify-center">
				<div className="w-full max-w-md bg-white rounded-2xl border-2 border-green-800 p-8 flex flex-col items-center gap-4">
					<p className="text-center text-lg font-semibold">
						Success! Your request has been submitted. A receipt of your request
						has been automatically downloaded.
					</p>
					<p className="text-center text-lg font-semibold">
						If you have not already, please drop off your exams at GSB 2-40.
						Please bring your receipt with you.
					</p>
					<p className="text-center text-lg font-semibold">Redirecting...</p>
					<CircularProgress className="mt-4" />
				</div>
			</main>

			{/* Footer */}
			<Footer />
		</div>
	);
}
