import { jsPDF } from "jspdf";
import { img2 } from "../../../Requisition/components/images";
import dayjs from "dayjs";

export default function PdfGeneration(classesList, departmentInfo, webOrNot, userid) {
	const deptName = departmentInfo.deptName;
	const term = departmentInfo.term;

	// Draws the common document header on the first page.
	function drawDocHeader(doc) {
		doc.addImage(img2, "PNG", 170, 15, 25, 25);
		doc.setDrawColor("#275d38");
		doc.setFontSize(12);
		doc.setFont(undefined, "normal");
		doc.rect(10, 10, 190, 270);
		doc.text("Department:", 12, 16);
		doc.text("Term:", 12, 24);
		doc.text("Receipt Date:", 12, 32);
		doc.text("User:", 12, 40);
		doc.setFont(undefined, "bold");
		doc.text(" " + deptName, 47, 16);
		doc.text(" " + term, 47, 24);
		doc.text(" " + dayjs().format("MM/DD/YYYY"), 47, 32);
		doc.text(" " + userid, 47, 40);
		doc.setFontSize(14);
		if (webOrNot === "web") {
			doc.setTextColor("#275d38");
			doc.text("Displaying Ordered and Excluded Classes", 12, 54);
			doc.setTextColor("#000000");
		} else {
			doc.setTextColor("#d32f2f");
			doc.text("Displaying Classes Not Ordered", 12, 54);
			doc.setTextColor("#000000");
		}
		doc.setFont(undefined, "normal");
		doc.setFontSize(12);
	}

	// Draws the table header (only on the first page)
	function drawTableHeader(doc) {
		doc.setDrawColor("#000000");
		doc.rect(12, 60, 186, 8);
		doc.setFontSize(10);
		doc.setFont(undefined, "bold");
		doc.text("Class ID", 16, 65);
		doc.rect(40, 60, 60, 8);
		doc.text("Course Title", 58, 65);
		doc.rect(100, 60, 25, 8);
		doc.text("Enrolled", 105, 65);
		doc.rect(125, 60, 30, 8);
		doc.text("Team Size", 131, 65);
		doc.rect(155, 60, 43, 8);
		doc.text("Instructor", 165, 65);
		doc.setFont(undefined, "normal");
		doc.setFontSize(10);
	}

	// Draws a single row for a class at the given y coordinate.
	function drawRow(doc, cls, y) {
		// Check if the row should be marked as excluded.
		const isExcluded = cls.evaluate === "No";
		const isComponent = cls.component;

		// Set text color based on evaluation.
		doc.setTextColor(isExcluded ? (isComponent ? "gray" : "red") : "black");

		// Prepare instructor names.
		let instructorFirstName = "";
		let instructorLastName = "";
		if (cls.name && cls.name !== "") {
			const names = cls.name.split(" ");
			instructorFirstName = names[0];
			instructorLastName = names[1] || "";
		}

		// Draw the Class ID column.
		doc.rect(12, y, 28, 8);
		doc.text(String(cls.classid), 18, y + 5);

		// Draw the Course Title column.
		doc.rect(40, y, 60, 8);
		doc.text(String(cls.ctitle.substring(0, 25)), 42, y + 5);

		// Draw the Enrolled column.
		doc.rect(100, y, 25, 8);
		let enrolledX = cls.csize < 10 ? 111 : cls.csize < 100 ? 110 : 109;
		doc.text(String(cls.csize), enrolledX, y + 6);

		// Draw the Team Size column.
		doc.rect(125, y, 30, 8);
		doc.text(String(cls.teamsize), 139, y + 6);

		// Draw the Instructor column.
		doc.rect(155, y, 43, 8);
		doc.setFontSize(9);
		if (isExcluded && !isComponent) {
			// For excluded rows, simply print "EXCLUDED".
			doc.text("EXCLUDED", 160, y + 5);
		} else if (isExcluded && isComponent) {
			// For excluded rows, simply print "EXCLUDED".
			const text = "COMBINED: " + String(cls.parent);
			doc.text(text, 160, y + 5);
		} else {
			// Otherwise, print the instructor name based on team size.
			if (cls.teamsize > 1) {
				doc.text("(Several)", 160, y + 5);
			} else {
				const combinedName = instructorFirstName + " " + instructorLastName;
				if (combinedName.length > 22) {
					doc.text(String(instructorLastName), 160, y + 3);
					doc.text(String(instructorFirstName), 160, y + 7);
				} else {
					doc.text(String(combinedName), 160, y + 5);
				}
			}
		}
		doc.setFontSize(10);

		// Reset text color back to default for subsequent operations.
		doc.setTextColor("black");
	}

	// Draws all the rows for one page. The starting y-coordinate differs for the first page vs. subsequent pages.
	function drawPageRows(doc, rows, isFirstPage) {
		const startY = isFirstPage ? 68 : 12;
		rows.forEach((cls, index) => {
			const y = startY + index * 8;
			drawRow(doc, cls, y);
		});
	}

	// Main function to generate the PDF.
	function generatePDF() {
		const doc = new jsPDF();

		// Draw header and table header on the first page.
		drawDocHeader(doc);
		drawTableHeader(doc);

		// Define how many rows fit on the first page and on subsequent pages.
		const firstPageCount = 26;
		const subsequentPageCount = 33;
		const pages = [];

		if (classesList.length <= firstPageCount) {
			pages.push(classesList);
		} else {
			pages.push(classesList.slice(0, firstPageCount));
			for (let i = firstPageCount; i < classesList.length; i += subsequentPageCount) {
				pages.push(classesList.slice(i, i + subsequentPageCount));
			}
		}

		// Draw rows for the first page.
		drawPageRows(doc, pages[0], true);

		// For every additional page, add a new page, draw a border, and render its rows.
		for (let i = 1; i < pages.length; i++) {
			doc.addPage();
			doc.setDrawColor("#275d38");
			doc.rect(10, 10, 190, 270);
			doc.setDrawColor("#000000");
			drawPageRows(doc, pages[i], false);
		}

		const fileName = deptName + (webOrNot === "web" ? "_in_surveys.pdf" : "_not_in_surveys.pdf");
		doc.save(fileName);
	}

	generatePDF();
}
