// utils/helpers.js

export const addLeadingZeros = (number, length) => {
	return String(number).padStart(length, "0");
};

export const formatDate = () => {
	const today = new Date();
	const dd = String(today.getDate()).padStart(2, "0");
	const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
	const yyyy = today.getFullYear();
	return `${yyyy}-${mm}-${dd}`;
};

export const getInitials = (name) => {
	const nameObj = name
		.split(" ")
		.map((word) => word[0])
		.join("")
		.toUpperCase();
	if (nameObj.length > 2) {
		return nameObj.substring(0, 2);
	}
	return nameObj;
};

export const getInitials2 = (name) => {
	var initials = name.match(/\b\w/g) || [];
	initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
	return initials;
};
export const setDate = () => {
	var today = new Date();
	var dd = String(today.getDate()).padStart(2, "0");
	var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
	var yyyy = today.getFullYear();
	today = yyyy + "-" + mm + "-" + dd;
	return today;
};

export const subStringCourseTitle = (courseTitle) => {
	let courseTitleString = "";
	if (courseTitle) {
		courseTitleString = courseTitle.substring(0, 16);
	} else {
		courseTitleString = "None";
	}
	return courseTitleString;
};
