import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";

export default function BlockIdSelect(props) {
	const [anySmallCourse, setAnySmallCourse] = useState(false);
	/* useEffect(() => {
		if (props.selectedClass) { */
	useEffect(() => {
		if (props.rows && props.customCheckboxSelection) {
			const selectedCourses = props.rows.filter((row) =>
				props.customCheckboxSelection.includes(row.classid)
			);
			const smallCourse = selectedCourses.some((course) => course.csize < 10);
			setAnySmallCourse(smallCourse);
		}
	}, [props.customCheckboxSelection, props.rows]);

	return (
		<div>
			<TextField
				select
				required={props.required}
				size="small"
				disabled={props.disabled}
				error={props.error}
				value={props.value || ""}
				name={props.name}
				label={props.label}
				multiple={props.multiple}
				onChange={props.onChange}
				onClick={props.onClick}
				margin="none"
				variant={"outlined"}
				sx={{
					width: 140,
					marginBottom: props.marginBottom ? props.marginBottom : "20px",
					marginTop: props.marginTop ? props.marginTop : "0px",
					fontSize: "0.75rem",
					backgroundColor: "#fff",
					".MuiInputBase-input": { fontSize: "10pt", letterSpacing: -1 },
				}}
				SelectProps={{
					multiple: props.multiple,
					value: props.value || "",
					onChange: props.onChange,
				}}
			>
				{props.blockIds &&
					Object.keys(props.blockIds).map((option) => (
						<MenuItem
							key={option}
							value={option}
							disabled={
								props.selectedClass && props.selectedClass.dept === 79
									? false
									: (props.selectedClass &&
											props.selectedClass.csize < 10 &&
											props.blockIds[option].forSmallCourse === false) ||
									  (props.selectedClass &&
											props.selectedClass.csize >= 10 &&
											props.blockIds[option].forSmallCourse === true) ||
									  (props.inBatch &&
											anySmallCourse &&
											props.blockIds[option].forSmallCourse === false) ||
									  (props.inBatch &&
											!anySmallCourse &&
											props.blockIds[option].forSmallCourse === true)
							}
						>
							{option}
						</MenuItem>
					))}
			</TextField>
		</div>
	);
}
