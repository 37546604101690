import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { updateDepartmentOptStatus } from "../../../components/Api/Updates";

export default function ConfirmOptStatusDialog(props) {
	const handleClose = () => {
		props.setOpen(false);
	};

	const handleToggleOptStatus = async () => {
		await updateDepartmentOptStatus(props.token, Number(props.deptId), props.deptOptStatus);
		props.refetchDepartments();
		props.setOpen(false);
	};
	return (
		<div>
			<Dialog open={props.open} maxWidth="2xl">
				<DialogTitle textAlign={"center"}>Confirm Department Intent</DialogTitle>

				<div className="flex flex-col gap-5 max-w-3xl p-4 ">
					<p>
						We have been working with the registrar's office to automate the process of confirming
						grade finalization statuses for departments. Starting this term, our class data will
						regularly bring in the most recent grade finalization statuses for all classes, and we
						will use this data to automatically release SPOT survey results to instructors. Reports
						will only be released to instructors if a class has a 100% grade finalization status.
					</p>
					<p>
						By default, all departments have been "opted in" to the new automated method of
						confirming grade finalization statuses. By clicking{" "}
						{props.deptOptStatus === "MANUAL" ? (
							<span>
								<span className="text-green-800 font-semibold">"OPT IN"</span>, you are choosing to
								use the new automated process and instructor results will be automatically sent out
								based on the data that comes from BearTracks/Campus Solutions.
							</span>
						) : (
							<span>
								<span className="text-red-600 font-semibold">"OPT OUT"</span>, you are choosing to
								forego the automated process and take the responsibility of ensuring timely
								confirmations of grade finalization statuses for your department.
							</span>
						)}
					</p>

					<p sx={{ maxWidth: "600px", color: "black" }}>
						This is a decision that will affect your entire department, so please ensure that you
						are on the same page as other staff members in your department before proceeding.
					</p>
				</div>

				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					{props.deptOptStatus === "MANUAL" ? (
						<Button onClick={handleToggleOptStatus} autoFocus variant="contained" color="primary">
							OPT IN
						</Button>
					) : (
						<Button onClick={handleToggleOptStatus} autoFocus variant="contained" color="error">
							OPT OUT
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
}
