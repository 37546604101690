import Footer from "./components/Footer.js";

import React from "react";

export default function PostSubmission() {
	return (
		<div className="flex flex-col items-center justify-center min-h-[80dvh] bg-gray-100">
			<main className="flex w-full flex-col items-center justify-center min-h-[75dvh]">
				<div className=" flex flex-col justify-center items-center bg-white shadow-md rounded-lg border-2 p-6">
					<div className="flex gap-4 items-center">
						<a
							href="request-for-service"
							className="flex items-center justify-center bg-[#275d38] hover:bg-[#163520] hover:text-gray-200 text-white rounded w-[100px] h-[100px] md:w-[125px] md:h-[125px] lg:w-[150px] lg:h-[150px] xl:w-[200px] xl:h-[200px]"
						>
							<span className="text-center font-semibold text-xl">CREATE NEW REQUEST</span>
						</a>
						<p className="font-semibold">OR</p>
						<a
							href="display"
							className="flex items-center justify-center bg-[#275d38] hover:bg-[#163520] hover:text-gray-200 text-white rounded w-[100px] h-[100px] md:w-[125px] md:h-[125px] lg:w-[150px] lg:h-[150px] xl:w-[200px] xl:h-[200px]"
						>
							<span className="text-center font-semibold text-xl">DISPLAY EXISTING REQUESTS</span>
						</a>
					</div>
				</div>
			</main>
			<Footer />
		</div>
	);
}
