import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";

export default function AutoCompleteComponent(props) {
	//isOptionEqualToValue={(option, value) => option.department === value.department}

	const optionsHaventLoaded = [
		{
			department: "Loading...",
		},
		{ department: "" },
	];
	const [autoCompleteValue, setAutoCompleteValue] = useState(null);

	useEffect(() => {
		if (props.value) {
			const realValue = props.options.find(
				(option) => option.value === props.value
			);
			if (realValue) {
				setAutoCompleteValue(realValue);
			}
		}
	}, [props.value, props.options]);

	if (props.options.length < 1 && props.value) {
		return (
			<FormControl>
				<Autocomplete
					id={props.id}
					options={optionsHaventLoaded}
					isOptionEqualToValue={(option, value) =>
						option.department === value.department
					}
					autoHighlight
					disabled
					key={props.key}
					value={props.value}
					name={props.name}
					onChange={props.onChange}
					renderInput={(params) => (
						<TextField
							{...params}
							label={props.label}
							name={props.name}
							key={props.key}
							helperText={props.helperText}
							error={props.error}
							id={props.id}
						/>
					)}
				/>
			</FormControl>
		);
	} else {
		return (
			<Autocomplete
				id={props.id}
				options={props.options}
				isOptionEqualToValue={(option, value) => option.value === value.value}
				autoHighlight
				key={props.key}
				value={autoCompleteValue ? autoCompleteValue : null}
				name={props.name}
				className="col-span-2 pt-2 "
				onChange={props.onChange}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						label={props.label}
						sx={{ label: { color: "#275d38" } }}
						required
						name={props.name}
						key={props.key}
						/* helperText={props.helperText} */
						error={props.error}
						id={props.id}
					/>
				)}
			/>
		);
	}
}
