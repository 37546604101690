// DepartmentSummary.jsx
import React, { useContext, useEffect, useState } from "react";
import { TokenContext } from "../../../context/TokenContext";
import ListSelect from "../../../components/Utils/ListSelect";
import AutocompleteComponent from "../../../components/Utils/AutocompleteComponent";
import { Typography, Button, Box } from "@mui/material";
import ClassPDF from "./components/ClassPDF";
import { useDepartmentsQuery } from "../../../Hooks/useDepartmentsQuery";
import { useTermsQuery } from "../../../Hooks/useTermsQuery";
import {
	useClassIdObjectsByDeptAndTerm,
	useAdjustedClassIdObjectsByDeptAndTerm,
} from "../../../Hooks/useClassIdsQuery";
import isAuthenticated from "../../../components/Utils/isAuthenticated";

export default function DepartmentSummary() {
	const auth = isAuthenticated();
	const userid = auth.ccid;

	const token = useContext(TokenContext);
	const [termValue, setTermValue] = useState("");
	const [autocompleteValue, setAutocompleteValue] = useState("");
	const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
	const [webClasses, setWebClasses] = useState([]);
	const [nonWebClasses, setNonWebClasses] = useState([]);
	const [departmentInfo, setDepartmentInfo] = useState({});
	const [blueCoursesArray, setBlueCoursesArray] = useState([]);
	const [toggleEmptyClass, setToggleEmptyClass] = useState(false);
	const [noEnrollmentNonWebClasses, setNoEnrollmentNonWebClasses] = useState([]);

	const { data: departments } = useDepartmentsQuery(token);
	const { data: terms } = useTermsQuery(token);

	useEffect(() => {
		if (nonWebClasses && nonWebClasses.length > 0) {
			const noEnrollmentClasses = nonWebClasses.filter((cls) => cls.csize > 0);
			setNoEnrollmentNonWebClasses(noEnrollmentClasses);
		}
	}, [nonWebClasses]);

	useEffect(() => {
		if (auth.dept_ids && departments) {
			let deptId;
			try {
				if (auth.dept_ids.includes(",")) {
					// Split and take the first department ID
					deptId = auth.dept_ids.split(",")[0].trim();
				} else {
					deptId = auth.dept_ids;
				}
			} catch (error) {
				console.error("Error parsing department ids", error);
			}

			// Use the parsed deptId for the lookup
			setAutocompleteValue(departments.find((dept) => dept.key === Number(deptId)) || null);
		}
	}, [auth.dept_ids, departments]);

	useEffect(() => {
		if (terms) {
			const maxTerm = terms[0];

			setTermValue(maxTerm);
		}
	}, [terms]);

	const { data: fetchedClassIds, isFetching: classIdsLoading } = useClassIdObjectsByDeptAndTerm(
		token,
		autocompleteValue?.key,
		termValue,
		{
			enabled: !!token && !!autocompleteValue?.key && !!termValue,
			staleTime: 1000 * 5,
			refetchOnWindowFocus: false,
		}
	);

	const { data: fetchedAdjustedClassIds, isFetching: fetchedAdjustedClassIdsLoading } =
		useAdjustedClassIdObjectsByDeptAndTerm(token, autocompleteValue?.key, termValue, {
			enabled: !!token && !!autocompleteValue?.key && !!termValue,
			refetchOnWindowFocus: false,
		});

	useEffect(() => {
		if (fetchedAdjustedClassIds) {
			const blueCourses = fetchedAdjustedClassIds.map((classid) => classid.classid);
			setBlueCoursesArray(blueCourses);
		}
	}, [fetchedAdjustedClassIds]);

	useEffect(() => {
		if (autocompleteValue && autocompleteValue !== "" && termValue && termValue !== "") {
			const deptInfo = {
				deptId: autocompleteValue.key,
				deptName: autocompleteValue.display_name,
				term: termValue,
			};
			setDepartmentInfo(deptInfo);
		}
	}, [autocompleteValue, termValue]);

	useEffect(() => {
		//fetched classids will always have data, but fetchedAdjustedClassIds will only have data if there are adjusted classids.
		//populate a nested dict with the main parent key as the classid, and then original and adjusted as the subkeys
		if (
			!fetchedAdjustedClassIds ||
			(fetchedAdjustedClassIds && fetchedAdjustedClassIds.length === 0)
		) {
			setWebClasses([]);
			if (fetchedClassIds && fetchedClassIds.length > 0) {
				setNonWebClasses(fetchedClassIds);
			} else {
				setNonWebClasses([]);
			}
		} else if (
			fetchedAdjustedClassIds &&
			fetchedAdjustedClassIds.length > 0 &&
			fetchedClassIds &&
			fetchedClassIds.length > 0
		) {
			const webClasses = [];
			const nonWebClasses = [];
			const combinedList = {}; // use an object for mapping course IDs to their combine IDs

			fetchedClassIds.forEach((course) => {
				const courseId = course.classid;
				const adjustedCourse = fetchedAdjustedClassIds.find((cls) => cls.classid === courseId);
				if (adjustedCourse) {
					// use the adjusted course
					course = adjustedCourse;

					// if the course has a non-empty 'combine' field, split it into an array
					if (course.combine && course.combine.length > 0) {
						combinedList[course.classid] = course.combine.split(",").map((id) => id.trim()); // trim any extra spaces
					}

					webClasses.push(course);
				} else {
					nonWebClasses.push(course);
				}
			});

			// Now iterate over the mapping to mark courses that are components.
			Object.entries(combinedList).forEach(([parentId, combineIds]) => {
				combineIds.forEach((combineId) => {
					webClasses.forEach((cls) => {
						if (Number(cls.classid) === Number(combineId)) {
							cls.component = true;
							cls.parent = parentId;
						}
					});
				});
			});

			const sortedWebClasses = webClasses.sort(sortByCtype);

			// Separate parents (non-components) from components.
			const parentClasses = sortedWebClasses.filter((cls) => !cls.component);
			const componentClasses = sortedWebClasses.filter((cls) => cls.component);

			// Build a new list where each parent is immediately followed by its components.
			const groupedWebClasses = [];
			parentClasses.forEach((parent) => {
				groupedWebClasses.push(parent);
				// Find and sort children that belong to this parent.
				const children = componentClasses
					.filter((child) => Number(child.parent) === Number(parent.classid))
					.sort(sortByCtype);
				groupedWebClasses.push(...children);
			});

			// Optionally, if there are any components not linked to a parent in this list, add them at the end.
			const groupedComponentIds = new Set(
				groupedWebClasses.filter((cls) => cls.component).map((cls) => cls.classid)
			);
			componentClasses.forEach((child) => {
				if (!groupedComponentIds.has(child.classid)) {
					groupedWebClasses.push(child);
				}
			});

			setWebClasses(groupedWebClasses);

			const sortedNonWebClasses = nonWebClasses.sort(sortByCtype);
			setNonWebClasses(sortedNonWebClasses);
		}
	}, [fetchedClassIds, fetchedAdjustedClassIds]);

	const handleChangeListSelect = (event) => {
		setTermValue(event.target.value);
	};

	const handlePDFInReq = () => {
		ClassPDF(webClasses, departmentInfo, "web", userid);
	};

	const handlePDFNotInReq = () => {
		ClassPDF(nonWebClasses, departmentInfo, "not", userid);
	};

	const sortByCtype = (a, b) => {
		const hierarchy = ["LEC", "LAB", "SEM", "IND", "PRA", "RSC", "LBL", "CSA", "THE", "LCL"];
		const aIndex = hierarchy.indexOf(a.ctype.split("/")[0]);
		const bIndex = hierarchy.indexOf(b.ctype.split("/")[0]);

		if (aIndex !== bIndex) {
			return aIndex - bIndex;
		}

		// Compare SUBJECT_ID (assuming they are strings)
		const subjectComparison = a.subject_id.localeCompare(b.subject_id);
		if (subjectComparison !== 0) {
			return subjectComparison;
		}

		// Compare cnumber numerically
		const aCNumber = Number(a.cnumber);
		const bCNumber = Number(b.cnumber);
		if (aCNumber !== bCNumber) {
			return aCNumber - bCNumber;
		}

		// Finally, compare section (assuming it's a string)
		return a.section.localeCompare(b.section);
	};
	return (
		<Box className="min-h-screen flex flex-col items-center mt-12 w-full p-4">
			<Typography variant="h6" className="text-center mt-8 mb-8 w-4/5">
				Department Class Summary
			</Typography>
			<Box className="flex flex-row gap-4 items-center mb-8">
				<AutocompleteComponent
					label="Department"
					options={departments ? departments : []}
					variant="standard"
					marginTop="0px"
					marginBottom="18px"
					value={autocompleteValue}
					setValue={setAutocompleteValue}
					inputValue={autocompleteInputValue}
					setInputValue={setAutocompleteInputValue}
				/>
				<ListSelect
					label="Term"
					list={terms}
					variant="standard"
					marginTop="0px"
					width="300px"
					onChange={handleChangeListSelect}
					value={termValue ? termValue : ""}
				/>
			</Box>
			<Box className="flex flex-row gap-4 w-full max-w-lg"></Box>

			{/* NEW: In-page graphical view */}
			{departmentInfo.deptName && termValue && (
				<div className="w-full mt-12 px-4">
					<div className="border border-gray-300 rounded p-6 shadow flex flex-col gap-6">
						<h2 className="text-2xl font-bold text-center mb-6">
							{departmentInfo.deptName} — {termValue}
						</h2>
						<div className="">
							<h3 className="text-xl font-semibold text-green-700 mb-2">Classes Ordered</h3>
							<Button
								variant="contained"
								onClick={handlePDFInReq}
								sx={{ marginY: "10px" }}
								disabled={
									!autocompleteValue ||
									!termValue ||
									classIdsLoading ||
									fetchedAdjustedClassIdsLoading
								}
							>
								Print Receipt
							</Button>
							{webClasses.length > 0 && <ClassTableView classes={webClasses} inOrOut={"in"} />}
						</div>

						<div>
							<h3 className="text-xl font-semibold text-orange-600 ">Classes Not Ordered</h3>
							<Button
								variant="contained"
								onClick={handlePDFNotInReq}
								sx={{ marginY: "10px", marginRight: "10px" }}
								disabled={
									!autocompleteValue ||
									!termValue ||
									classIdsLoading ||
									fetchedAdjustedClassIdsLoading
								}
							>
								Print Receipt
							</Button>
							<Button
								variant="standard"
								color="primary"
								onClick={() => setToggleEmptyClass(!toggleEmptyClass)}
								sx={{ marginY: "10px" }}
								disabled={
									!autocompleteValue ||
									!termValue ||
									classIdsLoading ||
									fetchedAdjustedClassIdsLoading
								}
							>
								{toggleEmptyClass ? "Show All Classes" : "Hide Classes With No Enrollment"}
							</Button>

							{nonWebClasses.length > 0 && (
								<ClassTableView
									classes={toggleEmptyClass ? noEnrollmentNonWebClasses : nonWebClasses}
									inOrOut={"out"}
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</Box>
	);
}

// A new component to display the table view
function ClassTableView({ classes, inOrOut }) {
	return (
		<div className="overflow-x-auto ">
			<table className="min-w-full divide-y divide-gray-200 border">
				<thead className="bg-gray-50">
					<tr>
						<th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Class ID</th>
						<th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Course Title</th>
						<th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Enrolled</th>
						<th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Team Size</th>
						<th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Instructor</th>
						{inOrOut === "in" && (
							<>
								<th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Block ID</th>
								<th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
									Survey Start
								</th>
								<th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
									Survey End
								</th>
							</>
						)}
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					{classes && classes.length > 0 ? (
						classes.map((cls, index) => {
							// Compute the instructor display value similar to the PDF.
							if (cls.evaluate === "No" && !cls.component) {
								return (
									<tr key={cls.classid || index}>
										<td className="px-4 py-2 text-sm text-red-600">{cls.classid}</td>
										<td className="px-4 py-2 text-sm text-red-600">{cls.ctitle}</td>
										<td className="px-4 py-2 text-sm text-red-600">{cls.csize}</td>
										<td className="px-4 py-2 text-sm text-red-600">{cls.teamsize}</td>
										<td className="px-4 py-2 text-sm text-red-600">EXCLUDED</td>
										<td className="px-4 py-2 text-sm text-red-600">EXCLUDED</td>
										<td className="px-4 py-2 text-sm text-red-600">EXCLUDED</td>
										<td className="px-4 py-2 text-sm text-red-600">EXCLUDED</td>
									</tr>
								);
							} else if (cls.evaluate === "No" && cls.component) {
								return (
									<tr key={cls.classid || index}>
										<td className="px-4 text-sm text-gray-400">{cls.classid}</td>
										<td className="px-4  text-sm text-gray-400">{cls.ctitle}</td>
										<td className="px-4  text-sm text-gray-400">{cls.csize}</td>
										<td className="px-4 text-sm text-gray-400">{cls.teamsize}</td>
										<td className="px-4  text-sm text-gray-400">COMB: {cls.parent}</td>
										<td className="px-4  text-sm text-gray-400">COMB: {cls.parent}</td>
										<td className="px-4  text-sm text-gray-400">COMB: {cls.parent}</td>
										<td className="px-4  text-sm text-gray-400">COMB: {cls.parent}</td>
									</tr>
								);
							} else if (cls.roles?.length >= 1) {
								return (
									<tr key={cls.classid || index}>
										<td className="px-4 py-2 text-sm text-gray-700">{cls.classid}</td>
										<td className="px-4 py-2 text-sm text-gray-700">{cls.ctitle}</td>
										<td className="px-4 py-2 text-sm text-gray-700">{cls.csize}</td>
										<td className="px-4 py-2 text-sm text-gray-700">{cls.teamsize}</td>
										<td className="flex flex-col">
											{cls.roles.map((role, index) => {
												return (
													<div
														key={role.id + "_" + role.empid || index}
														className="px-4 py-2 text-sm text-gray-700"
													>
														{role.name
															? role.name
															: role.user.last_name && role.user.first_name
															? role.user.last_name + ", " + role.user.first_name
															: ""}
													</div>
												);
											})}
										</td>
										{cls.form && <td className="px-4 py-2 text-sm text-gray-700">{cls.form}</td>}
										{cls.survey_start && (
											<td className="px-4 py-2 text-sm text-gray-700">{cls.survey_start}</td>
										)}
										{cls.form && (
											<td className="px-4 py-2 text-sm text-gray-700">{cls.survey_end}</td>
										)}
									</tr>
								);
							} else {
								let instructorName = "";
								if (cls.name && cls.name.trim() !== "") {
									instructorName = cls.name;
								}
								return (
									<tr key={cls.classid || index}>
										<td className="px-4 py-2 text-sm text-gray-700">{cls.classid}</td>
										<td className="px-4 py-2 text-sm text-gray-700">{cls.ctitle}</td>
										<td className="px-4 py-2 text-sm text-gray-700">{cls.csize}</td>
										<td className="px-4 py-2 text-sm text-gray-700">{cls.teamsize}</td>
										<td className="px-4 py-2 text-sm text-gray-700">{instructorName}</td>
									</tr>
								);
							}
						})
					) : (
						<tr>
							<td colSpan="5" className="px-4 py-2 text-center text-gray-500">
								No classes to display.
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}
