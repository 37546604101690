import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { Badge } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import { useNavigate } from "react-router-dom";
import isAuthenticated from "../../Utils/isAuthenticated";

export default function NotificationMenu(props) {
	const auth = isAuthenticated();
	const access = auth.access;
	const adminUser = access.includes("ADMIN");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [
		resultsEmailNotificationsCurrentTerm,
		setResultsEmailNotificationsCurrentTerm,
	] = useState([]);
	const [
		resultsEmailNotificationsPreviousTerm,
		setResultsEmailNotificationsPreviousTerm,
	] = useState([]);
	const [currentTermResultsCount, setCurrentTermResultsCount] = useState(0);
	const [previousTermResultsCount, setPreviousTermResultsCount] = useState(0);
	const [openCurrentTermResults, setOpenCurrentTermResults] = useState(false);
	const [openPreviousTermResults, setOpenPreviousTermResults] = useState(false);
	const navigate = useNavigate();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (event) => {
		if (event.target.innerText === "Logout") {
			props.Logout();
		}
		setAnchorEl(null);
	};

	useEffect(() => {
		if (props.resultsEmailNotificationData) {
			const nowTermResultsCount =
				props.resultsEmailNotificationData.departments_ready_current_term
					?.length;

			const lastTermResultsCount =
				props.resultsEmailNotificationData.departments_ready_previous_term
					?.length;

			setCurrentTermResultsCount(nowTermResultsCount);

			setPreviousTermResultsCount(lastTermResultsCount);

			setResultsEmailNotificationsPreviousTerm(
				props.resultsEmailNotificationData.departments_ready_previous_term
			);

			setResultsEmailNotificationsCurrentTerm(
				props.resultsEmailNotificationData.departments_ready_current_term
			);
		}
	}, [props.resultsEmailNotificationData]);

	const handleOpenCurrentTermResults = (event) => {
		event.stopPropagation();
		setOpenCurrentTermResults(!openCurrentTermResults);
	};

	const handleOpenPreviousTermResults = (event) => {
		event.stopPropagation();
		setOpenPreviousTermResults(!openPreviousTermResults);
	};

	const handleRouteAdminUtils = () => {
		navigate("/AdminUtils");
		setOpenCurrentTermResults(false);
	};

	if (adminUser) {
		return (
			<React.Fragment>
				<Box
					sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
				>
					<Tooltip title="Notifications">
						<IconButton
							onClick={handleClick}
							size="small"
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								height: "50%",
								marginRight: ".5rem",
							}}
							aria-controls={open ? "account-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={open ? "true" : undefined}
						>
							<Badge
								badgeContent={previousTermResultsCount}
								color="warning"
								anchorOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								className="pulse"
							>
								<Badge
									badgeContent={currentTermResultsCount}
									color="error"
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
								>
									<NotificationsIcon
										fontSize="large"
										color="primary"
									/>
								</Badge>
							</Badge>
						</IconButton>
					</Tooltip>
				</Box>
				<Menu
					anchorEl={anchorEl}
					id="notification-menu"
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					slotProps={{
						paper: {
							elevation: 0,
							sx: {
								overflow: "visible",
								filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
								mt: 1.5,
								"& .MuiAvatar-root": {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								"&:before": {
									content: '""',
									display: "block",
									position: "absolute",
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: "background.paper",
									transform: "translateY(-50%) rotate(45deg)",
									zIndex: 0,
								},
							},
						},
					}}
					transformOrigin={{ horizontal: "right", vertical: "top" }}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				>
					{currentTermResultsCount > 0 ? (
						<MenuItem onClick={handleOpenCurrentTermResults}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignContent: "center",
									alignItems: "center",
								}}
							>
								<Typography color="error">
									{currentTermResultsCount > 1
										? `${currentTermResultsCount} Departments From Current Term Ready`
										: `${currentTermResultsCount} Department From Current Term Ready`}
								</Typography>
								<ListItemIcon sx={{}}>
									{openCurrentTermResults ? (
										<ArrowDropDownIcon />
									) : (
										<ArrowDropUpIcon />
									)}
								</ListItemIcon>
							</Box>
						</MenuItem>
					) : null}
					{openCurrentTermResults ? (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								maxHeight: "200px",
								overflow: "auto",
							}}
						>
							<Divider />
							{resultsEmailNotificationsCurrentTerm.map((resultsEmails) => {
								return (
									<Typography
										sx={{ p: 1, marginLeft: "20px" }}
										key={resultsEmails.dept_id}
									>
										{resultsEmails.dept_id__display_name} -{" "}
										{resultsEmails.unique_empid_count} Unique Instructor(s)
									</Typography>
								);
							})}
						</Box>
					) : null}
					{previousTermResultsCount > 0 ? (
						<MenuItem onClick={handleOpenPreviousTermResults}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignContent: "center",
									alignItems: "center",
								}}
							>
								<Typography color="orange">
									{previousTermResultsCount > 1
										? `${previousTermResultsCount} Departments From Last Term Ready`
										: `${previousTermResultsCount} Department From Last Term Ready`}
								</Typography>
								<ListItemIcon sx={{}}>
									{openPreviousTermResults ? (
										<ArrowDropDownIcon />
									) : (
										<ArrowDropUpIcon />
									)}
								</ListItemIcon>
							</Box>
						</MenuItem>
					) : null}
					{openPreviousTermResults ? (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								maxHeight: "250px",
								overflow: "auto",
							}}
						>
							<Divider />
							{resultsEmailNotificationsPreviousTerm.map((resultsEmails) => {
								return (
									<Typography
										sx={{ p: 1, marginLeft: "20px" }}
										key={resultsEmails.dept_id}
									>
										{resultsEmails.dept_id__display_name} -{" "}
										{resultsEmails.unique_empid_count} Unique Instructor(s)
									</Typography>
								);
							})}
						</Box>
					) : null}
					<MenuItem
						sx={{ color: "primary" }}
						onClick={handleRouteAdminUtils}
					>
						<ListItemIcon>
							<CallMissedOutgoingIcon />
						</ListItemIcon>
						Go to Admin Utils
					</MenuItem>
				</Menu>
			</React.Fragment>
		);
	}
}
