// utils/api.js
import axios from "axios";

export const fetchDepartments = async (token) => {
	const url = `${process.env.REACT_APP_BACKEND_HOST}/scanner/departments/`;
	const response = await axios.get(url, {
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: `Token ${token}`,
		},
	});
	return response.data;
};

export const fetchClasses = async (token, deptID) => {
	const url = `${process.env.REACT_APP_BACKEND_HOST}/scanner/department_classes/${deptID}/`;
	const response = await axios.get(url, {
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: `Token ${token}`,
		},
	});
	return response.data;
};

export const createRequisition = async (token, data) => {
	const url = `${process.env.REACT_APP_BACKEND_HOST}/scanner/new_scanner_requisition/`;
	const response = await axios.post(url, data, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		},
	});
	return response.data;
};

export const processRequisition = async (token, reqnum, processDetails) => {
	const url = `${process.env.REACT_APP_BACKEND_HOST}/scanner/processes/${reqnum}/`;
	await axios.post(url, processDetails, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		},
	});
};

export const getReqNum = () => {
	return new Promise((resolve, reject) => {
		axios({
			headers: {
				Authorization: `Token ${sessionStorage.getItem("token")}`,
			},
			method: "get",
			url: process.env.REACT_APP_BACKEND_HOST.concat("/scanner/fetch_reqnum/"),
		})
			.catch(function (error) {
				if (error.response) {
					console.log("data", error.response.data);
					console.log("status", error.response.status);
					console.log("headers", error.response.headers);
				} else if (error.request) {
					console.log("request", error.request);
				} else {
					console.log("Error", error.message);
				}
				console.log(error.config);
				console.log("json", error.toJSON());
			})
			.then((res) => {
				if (res.data.length > 0) {
					resolve(res.data[0]);
				}
			});
	});
};
