import styles from "../styles/Banner.module.css";
import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import ualogowhite from "./ualogowhite.png";

function Banner(props) {
	const isAuthenticated = props.isAuthenticated;
	const [ccid, setCcid] = React.useState("");
	const navigate = useNavigate();

	useEffect(() => {
		if (isAuthenticated) {
			if (sessionStorage.getItem("ccid") != null) {
				setCcid(sessionStorage.getItem("ccid").toUpperCase());
			} else {
				navigate("/");
			}
		}
	}, []);

	const handleLogout = () => {
		sessionStorage.clear();
		navigate("/");
	};

	if (isAuthenticated) {
		return (
			<div>
				<Box
					sx={{
						minHeight: "12vh",
						backgroundColor: "#275d38",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "10px",
						padding: "10px",
					}}
				>
					<Box
						sx={{
							/* marginTop: "auto",
							marginBottom: "auto",
							marginLeft: "10px",
							marginRight: "10px", */
							position: "absolute",
							left: "20px",
							margin: "auto",
						}}
					></Box>
					<Typography
						variant="h2"
						sx={{
							color: "white",
							position: "absolute",
							left: "calc(50% - 250px)",
							display: { xs: "none", sm: "none", md: "none", lg: "block" },
						}}
					>
						TSS Request for Service
					</Typography>

					<Box sx={{ color: "white", position: "absolute", right: "10px" }}>
						<Typography>CCID: {ccid}</Typography>
						<Button
							className={styles["logout-button"]}
							onClick={handleLogout}
							variant="contained"
							color="invertedPrimary"
							sx={{
								width: { xl: 150, lg: 200, md: 200, sm: 200, xs: 200 },
								height: 50,
							}}
						>
							Logout
						</Button>
					</Box>
				</Box>
			</div>
		);
	}
	return (
		<div>
			<Box
				sx={{
					minHeight: "12vh",
					backgroundColor: "#275d38",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "10px",
					padding: "10px",
				}}
			>
				<Box
					sx={{
						/* marginTop: "auto",
							marginBottom: "auto",
							marginLeft: "10px",
							marginRight: "10px", */
						position: "absolute",
						left: "20px",
						margin: "auto",
					}}
				></Box>
				<Typography
					variant="h2"
					sx={{
						color: "white",
						position: "absolute",
						left: "calc(50% - 250px)",
						display: { xs: "none", sm: "none", md: "block" },
					}}
				>
					TSS Request for Service
				</Typography>

				<Box
					sx={{
						color: "white",
						position: "absolute",
						right: "10px",
						display: "none",
					}}
				>
					<Typography>CCID: {ccid}</Typography>
					<Button
						className={styles["logout-button"]}
						onClick={handleLogout}
						variant="contained"
						color="invertedPrimary"
						sx={{
							width: { xl: 150, lg: 200, md: 200, sm: 200, xs: 200 },
							height: 50,
						}}
					>
						Logout
					</Button>
				</Box>
			</Box>
		</div>
	);
}

export default Banner;
