import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function PostBatchSubmitDialog(props) {
	const handleClose = () => {
		props.setOpen(false);
	};

	const resultObj = props.batchSubmitResponse;

	return (
		<div>
			<Dialog open={props.open} onClose={handleClose} maxWidth="xl" sx={{ padding: "10px" }}>
				<h1 className="text-center text-2xl p-4">
					{resultObj?.failed_updates?.length > 0 ? (
						<p className="text-red-600">Error with Submission</p>
					) : (
						"Submission Completed"
					)}
				</h1>

				<DialogContent sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
					<p className="text-center text-lg p-2 border-2 rounded-md border-green-300 bg-green-100">
						{resultObj?.successful_updates > 0
							? `${resultObj?.successful_updates} Classes Submitted`
							: "0 Classes Submitted"}
					</p>
					{resultObj?.failed_updates?.length > 0 && (
						<div className="text-center text-lg p-2 border-2 rounded-md border-red-300 bg-red-100">
							<p>{resultObj?.failed_updates?.length} Classes Failed:</p>
							{resultObj?.failed_updates?.length > 0
								? resultObj?.failed_updates.map((failed, index) => {
										return (
											<div key={index}>
												{failed.classid} - {failed.ctitle}
											</div>
										);
								  })
								: ""}
						</div>
					)}
					{resultObj?.failed_updates?.length > 0 && (
						<div className="text-center text-lg p-2 ">
							<p>Please try again or contact TSQS for assistance.</p>
						</div>
					)}
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} variant="contained">
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
