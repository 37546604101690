import React from "react";
import { useEffect, useState, useRef } from "react";
import {
	Button,
	TextField,
	FormControlLabel,
	Radio,
	RadioGroup,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Switch,
	ButtonGroup,
} from "@mui/material";
import axios from "axios";
import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";
import Footer from "./components/Footer.js";
import AutoCompleteComponent from "./components/AutoCompleteComponent";
import { img1, img2 } from "./components/images.js";
import isAuthenticated from "../../components/Utils/isAuthenticated.js";
import { courierDepts } from "./utils/common.js";
import { setDate, subStringCourseTitle } from "./utils/helpers.js";
import GPSCor from "./GPScor/gpScor.js";
import { getRFSListByCCID } from "../../components/Api/Getters.js";
import Assign2 from "./Assign2/assign2.js";
import MRScor from "./MRScor/mrScor.js";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ArticleIcon from "@mui/icons-material/Article";
import CalculateIcon from "@mui/icons-material/Calculate";
import EditIcon from "@mui/icons-material/Edit";
//Save reorder sheet in O drive => NOT GOING TO BE EASY
//Create an admin panel where we can change status to "done", print out sheet, make requisitions for profs, etc.
//finish mrscor and advanced functions
//implement pickup or delivery

const inputProps = {
	variant: "outlined",
	minRows: 8,
	maxRows: 8,
	maxLength: 1000,
};

export default function Request() {
	const navigate = useNavigate();
	const auth = isAuthenticated();
	const token = auth.token;
	const ccid = auth.ccid;
	const firstName = auth.firstName;
	const lastName = auth.lastName;
	const userDept = auth.dept_ids.includes(",")
		? auth.dept_ids.split(",")[0]
		: auth.dept_ids;
	const [state, setState] = React.useState({
		reqnum: "",
		courseTitle: "",
		deptID: "",
		program: "1",
		lqNum: "1",
		hqNum: "",
		qLen: "5",
		nKeys: "",
		a: "",
		b: "",
		c: "0",
		d: "",
		e: "0",
		f: "0",
		k: "",
		l: "",
		m: "",
		n: "1",
		o: "0",
		p: "0",
		numOpen: "",
		docSize: "",
		instructorName: "",
		department: "",
		email1: "",
		email2: "",
		phone: "",
		address: "",
		singleSided: "",
		mrScor: "",
		assign2: "",
		formulaScoringChecked: false,
		classFromDatabase: "1",
		notes: "",
		yesOrNo: "",
		notMultipleResponse: "",
		weightingMultiple: "",
		schema: "",
		schemaNotes: "",
		weightingSingle: "",
		partial: "",
		overanswering: "",
		numQuestions: "",
		numAnswers: "",
		numSingleResponse: "",
		numMultipleResponse: "",
		multipleResponseQuestions: [],
		singleResponseQuestions: [],

		valueNumKeys: "1",
		valueNumQuestions: "",
		valueNumAnswers: "5",
		valueClassNum: [],

		penalties: "",
		compKey1Weight: "",
		compKey2Weight: "",
		compKey3Weight: "",
		compKey4Weight: "",
		compKey5Weight: "",
	});

	const [assign2State, setAssign2State] = useState({
		valueNumBooklets: "",
		valueNumPages: "",
		valueClassNum: [],
		tsqsLoadOrNot: "",
		singleOrDouble: "Double sided",
		classRecord: "",
		examDate: "",
		midtermOrFinal: "",
	});

	const [typeOfExam, setTypeOfExam] = React.useState("");
	const [gotDepartments, setGotDepartments] = React.useState(false);
	const [dataFilled, setDataFilled] = React.useState(false);
	const [classNumList, setClassNumList] = React.useState([{}]);
	const [departments, setDepartments] = React.useState([]);
	const [departmentsDict, setDepartmentsDict] = React.useState([
		{ key: "", value: "", label: "" },
	]);
	const examTypeRef = useRef(null);

	const [deptFromPrevious, setDeptFromPrevious] = React.useState("");

	const [errorHelpText, setErrorHelpText] = useState({
		address: "",
		deptID: "",
		email1: "",
		instructorName: "",
	});

	const [errorState, setErrorState] = useState({
		address: false,
		deptID: false,
		email1: false,
		instructorName: false,
	});

	const [instructorClasses, setInstructorClasses] = useState([]);

	const handleGetRFSListByCCID = async (ccid) => {
		try {
			const response = await getRFSListByCCID(token, ccid);

			if (response.length > 0) {
				if (response[0].caddr2) {
					setDeptFromPrevious(response[0].caddr2.trim());
				}

				setState({
					...state,
					instructorName: firstName.concat(" ").concat(lastName),
					department: response[0]?.caddr2 && response[0].caddr2.trim(),
					deptID: Number(response[0].dept),
					email1: ccid.concat("@ualberta.ca"),
					phone: response[0].phone,
					address: response[0].caddr3,
					c: "0",
					o: "",
					p: "",
					pocketflag: "",
					numOpen: 1,
					docSize: 1,
					program: 1,
					singleSided: true,
				});
			} else {
				setState({
					...state,
					instructorName: firstName.concat(" ").concat(lastName),
					deptID: userDept,
					email1: ccid.concat("@ualberta.ca"),
				});
			}

			setDataFilled(true);
		} catch (error) {
			setDataFilled(true);
			console.error(error);
		}
	};

	useEffect(() => {
		if (dataFilled === false && ccid !== "") {
			handleGetRFSListByCCID(ccid);
		}
		// eslint-disable-next-line
	}, [dataFilled, ccid]);

	useEffect(() => {
		if (token !== "") {
			// getResponse(token);
			getDepartments(token);
		}

		async function getDepartments(token) {
			if (!gotDepartments) {
				let url = process.env.REACT_APP_BACKEND_HOST.concat(
					"/scanner/departments/"
				);
				const response = await fetch(url, {
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Token ${token}`,
					},
				});

				let output = await response.json();
				if (output.length > 0) {
					Promise.all(output).then((result) => {
						result.forEach((department) => {
							department.DNAME = department.DNAME.replace("Department of", "");
						});
						//sort by dname
						result.sort((a, b) => (a.DNAME > b.DNAME ? 1 : -1));
						setDepartments(result);
					});
					setGotDepartments(true);
				}
			} else {
				let i = 1;
				let departmentsDict = [];

				departments.forEach((department) => {
					//remove "Department of" from the beginning of each dname

					departmentsDict.push({
						key: i,
						value: department.DEPT_ID,
						label: department.DNAME.trim() + " (" + department.DEPT_ID + ")",
					});
					i++;
				});
				setDepartmentsDict(departmentsDict);
			}
		}
	}, [token, departments, gotDepartments]);

	useEffect(() => {
		if (state.deptID && state.deptID !== "") {
			getClassesFromDepartments(token, state.deptID);
		}
	}, [state.deptID, token]);

	useEffect(() => {
		//when typeOfExam changes, jump to ref
		if (typeOfExam) {
			examTypeRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [typeOfExam]);

	const handleChange = (event) => {
		const value = event.target.value;
		setState({
			...state,
			[event.target.name]: value,
		});
		if (event.target.name === "instructorName") {
			setErrorState((prev) => ({ ...prev, instructorName: false }));
		}
		if (event.target.name === "email1") {
			setErrorState((prev) => ({ ...prev, email1: false }));
		}

		if (event.target.name === "address") {
			setErrorState((prev) => ({ ...prev, address: false }));
		}
	};
	useEffect(() => {
		if (courierDepts.includes(state.deptID)) {
			setState({
				...state,
				address: "Courier",
			});
		} else if (state.address === "Courier") {
			setState({
				...state,
				address: "",
			});
		}
		// eslint-disable-next-line
	}, [state.deptID]);

	const handleChangeAutoComplete = (event, value) => {
		setClassNumList([]);

		if (value) {
			setState({
				...state,
				department: value.label,
				deptID: value.value,
			});
		}
	};

	const getClassesFromDepartments = (token, deptID) => {
		axios({
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Token ${token}`,
			},
			method: "get",
			url: process.env.REACT_APP_BACKEND_HOST.concat(
				"/scanner/department_classes/".concat(`${deptID}`).concat("/")
			),
		}).then((res) => {
			let listClassNum = [];
			if (res.data.length > 0) {
				res.data.forEach((classFromDept) => {
					const listClassObj = {
						key: classFromDept.CLASSID,
						value: classFromDept.CLASSID,
						label: classFromDept.CTITLE,
						name: classFromDept.NAME,
						term: classFromDept.TERM_NAME,
						term_start: classFromDept.TERM_START,
						term_end: classFromDept.TERM_END,
						ccid: classFromDept.CCID,
					};
					listClassNum.push(listClassObj);
				});

				var labels = listClassNum.map(function (e) {
					return e.label;
				});

				labels.sort(function (a, b) {
					var aSplit = a.split(" ");
					var bSplit = b.split(" ");

					var s1 = aSplit[0];
					var s2 = bSplit[0];

					var n1 = aSplit.length === 5 ? aSplit[2] : aSplit[1];
					var n2 = bSplit.length === 5 ? bSplit[2] : bSplit[1];

					return s1 < s2 ? -1 : s1 > s2 ? 1 : n1 < n2 ? -1 : n1 > n2 ? 1 : 0;
				});
				var sorted = [];
				labels.forEach(function (label) {
					sorted.push(
						listClassNum.find(function (e) {
							return e.label === label;
						})
					);
				});
				//remove duplicates
				sorted = sorted.filter(
					(item, index, self) =>
						index === self.findIndex((t) => t.value === item.value)
				);

				setClassNumList(sorted);
			}
		});
	};

	useEffect(() => {
		if (classNumList && classNumList.length > 0) {
			let instructorClasses = classNumList.filter(
				(classItem) => classItem.ccid === ccid
			);

			setInstructorClasses(instructorClasses);
		}
	}, [classNumList, ccid]);

	// This function creates a fresh document and returns it.
	// It does not immediately trigger printing/saving.
	const buildDoc = (pulledReqnum) => {
		// Always create a new instance
		const doc = new jsPDF();

		// Calculate courseTitle based on current state
		let classTitle = classNumList.find(
			(item) =>
				item.value === state.valueClassNum[0] ||
				item.value === state.valueClassNum
		).label;

		if (classTitle.split(" ").length > 4) {
			//combine the first two indexes
			let classTitle1 = classTitle.split(" ").slice(0, 2).join("").trim();
			let classTitle2 = classTitle.split(" ").slice(2).join(" ").trim();
			classTitle = classTitle1 + " " + classTitle2;
		}

		// Process notes for printing
		let notesPrint = "";
		if (state.notes && state.notes !== "") {
			if (state.notes.length > 46) {
				if (state.notes.length > 330) {
					notesPrint = state.notes.substring(0, 250) + "...";
					notesPrint = notesPrint.replace(/(.{46})/g, "$1\n");
				} else {
					notesPrint = state.notes.replace(/(.{46})/g, "$1\n");
				}
			} else {
				notesPrint = state.notes;
			}
		} else {
			notesPrint = "None";
		}

		if (typeOfExam === "gpscor" || typeOfExam === "mrscor") {
			const typeOfExamPrint =
				typeOfExam === "gpscor"
					? "Standard Multiple Choice"
					: "Multiple Response Scoring";

			// Example images and text placement for these types
			doc.addImage(img2, "PNG", 80, 275, 50, 14);
			doc.addImage(img1, "PNG", 10, 5, 25, 25);
			doc.setDrawColor("#275d38");
			doc.setFont(undefined, "bold");
			doc.text("Requisition number: " + pulledReqnum, 65, 34);
			doc.setFont(undefined, "normal");

			doc.rect(8, 37, doc.internal.pageSize.width - 16, 83);
			doc.text("Request Date: " + setDate(), 30, 50);
			doc.text("Client Name: " + state.instructorName, 30, 60);
			if (state.email2 && state.email2 !== "") {
				doc.text(
					"Emails: " + state.email1.toString() + ", " + state.email2.toString(),
					30,
					70
				);
			} else {
				doc.text("Email: " + state.email1.toString(), 30, 70);
			}
			doc.text("Phone: " + state.phone.toString(), 30, 80);
			doc.text("Department: " + state.department, 30, 90);
			doc.text("Address: " + state.address.substring(0, 32), 30, 100);
			doc.text("Program: " + typeOfExamPrint, 30, 110);
			doc.rect(8, 120, doc.internal.pageSize.width - 16, 65);
			doc.text(
				"Number of questions: " + (state.hqNum - state.lqNum + 1),
				30,
				132
			);
			doc.text("Number of answers per question: " + state.qLen, 30, 143);
			doc.text("Number of answer keys: " + state.valueNumKeys, 30, 153);
			doc.text("Class: " + classTitle.substring(0, 16), 30, 163);
			doc.text("ID(s): " + state.valueClassNum.toString(), 30, 173);

			doc.rect(8, 185, doc.internal.pageSize.width - 16, 50);
			doc.text("Notes: " + notesPrint, 30, 198);
			doc.rect(8, 235, doc.internal.pageSize.width - 16, 35);
			doc.text(
				"If you have any questions or require assistance, please contact TSS",
				15,
				248
			);
			doc.text(
				"by emailing opscan@ualberta.ca or by calling 780.492.2741.",
				25,
				258
			);
		} else if (typeOfExam === "assign2") {
			// Build the receipt for an Assign2 exam
			doc.addImage(img2, "PNG", 80, 275, 50, 14);
			doc.addImage(img1, "PNG", 10, 3, 25, 25);
			doc.setDrawColor("#275d38");
			doc.setFont(undefined, "bold");
			doc.text("Requisition number: " + pulledReqnum, 68, 27);
			doc.setFont(undefined, "normal");

			doc.rect(8, 32, doc.internal.pageSize.width - 16, 83);
			doc.text("Request Date: " + setDate(), 30, 45);
			doc.text("Client Name: " + state.instructorName, 30, 55);
			doc.text("Email: " + state.email1.toString(), 30, 65);
			doc.text("Phone: " + state.phone.toString(), 30, 75);
			doc.text("Department: " + state.department, 30, 85);
			doc.text("Address: " + state.address, 30, 95);
			doc.rect(8, 115, doc.internal.pageSize.width - 16, 75);
			doc.text("Number of booklets: " + assign2State.valueNumBooklets, 30, 128);
			doc.text(
				"Number of pages per booklet: " + assign2State.valueNumPages,
				30,
				138
			);
			doc.text("Sided: " + assign2State.singleOrDouble, 30, 148);
			if (assign2State.tsqsLoadOrNot === "upload") {
				doc.text(
					"Upload: TSS will load the exam. Please add one of us as",
					30,
					158
				);
				doc.text("an instructor.", 30, 168);
			}
			if (assign2State.tsqsLoadOrNot === "doNotUpload") {
				doc.text(
					"Send: TSS will email you the scanned .pdf files once",
					30,
					158
				);
				doc.text("the upload has finished", 30, 163);
			}
			doc.text("Class: " + classTitle.substring(0, 16), 30, 178);
			doc.rect(8, 190, doc.internal.pageSize.width - 16, 65);
			doc.text(
				"If you have any questions or require assistance, please contact TSS",
				15,
				213
			);
			doc.text(
				"by emailing opscan@ualberta.ca or by calling 780.492.2741.",
				25,
				223
			);
		}
		return doc;
	};

	// This function triggers the actual printing/saving.
	const handlePrint = async (pulledReqnum) => {
		// Build a new document with the latest state
		const doc = buildDoc(pulledReqnum);

		// Optionally, if you want to auto-print:
		doc.autoPrint();

		// Save the PDF (this will trigger a download)
		doc.save("Test Scoring Receipt " + pulledReqnum + ".pdf");
	};

	/* useEffect(() => {
		// For preview purposes, use a dummy requisition number (or the latest known one)
		const previewReqnum = "PREVIEW";
		const doc = buildDoc(previewReqnum);
		setDocState(doc.output("datauristring"));
		// Get the data URI string of the PDF
	}, [state, assign2State, typeOfExam]); //memory leaking as heck */

	const [isFormFilled, setIsFormFilled] = useState(false);

	useEffect(() => {
		if (state.instructorName && state.deptID && state.email1 && state.address) {
			setIsFormFilled(true);
		} else {
			setIsFormFilled(false);
		}
	}, [state.instructorName, state.deptID, state.email1, state.address]);

	const handleTypeOfExamChange = (event) => {
		const value = event.target.value;
		if (typeOfExam === value) {
			setTypeOfExam("");
		} else {
			if (state.instructorName === "") {
				setErrorState((prev) => ({ ...prev, instructorName: true }));
				setErrorHelpText((prev) => ({
					...prev,
					instructorName: "Please enter instructor name",
				}));
			}
			if (state.deptID === "") {
				setErrorState((prev) => ({ ...prev, deptID: true }));
				setErrorHelpText((prev) => ({
					...prev,
					deptID: "Please enter department",
				}));
			}
			if (state.email1 === "") {
				setErrorState((prev) => ({ ...prev, email1: true }));
				setErrorHelpText((prev) => ({ ...prev, email1: "Please enter email" }));
			}
			if (state.address === "") {
				setErrorState((prev) => ({ ...prev, address: true }));
				setErrorHelpText((prev) => ({
					...prev,
					address: "Please enter address",
				}));
			} else if (
				state.instructorName !== "" &&
				state.deptID !== "" &&
				state.email1 !== "" &&
				state.address !== ""
			) {
				setTypeOfExam(value);
				if (value === "gpscor") {
					setState({
						...state,
						program: "1",
					});
				} else if (value === "mrscor") {
					setState({
						...state,
						program: "2",
					});
				} else if (value === "assign2") {
					setState({
						...state,
						program: "4",
					});
				}
				getClassesFromDepartments(token, state.deptID);
			}
		}
	};

	const handleSetPickup = () => {
		setState({
			...state,
			address:
				state.address === "Pick Up"
					? courierDepts.includes(Number(state.deptID))
						? "Courier"
						: ""
					: "Pick Up",
		});
	};

	//Need to add checks for all text fields before next is pressed.
	return (
		<div className="flex flex-col items-center justify-start min-h-[80dvh] bg-gray-100">
			<main className="flex w-full flex-col items-center min-h-[90dvh]">
				{/* Main Request Form */}
				<div className="flex w-full max-w-4xl flex-col items-center border-2 my-8 rounded-md border-t-2 bg-white p-10">
					<div className="mb-4 text-center">
						<h5 className="text-xl font-medium text-primary">
							Exam Scoring/Scanning Request Form
						</h5>
					</div>
					<div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
						<div className="flex flex-col col-span-2">
							<label
								htmlFor="instructorName"
								className="font-medium text-primary"
							>
								Instructor Name*
							</label>
							<input
								type="text"
								id="instructorName"
								name="instructorName"
								value={state.instructorName || ""}
								maxLength={32}
								onChange={handleChange}
								className="mt-1 p-2 border rounded w-full"
							/>
							{errorState.instructorName && (
								<span className="text-red-500 text-xs mt-1">
									Please enter instructor name
								</span>
							)}
						</div>
						{/* Primary Email */}
						<div className="flex flex-col">
							<label
								htmlFor="email1"
								className="font-medium text-primary"
							>
								Primary Email*{" "}
								<span className="text-sm text-gray-500">
									(Processed results will be sent here)
								</span>
							</label>
							<input
								type="email"
								id="email1"
								name="email1"
								value={state.email1 || ""}
								maxLength={60}
								onChange={handleChange}
								className="mt-1 p-2 border rounded w-full"
							/>
							{errorState.email1 && (
								<span className="text-red-500 text-xs mt-1">
									Please enter instructor email
								</span>
							)}
						</div>
						<div className="flex flex-col">
							<label
								htmlFor="email2"
								className="font-medium text-primary"
							>
								Secondary Email{" "}
								<span className="text-sm text-gray-500">(Optional)</span>
							</label>
							<input
								type="email"
								id="email2"
								name="email2"
								maxLength={60}
								value={state.email2 || ""}
								onChange={handleChange}
								className="mt-1 p-2 border rounded w-full"
							/>
						</div>
						{/* Department */}

						{/* Secondary Email */}

						{/* Pickup / Delivery */}
						<div className="flex flex-col col-span-2">
							<label
								htmlFor="address"
								className="font-medium text-primary relative"
							>
								Delivery Address*{" "}
								<span className="text-sm text-gray-500">
									(Physical sheets will be delivered here)
								</span>
								<button
									className=" absolute bottom-0 right-0 text-sm text-[#275d38]"
									onClick={handleSetPickup}
								>
									Pick Up
									<Switch
										size="small"
										checked={state.address === "Pick Up"}
										onChange={handleSetPickup}
									/>
								</button>
							</label>
							<input
								type="text"
								id="address"
								name="address"
								value={state.address || ""}
								maxLength={60}
								onChange={handleChange}
								disabled={state.address === "Pick Up"}
								className="mt-1 p-2 border rounded w-full"
							/>
							{errorState.address && (
								<span className="text-red-500 text-xs mt-1">
									{errorState.address}
								</span>
							)}
						</div>
						<AutoCompleteComponent
							id="outlined-basic"
							required
							label="Department"
							helperText={errorState.deptID && "Please select department"}
							error={errorState.deptID || (dataFilled && state.deptID === "")}
							name="department"
							variant="outlined"
							options={departmentsDict}
							onChange={handleChangeAutoComplete}
							value={state.deptID}
						/>

						{typeOfExam !== "gpscor" &&
						typeOfExam !== "mrscor" &&
						typeOfExam !== "assign2" ? (
							<FormControl
								fullWidth
								className="col-span-2 "
								variant="outlined"
								required
							>
								<div className="flex ">
									<button
										disabled={!isFormFilled}
										onClick={() => {
											setTypeOfExam("gpscor");
										}}
										className={`  rounded-tl-md rounded-bl-md w-1/3 py-6 bg-primary  text-white hover:bg-dark hover:text-white disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed
									
									${typeOfExam === "gpscor" ? "bg-primary text-white" : "bg-gray-100"}`}
									>
										<div className="flex flex-col items-center gap-2">
											Standard Multiple Choice Exam
											<div className="flex gap-1">
												<RadioButtonUncheckedIcon />
												<RadioButtonCheckedIcon />
												<RadioButtonUncheckedIcon />
												<RadioButtonUncheckedIcon />
											</div>
											{/* <span className="text-xs">(Scantron)</span> */}
										</div>
									</button>
									<button
										disabled={!isFormFilled}
										onClick={() => {
											setTypeOfExam("mrscor");
										}}
										className={`border-l-2 border-r-2  w-1/3 bg-primary text-white border-gray-200 hover:bg-dark hover:text-white disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed
									${typeOfExam === "gpscor" ? "bg-primary text-white" : "bg-gray-100"}`}
									>
										<div className="flex flex-col items-center gap-2">
											Multiple Response Exam
											<div className="flex gap-1">
												<RadioButtonCheckedIcon />
												<RadioButtonCheckedIcon />
												<RadioButtonCheckedIcon />
												<RadioButtonUncheckedIcon />
											</div>
											{/* <span className="text-xs">(Scantron)</span> */}
										</div>
									</button>
									<button
										disabled={!isFormFilled}
										className={`  rounded-tr-md rounded-br-md w-1/3 bg-primary text-white  hover:bg-dark hover:text-white disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed
									${typeOfExam === "assign2" ? "bg-primary text-white" : "bg-gray-100"}`}
										onClick={() => {
											setTypeOfExam("assign2");
										}}
									>
										<div className="flex flex-col items-center gap-2">
											Assign2 Written Exam
											<div className="flex gap-1">
												<EditIcon />
												<ArticleIcon />
												<CalculateIcon />
											</div>
											{/* <span className="text-xs">(Assign2)</span> */}
										</div>
									</button>
								</div>
							</FormControl>
						) : (
							<FormControl
								fullWidth
								className="col-span-2 "
								variant="outlined"
								required
							>
								<InputLabel
									id="exam-type-label"
									className="mt-4"
									sx={{ color: "#275d38" }}
								>
									{isFormFilled
										? "Select Exam Type"
										: "Please fill out the required form fields."}
								</InputLabel>
								<Select
									labelId="exam-type-label"
									value={typeOfExam}
									disabled={!isFormFilled}
									onChange={handleTypeOfExamChange}
									label="Select Exam TypeT"
									className="mt-4"
								>
									<MenuItem value="gpscor">
										<div className="flex items-center gap-2">
											Standard Multiple Choice Exam
											<div className="flex gap-1">
												<RadioButtonUncheckedIcon />
												<RadioButtonCheckedIcon />
												<RadioButtonUncheckedIcon />
												<RadioButtonUncheckedIcon />
											</div>
											{/* <span className="text-xs">(Scantron)</span> */}
										</div>
									</MenuItem>

									<MenuItem value="mrscor">
										<div className="flex items-center gap-2">
											Multiple Response Exam
											<div className="flex gap-1">
												<RadioButtonCheckedIcon />
												<RadioButtonCheckedIcon />
												<RadioButtonCheckedIcon />
												<RadioButtonUncheckedIcon />
											</div>
											{/* <span className="text-xs">(Scantron)</span> */}
										</div>
									</MenuItem>

									<MenuItem value="assign2">
										<div className="flex items-center gap-2">
											Written Exam
											<div className="flex gap-1">
												<EditIcon />
												<ArticleIcon />
												<CalculateIcon />
											</div>
											{/* <span className="text-xs">(Assign2)</span> */}
										</div>
									</MenuItem>
								</Select>
							</FormControl>
						)}
					</div>
				</div>
				{typeOfExam && (
					<div
						ref={examTypeRef}
						className="flex w-full max-w-4xl flex-col items-center  border-2 mb-8  rounded-md border-t-2  bg-white p-10"
					>
						{/* Conditional Components */}
						{typeOfExam === "gpscor" && (
							<GPSCor
								state={state}
								setState={setState}
								classNumList={classNumList}
								navigate={navigate}
								token={token}
								handleChange={handleChange}
								ccid={ccid}
								handlePrint={handlePrint}
								inputProps={inputProps}
								instructorClasses={instructorClasses}
							/>
						)}
						{typeOfExam === "assign2" && (
							<Assign2
								navigate={navigate}
								state={state}
								setState={setState}
								assign2State={assign2State}
								setAssign2State={setAssign2State}
								classNumList={classNumList}
								token={token}
								handleChange={handleChange}
								ccid={ccid}
								handlePrint={handlePrint}
								instructorClasses={instructorClasses}
							/>
						)}
						{typeOfExam === "mrscor" && (
							<MRScor
								state={state}
								setState={setState}
								classNumList={classNumList}
								navigate={navigate}
								token={token}
								handleChange={handleChange}
								ccid={ccid}
								handlePrint={handlePrint}
								inputProps={inputProps}
							/>
						)}
					</div>
				)}
			</main>
			<Footer />
		</div>
	);
}
