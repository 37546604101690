import { useEffect, useState } from "react";
import axios from "axios";
import { setDate, subStringCourseTitle } from "../utils/helpers";
import Assign2ResultsRadio from "../components/Assign2ResultsRadio";
import Assign2InstructorInstructions from "../components/Assign2InstructorInstructions";
import Button from "@mui/material/Button";
import { singleOrDoubleSided } from "../utils/common";
import { getReqNum } from "../utils/api";

export default function Assign2(props) {
	const {
		navigate,
		state,
		setState,
		token,
		ccid,
		assign2State,
		setAssign2State,
		handlePrint,
		classNumList,
	} = props;

	const [assign2Error, setAssign2Error] = useState(false);
	const [assign2HelpText, setAssign2HelpText] = useState(" ");
	const [errorState, setErrorState] = useState({
		valueNumBooklets: false,
		valueNumPages: false,
		singleOrDouble: false,
		classRecord: false,
		examDate: false,
		midtermOrFinal: false,
	});

	const [errorHelpText, setErrorHelpText] = useState({
		valueNumBooklets: "",
		valueNumPages: "",
		classRecord: "",
		singleOrDouble: "",
		examDate: "",
		midtermOrFinal: "",
	});

	const getClassTitle = (classNum) => {
		let classTitle = "";
		classNumList.forEach((classItem) => {
			if (classItem.value === classNum) {
				classTitle = classItem.label;
			}
		});
		let splitTitle = classTitle.split(" ");
		if (splitTitle.length > 4) {
			splitTitle[0] = splitTitle[0].concat(splitTitle[1]);
			splitTitle.splice(1, 1);
			classTitle = splitTitle.join(" ");
		} else {
			classTitle = splitTitle.join(" ");
		}
		setState({
			...state,
			courseTitle: classTitle,
		});
		return classTitle;
	};

	const [finalNotes, setFinalNotes] = useState("");

	useEffect(() => {
		let notes = state.notes;
		let additionalNotes =
			"Class: " +
			assign2State.classRecord +
			" : " +
			assign2State.midtermOrFinal +
			" : " +
			assign2State.valueNumBooklets +
			" booklets : " +
			assign2State.valueNumPages +
			" pages per booklet : " +
			assign2State.singleOrDouble +
			" : " +
			assign2State.tsqsLoadOrNot;
		setFinalNotes(additionalNotes.concat(notes));
	}, [
		state.notes,
		assign2State.classRecord,
		assign2State.valueNumBooklets,
		assign2State.valueNumPages,
		assign2State.singleOrDouble,
		assign2State.tsqsLoadOrNot,
		assign2State.midtermOrFinal,
	]);

	const postAssign2Data = (pulledReqnum) => {
		let courseTitle = getClassTitle(assign2State.valueClassNum[0]);
		let singleDouble = "";
		if (assign2State.singleOrDouble === "Single sided") {
			singleDouble = "single";
		} else if (assign2State.singleOrDouble === "Double sided") {
			singleDouble = "double";
		}
		let uploadVar = assign2State.tsqsLoadOrNot === "upload" ? 1 : 0;
		axios({
			headers: {
				Authorization: `Token ${token}`,
			},
			method: "post",
			url: process.env.REACT_APP_BACKEND_HOST.concat(
				"/scanner/new_assign2_requisition/"
			),
			data: {
				scanner_request: {
					reqnum: pulledReqnum,
					program: 4,
					rqdate: setDate(),
					coursetitle: subStringCourseTitle(courseTitle),
					instructor_name: state.instructorName,
					numopen: state.numOpen,
					docsize: state.docSize,
					singlesided: state.singleSided,
					userid: ccid,
					email1: state.email1,
					phone: state.phone,
					speedcode: 84311,
					caddr2: state.department.trim().replace(/ *\([^)]*\) */g, ""),
					caddr3: state.address,
					status: "Received",
					dept: state.deptID,
					notes: finalNotes,
					received: assign2State.examDate,
				},
				scanner_processing: {
					client: state.instructorName,
					booklets: assign2State.valueNumBooklets,
					pages: assign2State.valueNumPages,
					sided: singleDouble,
					upload: uploadVar,
					reqnum: pulledReqnum,
					reportnum: 1,
				},
			},
		})
			.catch(function (error) {
				if (error.response) {
					console.log("data", error.response.data);
				}
			})
			.then(() => navigate("/success"));
	};

	const handleAssign2Change = (event) => {
		const value = event.target.value;
		setAssign2State({ ...assign2State, [event.target.name]: value });
		setAssign2Error(false);
		setAssign2HelpText("");
		if (event.target.name === "valueNumBooklets") {
			setErrorState((prev) => ({ ...prev, valueNumBooklets: false }));
			setErrorHelpText((prev) => ({ ...prev, valueNumBooklets: "" }));
		}
		if (event.target.name === "valueNumPages") {
			setErrorState((prev) => ({ ...prev, valueNumPages: false }));
			setErrorHelpText((prev) => ({ ...prev, valueNumPages: "" }));
		}
		if (event.target.name === "classRecord") {
			setErrorState((prev) => ({ ...prev, classRecord: false }));
			setErrorHelpText((prev) => ({ ...prev, classRecord: "" }));
		}
		if (event.target.name === "examDate") {
			setErrorState((prev) => ({ ...prev, examDate: false }));
			setErrorHelpText((prev) => ({ ...prev, examDate: "" }));
		}
		if (event.target.name === "midtermOrFinal") {
			setErrorState((prev) => ({ ...prev, midtermOrFinal: false }));
			setErrorHelpText((prev) => ({ ...prev, midtermOrFinal: "" }));
		}
	};

	const handleSubmit = () => {
		if (assign2State.tsqsLoadOrNot === "") {
			setAssign2HelpText("Please select a results delivery option");
			setAssign2Error(true);
		}
		if (assign2State.valueNumBooklets === "") {
			setErrorState((prev) => ({ ...prev, valueNumBooklets: true }));
			setErrorHelpText((prev) => ({
				...prev,
				valueNumBooklets: "Please enter a value",
			}));
		}
		if (assign2State.classRecord === "") {
			setErrorState((prev) => ({ ...prev, classRecord: true }));
			setErrorHelpText((prev) => ({
				...prev,
				classRecord: "Please enter a value",
			}));
		}
		if (assign2State.examDate === "") {
			setErrorState((prev) => ({ ...prev, examDate: true }));
			setErrorHelpText((prev) => ({
				...prev,
				examDate: "Please enter a value",
			}));
		}
		if (
			assign2State.midtermOrFinal !== "Midterm" &&
			assign2State.midtermOrFinal !== "Final"
		) {
			setErrorState((prev) => ({ ...prev, midtermOrFinal: true }));
			setErrorHelpText((prev) => ({
				...prev,
				midtermOrFinal: "Please indicate if the exam is a midterm or final",
			}));
		}
		if (assign2State.valueNumPages === "") {
			setErrorState((prev) => ({ ...prev, valueNumPages: true }));
			setErrorHelpText((prev) => ({
				...prev,
				valueNumPages: "Please enter a value",
			}));
		} else if (
			assign2State.tsqsLoadOrNot !== "" &&
			state.instructorName !== "" &&
			state.deptID !== "" &&
			state.email1 !== "" &&
			assign2State.valueNumBooklets !== "" &&
			assign2State.valueNumPages !== "" &&
			assign2State.classRecord !== "" &&
			assign2State.examDate !== "" &&
			assign2State.midtermOrFinal !== ""
		) {
			setAssign2Error(false);
			setAssign2HelpText("");

			getReqNum().then((pulledReqnum) => {
				postAssign2Data(pulledReqnum);
				handlePrint(pulledReqnum);
			});
		}
	};

	const midtermOrFinalOptions = [
		{ key: 1, value: "Midterm" },
		{ key: 2, value: "Final" },
	];

	return (
		<div className="relative w-full">
			<div className="flex flex-col w-full gap-4 pt-8">
				{/* Exam Booklets Field */}
				<div className="flex flex-col">
					<label
						htmlFor="valueNumBooklets"
						className="font-medium text-primary"
					>
						How many exam booklets would you like to scan?
					</label>
					<input
						type="number"
						id="valueNumBooklets"
						name="valueNumBooklets"
						value={assign2State.valueNumBooklets || ""}
						onChange={handleAssign2Change}
						className={`mt-1 p-2 border rounded ${
							errorState.valueNumBooklets ? "border-red-500" : "border-gray-300"
						}`}
					/>
					{errorState.valueNumBooklets && (
						<span className="text-red-500 text-xs mt-1">
							{errorHelpText.valueNumBooklets}
						</span>
					)}
				</div>

				<div className="flex flex-col">
					<label
						htmlFor="valueNumPages"
						className="font-medium text-primary"
					>
						How many pages are in each booklet?
					</label>
					<input
						type="number"
						id="valueNumPages"
						name="valueNumPages"
						value={assign2State.valueNumPages || ""}
						onChange={handleAssign2Change}
						className={`mt-1 p-2 border rounded ${
							errorState.valueNumPages ? "border-red-500" : "border-gray-300"
						}`}
					/>
					{errorState.valueNumPages && (
						<span className="text-red-500 text-xs mt-1">
							{errorHelpText.valueNumPages}
						</span>
					)}
				</div>

				<div className="flex flex-col">
					<label
						htmlFor="singleOrDouble"
						className="font-medium text-primary"
					>
						Are the booklets single or double sided?
					</label>
					<select
						id="singleOrDouble"
						name="singleOrDouble"
						value={assign2State.singleOrDouble || ""}
						onChange={handleAssign2Change}
						className={`mt-1 hover:cursor-pointer p-2 border rounded ${
							errorState.singleOrDouble ? "border-red-500" : "border-gray-300"
						}`}
					>
						<option
							value=""
							key="999"
						>
							Select an option
						</option>
						{singleOrDoubleSided.map((option) => (
							<option
								key={option.key}
								value={option.value}
							>
								{option.label}
							</option>
						))}
					</select>
					{errorState.qLen && (
						<span className="text-red-500 text-xs mt-1">
							{errorHelpText.singleOrDouble}
						</span>
					)}
				</div>

				{/* Dropdowns: Single/Double sided and Class selector */}

				<div className="flex flex-col">
					<label
						htmlFor="classRecord"
						className="font-medium text-primary"
					>
						Please enter the class below (e.g. MATH 100)
					</label>
					<input
						type="text"
						id="classRecord"
						name="classRecord"
						value={assign2State.classRecord || ""}
						onChange={handleAssign2Change}
						className={`mt-1 p-2 border rounded ${
							errorState.classRecord ? "border-red-500" : "border-gray-300"
						}`}
					/>
					{errorState.classRecord && (
						<span className="text-red-500 text-xs mt-1">
							{errorHelpText.classRecord}
						</span>
					)}
				</div>

				<div className="flex flex-col">
					<label
						htmlFor="midtermOrFinal"
						className="font-medium text-primary"
					>
						What day and time do you expect to drop off the exam?
					</label>
					<input
						type="datetime-local"
						id="examDate"
						name="examDate"
						value={assign2State.examDate || ""}
						onChange={handleAssign2Change}
						className={`mt-1 p-2 border rounded ${
							errorState.examDate ? "border-red-500" : "border-gray-300"
						}`}
					/>
					{errorState.examDate && (
						<span className="text-red-500 text-xs mt-1">
							{errorHelpText.examDate}
						</span>
					)}
				</div>

				<div className="flex flex-col">
					<label
						htmlFor="midtermOrFinal"
						className="font-medium text-primary"
					>
						Is the exam a midterm or a final?
					</label>
					<select
						id="midtermOrFinal"
						name="midtermOrFinal"
						value={assign2State.midtermOrFinal || ""}
						onChange={handleAssign2Change}
						className={`mt-1 hover:cursor-pointer p-2 border rounded ${
							errorState.midtermOrFinal ? "border-red-500" : "border-gray-300"
						}`}
					>
						<option
							value=""
							key="999"
						>
							Select an option
						</option>
						{midtermOrFinalOptions.map((option) => (
							<option
								key={option.key}
								value={option.value}
							>
								{option.value}
							</option>
						))}
					</select>
					{errorState.midtermOrFinal && (
						<span className="text-red-500 text-xs mt-1">
							{errorHelpText.midtermOrFinal}
						</span>
					)}
				</div>

				{/* Results Delivery Option */}
				<div className="">
					<Assign2ResultsRadio
						value={assign2State.tsqsLoadOrNot}
						onChange={handleAssign2Change}
						name="tsqsLoadOrNot"
						error={assign2Error}
						helpText={assign2HelpText}
					/>
				</div>

				{/* Instructor Instructions */}

				<Assign2InstructorInstructions upload={assign2State.tsqsLoadOrNot} />

				{/* Submit Button */}
				<Button
					variant="contained"
					color="primary"
					onClick={handleSubmit}
					className="w-full"
				>
					Submit
				</Button>
			</div>
		</div>
	);
}
