import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { updateDepartmentOptStatus } from "../../../components/Api/Updates";

export default function ExplanationDialog(props) {
	const handleClose = () => {
		props.setOpen(false);
	};

	return (
		<div>
			<Dialog open={props.open} maxWidth="2xl">
				<DialogTitle textAlign={"center"}>Confirm Department Intent</DialogTitle>

				<div className="flex flex-col gap-4 max-w-3xl p-4 ">
					<p>
						When SPOT surveys conclude, University policy dictates that results cannot be shared
						with instructors until grade results for all students in their class have been approved
						and finalized.
					</p>
					<p>
						In the past, this has been a manual process where departments would confirm grade
						finalization statuses for each class. We have been working with the registrar's office
						to automate this process and reduce overhead for departments as well as ourselves.
					</p>
					<p>
						Starting this term, our class data will regularly pull in the most recent grade
						finalization statuses for all classes, and we will use this data to automatically
						release SPOT survey results to instructors. Reports will only be released to instructors
						if a class has a 100% grade finalization status.
					</p>
					<p>
						By default, all departments have been "opted in" to the new automated method of
						confirming grade finalization statuses. If you have a specific need or reason to want to
						retain manual control over the grade finalization status for your department (and by
						extension, the release of SPOT results to your instructors), you can do so here.
					</p>

					<p>
						This is a decision that will affect your entire department, so please ensure that you
						are on the same page as your colleagues before proceeding.
					</p>
				</div>

				<DialogActions>
					<Button onClick={handleClose}>OK</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
