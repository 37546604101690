import React from "react";
import { Box, Button } from "@mui/material";
import ListSelect from "../../../../components/Utils/ListSelect";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";

const HeaderSection = ({
	terms,
	termValue,
	setTermValue,
	isAdmin,
	isTermHighest,
	departmentsList,
	selectedDept,
	handleDepartmentChange,
	handleRefresh,
	loading,
	unsavedChanges,
	dateFormat,
	setDateFormat,
	disabledUI,
}) => {
	const dateFormats = ["DD/MM/YY", "YYYY-MM-DD", "DD/MMM/YYYY"];
	return (
		<div className="flex items-center justify-between w-full ">
			<Box sx={{ display: "flex", gap: "10px" }}>
				<ListSelect
					disabled={unsavedChanges || disabledUI}
					className="termSelect"
					list={terms}
					label="Term"
					width="200px"
					size="small"
					marginBottom="0px"
					value={termValue || ""}
					onChange={(e) => setTermValue(e.target.value)}
				/>
				<ListSelect
					disabled={unsavedChanges || disabledUI}
					className="deptSelect"
					list={departmentsList}
					label="Department"
					width="350px"
					size="small"
					marginBottom="0px"
					value={selectedDept || ""}
					onChange={handleDepartmentChange}
				/>
				<ListSelect
					className="dateFormatSelect"
					list={dateFormats}
					label="Date Format"
					width="150px"
					size="small"
					marginBottom="0px"
					value={dateFormat || ""}
					onChange={(e) => setDateFormat(e.target.value)}
				/>
			</Box>
			<Box sx={{ p: 1 }}>
				<Button
					onClick={handleRefresh}
					disabled={unsavedChanges || disabledUI || (!isTermHighest && !isAdmin)}
					variant="outlined"
					sx={{ marginLeft: "5px" }}
				>
					REFRESH
					{loading ? (
						<CircularProgress size={20} sx={{ marginLeft: "9px" }} />
					) : (
						<RefreshIcon sx={{ marginLeft: "5px" }} />
					)}
				</Button>
			</Box>
		</div>
	);
};

export default HeaderSection;
