import {
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	FormHelperText,
	Typography,
} from "@mui/material/";

import React from "react";

export default function Assign2ResultsRadio(props) {
	return (
		<FormControl error={props.error}>
			<RadioGroup name={props.name} value={props.value || ""} onChange={props.onChange}>
				<FormControlLabel
					value="upload"
					control={<Radio />}
					label={
						<Typography variant="h7">Upload the scanned booklets to Assign2 for me</Typography>
					}
				/>
				<FormControlLabel
					value="doNotUpload"
					control={<Radio />}
					label={<Typography variant="h7">I would like to upload the booklets myself</Typography>}
				/>
			</RadioGroup>
			{props.helpText && props.helpText.length > 0 && (
				<span className="text-red-500 text-xs mt-1">{props.helpText}</span>
			)}
		</FormControl>
	);
}
