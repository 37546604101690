import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { importSpreadsheet } from "../../components/Api/Post";
import isAuthenticated from "../../components/Utils/isAuthenticated";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {
	getLDAPUserBySearchField,
	checkInstructorResultsEmail,
	sendInstructorResultsEmail,
	checkMidtermEmails,
	sendMidtermEmails,
	checkAllDepartmentsForResultsEmail,
} from "../../components/Api/Getters";
import { populateQPUsers } from "../../components/Api/Post";
import { CircularProgress } from "@mui/material";
import AutocompleteComponent from "../../components/Utils/AutocompleteComponent";
import { useDepartmentsQuery } from "../../Hooks/useDepartmentsQuery";
import ListSelect from "../../components/Utils/ListSelect";
import { useTermsQueryFull } from "../../Hooks/useTermsQuery";

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const tableOptions = [
	{
		name: "COURSE_STUDENT",
		value: "COURSE_STUDENT",
		type: "relationship",
	},
	{
		name: "COURSE_INSTRUCTOR",
		value: "COURSE_INSTRUCTOR",
		type: "relationship",
	},
];

const searchFields = [
	{
		name: "CCID",
		value: "uid",
	},
	{
		name: "EMPID",
		value: "employeeNumber",
	},
];

export default function AdminUtils() {
	const [value, setValue] = React.useState(0);
	const [csvData, setCsvData] = useState([]);
	const [headers, setHeaders] = useState([]);
	const [selectedOption, setSelectedOption] = useState("");
	const auth = isAuthenticated();
	const token = auth.token;
	const [loading, setLoading] = useState(false);
	const [importStatus, setImportStatus] = useState("");
	const [searchStatus, setSearchStatus] = useState("");
	const [populateQPLoading, setPopulateQPLoading] = useState(false);
	const [statusMessage, setStatusMessage] = useState("");
	const [searchFieldValue, setSearchFieldValue] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [instructorsForEmail, setInstructorsForEmail] = useState([]);
	const [loadingResultsEmails, setLoadingResultsEmails] = useState(false);
	const [resultsMessage, setResultsMessage] = useState("");
	const [deptId, setDeptId] = useState(null);
	const [midtermEmailInstructors, setMidtermEmailInstructor] = useState([]);
	const [loadingMidterm, setLoadingMidterm] = useState(false);
	const [midtermSent, setMidtermSent] = useState(false);
	const [allDepartmentsResults, setAllDepartmentsResults] = useState([]);
	const [termValue, setTermValue] = useState("");
	const [termNumber, setTermNumber] = useState(null);
	const [highestTermNumber, setHighestTermNumber] = useState(null);
	const [terms, setTerms] = useState([]);
	const { data: termsList, isFetching: termsLoading } =
		useTermsQueryFull(token);

	useEffect(() => {
		if (termsList && termsList.length > 0) {
			const termDescriptions = termsList.map((term) => term.desc);
			const termNumbers = termsList.map((term) => term.term);
			const terms = termNumbers.map((term, index) => {
				return termDescriptions[index] + " (" + term + ")";
			});
			setTerms(terms);

			const highestTermNumber = Math.max(...termNumbers);
			const highestTermIndex = termNumbers.indexOf(highestTermNumber);
			const highestTerm = terms[highestTermIndex];
			setHighestTermNumber(highestTermNumber);
			setTermValue(highestTerm);
		}
	}, [termsList]);

	useEffect(() => {
		if (
			termsList &&
			termsList.length > 0 &&
			termValue &&
			termValue.length > 0
		) {
			//get value in brackets of termValue
			const termNumber = termValue.match(/\(([^)]+)\)/)[1];
			setTermNumber(Number(termNumber));
		}
	}, [termsList, termValue]);

	useEffect(() => {
		if (termNumber) {
			const fetchData = async () => {
				await handleCheckDepartmentsForResultsEmail();
			};
			fetchData();
		}
	}, [termNumber]);

	const handlePopulateQP = async () => {
		try {
			setPopulateQPLoading(true);
			setStatusMessage("Populating QP Users, please wait...");
			const response = await populateQPUsers(token);
			if (response) {
				setPopulateQPLoading(false);
				setStatusMessage("QP Users populated successfully");
			}
		} catch (error) {
			setPopulateQPLoading(false);
			setStatusMessage("Error populating QP Users", error);
			console.error(error);
		}
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleFileUpload = (event) => {
		const file = event.target.files[0];

		if (file) {
			Papa.parse(file, {
				header: true,
				skipEmptyLines: true,
				complete: (result) => {
					// Extracting headers and data
					setHeaders(Object.keys(result.data[0] || {}));
					setCsvData(result.data);
				},
				error: (error) => {
					console.error("Error reading CSV file:", error);
				},
			});
		}
	};

	const handleSelectChange = (event) => {
		setSelectedOption(event.target.value);
	};

	const handleImport = async () => {
		const data = {
			table: selectedOption,
			data: csvData,
		};

		try {
			setLoading(true);
			setImportStatus("Importing spreadsheet, please wait...");
			const response = await importSpreadsheet(token, data);
			if (response.status === 200) {
				setLoading(false);
				setImportStatus("Spreadsheet imported successfully");
			}
		} catch (error) {
			setLoading(false);
			setImportStatus("Error importing spreadsheet", error);
			console.error(error);
		}
	};

	const handleSearchChange = (event) => {
		setSearchFieldValue(event.target.value);
	};

	const handleLDAPSearch = async () => {
		try {
			const response = await getLDAPUserBySearchField(
				token,
				searchFieldValue,
				searchValue
			);
			if (!response || response.length === 0) {
				// Handle the case where the response is empty/null
				setSearchStatus("User not found in LDAP.");
				return;
			}
			setSearchResults((prevSearchResults) => {
				const userExists = prevSearchResults.some(
					(user) => user.empid === response.empid
				);
				if (!userExists) {
					return [...prevSearchResults, response];
				}
				return prevSearchResults;
			});
		} catch (error) {
			setSearchStatus("Error searching for user");
			console.error("Search error:", error);
		}
	};

	const handleCheckInstructorResultsEmail = async (deptId, term) => {
		try {
			setResultsMessage("");
			const response = await checkInstructorResultsEmail(token, deptId, term);
			if (response.data_classes && response.data_classes.length > 0) {
				setResultsMessage("Ready to send.");
				setInstructorsForEmail(response.data_classes);
			} else {
				setResultsMessage(response.message);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleSendEmails = async () => {
		try {
			setLoadingResultsEmails(true);
			const response = await sendInstructorResultsEmail(
				token,
				deptId,
				termNumber,
				termValue.split(" ")[0].concat(" ").concat(termValue.split(" ")[1])
			);
			setResultsMessage(response.message);
			const response2 = await checkInstructorResultsEmail(
				token,
				deptId,
				termNumber
			);
			if (response2.data_classes && response2.data_classes.length > 0) {
				setInstructorsForEmail(response2.data_classes);
			} else {
				setInstructorsForEmail([]);
			}
			setLoadingResultsEmails(false);

			handleCheckDepartmentsForResultsEmail();
		} catch (error) {
			console.error(error);
			setResultsMessage("Error sending emails");
			setLoadingResultsEmails(false);
		}
	};

	const handleCheckMidtermEmails = async () => {
		try {
			const response = await checkMidtermEmails(token);
			if (response.instructors && response.instructors.length > 0) {
				setMidtermEmailInstructor(response.instructors);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleSendMidtermEmails = async () => {
		try {
			setLoadingMidterm(true);
			await sendMidtermEmails(token);
			setLoadingMidterm(false);
			setMidtermSent(true);
		} catch (error) {
			console.error(error);
			setLoadingMidterm(false);
		}
	};

	const handleCheckDepartmentsForResultsEmail = async () => {
		try {
			const response = await checkAllDepartmentsForResultsEmail(
				token,
				termNumber
			);
			if (response.data_classes && response.data_classes.length > 0) {
				setAllDepartmentsResults(response.data_classes);
			} else {
				setAllDepartmentsResults([]);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleChangeTerm = (event) => {
		const termNumber = event.target.value.match(/\(([^)]+)\)/)[1];
		if (Number(termNumber) >= 1890) {
			setTermValue(event.target.value);
			setResultsMessage("");
			setInstructorsForEmail([]);
		} else {
			alert("Terms earlier than 1890 are not supported.");
		}
	};

	return (
		<div className="w-full">
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					<Tab
						label="Email Instructor Results"
						{...a11yProps(0)}
					/>
					<Tab
						label="CSV Uploads"
						{...a11yProps(1)}
					/>
					<Tab
						label="LDAP Search"
						{...a11yProps(2)}
					/>
					<Tab
						label="Populate QP"
						{...a11yProps(3)}
					/>

					<Tab
						label="Midterm Email"
						{...a11yProps(4)}
					/>
				</Tabs>
			</Box>
			<CustomTabPanel
				value={value}
				index={0}
			>
				<div className="flex gap-4">
					{/* Left Sidebar: Term and Department List */}
					<div className="w-1/3">
						<div className="">
							<ListSelect
								className="termSelect"
								list={terms}
								label="Term"
								width="100%"
								value={termValue || ""}
								onChange={handleChangeTerm}
							/>
						</div>
						<div className="bg-gray-100 shadow rounded p-2">
							<h3 className="text-lg font-semibold mb-2">Departments</h3>
							<div className="max-h-80 overflow-y-auto gap-2 flex flex-col">
								{allDepartmentsResults && allDepartmentsResults.length > 0 ? (
									allDepartmentsResults.map((department) => (
										<div
											key={department.dept_id}
											className="flex justify-between p-2 rounded-xl bg-white hover:bg-gray-300  hover:cursor-pointer border-2 hover:border-blue-500"
											onClick={() => {
												setDeptId(department.dept_id);
												handleCheckInstructorResultsEmail(
													department.dept_id,
													termNumber
												);
											}}
										>
											<span>{department.dept_id__display_name}</span>
											<span>{department.unique_empid_count}</span>
										</div>
									))
								) : (
									<p>No departments available</p>
								)}
							</div>
						</div>
					</div>

					{/* Main Panel: Instructors and Email Actions */}
					<div className="w-2/3">
						{instructorsForEmail && instructorsForEmail.length > 0 ? (
							<div className="bg-gray-100 shadow rounded p-2">
								<h3 className="text-lg font-semibold mb-2">
									Instructors for Notification
								</h3>
								<div className="max-h-80 overflow-y-auto">
									{instructorsForEmail.map((instructor) => (
										<div
											key={instructor.empid}
											className="flex justify-between p-2 border-b bg-white rounded-xl"
										>
											<span
												title={instructor.classes
													.map((item) => item.classid)
													.join(",")}
											>
												{instructor.classes.length} Classes
											</span>
											<span>{instructor.email}</span>
										</div>
									))}
								</div>
								<button
									onClick={handleSendEmails}
									disabled={
										loadingResultsEmails || instructorsForEmail.length === 0
									}
									className="mt-4 w-full py-2 bg-green-700 border-2 border-green-800 rounded hover:bg-green-900 text-white disabled:bg-gray-400 disabled:border-gray-500"
								>
									{loadingResultsEmails ? (
										<CircularProgress size={20} />
									) : (
										`Send Emails to ${instructorsForEmail.length} Instructors`
									)}
								</button>
								{resultsMessage && (
									<p className="mt-2 p-2 bg-white border border-gray-400 rounded">
										<strong>Message:</strong> {resultsMessage}
									</p>
								)}
							</div>
						) : (
							<div className="bg-white border-gray-200 border-2 shadow rounded p-2">
								<p>Please select a department to see instructors.</p>
							</div>
						)}
					</div>
				</div>
			</CustomTabPanel>
			<CustomTabPanel
				value={value}
				index={1}
			>
				<div className="w-full flex flex-col items-center justify-center mt-8 pb-10">
					<input
						className="border-2 rounded-md px-4 py-2 bg-gray-100 text-black border-green-800 mb-4"
						type="file"
						accept=".csv"
						onChange={handleFileUpload}
					/>

					<select
						value={selectedOption}
						onChange={handleSelectChange}
						className="mb-4 px-4 py-2 border border-gray-400 rounded-md text-gray-700"
					>
						<option
							value=""
							disabled
						>
							Select Table Option
						</option>
						{tableOptions.map((option) => (
							<option
								key={option.value}
								value={option.value}
							>
								{option.name}
							</option>
						))}
					</select>

					{selectedOption && (
						<button
							onClick={handleImport}
							disabled={loading || csvData.length === 0}
							className={`${
								loading || csvData.length === 0
									? " bg-gray-500 text-black border-gray-800 hover:cursor-not-allowed"
									: "bg-green-700 hover:bg-green-800 text-white border-green-800"
							}border-2 rounded-md px-4 py-2   `}
						>
							Import {csvData.length} rows Into {selectedOption}
						</button>
					)}

					{headers.length > 0 && csvData.length > 0 && (
						<div className="mt-4 max-h-[60dvh] overflow-y-auto w-[50dvw] ">
							<table className="table-auto border-collapse border border-gray-400 w-full">
								<thead>
									<tr>
										{headers.map((header, index) => (
											<th
												key={index}
												className="border border-gray-400 px-4 py-2"
											>
												{header}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{csvData.map((row, rowIndex) => (
										<tr key={rowIndex}>
											{headers.map((header, index) => (
												<td
													key={index}
													className="border border-gray-400 px-4 py-2"
												>
													{row[header]}
												</td>
											))}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</CustomTabPanel>
			<CustomTabPanel
				value={value}
				index={2}
			>
				<div className="w-full flex flex-col items-center justify-center mt-8 pb-10">
					<select
						className="mb-4 px-4 py-2 border border-gray-400 rounded-md text-gray-700"
						onChange={handleSearchChange}
						value={searchFieldValue}
					>
						<option
							value=""
							disabled
						>
							Select Search Field
						</option>
						{searchFields.map((option) => (
							<option
								key={option.value}
								value={option.value}
							>
								{option.name}
							</option>
						))}
					</select>
					<input
						className="border-2 rounded-md px-4 py-2 bg-gray-100 text-black border-green-800 mb-4"
						type="text"
						onChange={(e) => setSearchValue(e.target.value)}
						placeholder="Search Value"
					/>
					<button
						disabled={!searchFieldValue || !searchValue}
						onClick={handleLDAPSearch}
						className={`disabled:bg-gray-400 disabled:border-gray-500 bg-green-700 hover:bg-green-800 text-white border-green-800 border-2 rounded-md px-4 py-2`}
					>
						Search
					</button>
					{searchStatus && <div className="text-red-500">{searchStatus}</div>}
					<div className="mt-4 max-h-[60dvh] overflow-y-auto w-[50dvw] ">
						<table className="table-auto border-collapse border border-gray-400 w-full">
							<thead>
								<tr>
									<th className="border border-gray-400 px-4 py-2">CCID</th>
									<th className="border border-gray-400 px-4 py-2">EMPID</th>
									<th className="border border-gray-400 px-4 py-2">
										First Name
									</th>
									<th className="border border-gray-400 px-4 py-2">
										Last Name
									</th>
									<th className="border border-gray-400 px-4 py-2">Email</th>
								</tr>
							</thead>
							<tbody>
								{searchResults &&
									searchResults.map((row, rowIndex) => (
										<tr key={rowIndex}>
											<td className="border border-gray-400 px-4 py-2">
												{row.ccid}
											</td>
											<td className="border border-gray-400 px-4 py-2">
												{row.empid}
											</td>
											<td className="border border-gray-400 px-4 py-2">
												{row.firstName}
											</td>
											<td className="border border-gray-400 px-4 py-2">
												{row.lastName}
											</td>
											<td className="border border-gray-400 px-4 py-2">
												{row.email}
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>
			</CustomTabPanel>
			<CustomTabPanel
				value={value}
				index={3}
			>
				<button
					onClick={handlePopulateQP}
					className="px-4 py-2 bg-gray-200 border-gray-400 border-2 rounded-xl hover:bg-gray-600 hover:border-gray-800 hover:text-white"
				>
					Populate QP Users
				</button>
				{populateQPLoading && <div>{statusMessage}</div>}
			</CustomTabPanel>
			<CustomTabPanel
				value={value}
				index={4}
			>
				<div className="flex gap-2 mb-2">
					<button
						onClick={handleCheckMidtermEmails}
						className={`px-4 py-2 bg-green-700 border-green-800 border-2 rounded-xl hover:bg-green-900 hover:border-gray-800 text-white mb-2`}
					>
						Check Midterm Emails (DO NOT RUN NEXT TERM UNTIL API CALL FIGURED
						OUT)
					</button>
					{}
					{midtermEmailInstructors.length > 0 && (
						<button
							//onClick={handleSendMidtermEmails}
							//disabled={loadingMidterm || midtermSent}
							disabled
							className={`${
								midtermSent &&
								"disabled:bg-gray-400  disabled:border-gray-800 hover:cursor-not-allowed"
							} px-4 ml-2 py-2 bg-green-700 border-green-800 border-2 rounded-xl hover:bg-green-900 hover:border-gray-800 text-white mb-2 `}
						>
							{loadingMidterm ? (
								<CircularProgress size={20} />
							) : (
								"Send Midterm Emails to " +
								midtermEmailInstructors.length +
								" Instructors"
							)}
						</button>
					)}
				</div>

				<p className="px-4 py-2 bg-gray-200 border-gray-400 border-2 rounded-xl mb-2">
					Total Instructors: {midtermEmailInstructors.length}
				</p>
				<div className="flex flex-col gap-1 w-[300px] bg-gray-300 rounded-xl p-2">
					<div className="flex justify-between ">
						<div className=" font-medium">Classes</div>
						<div className=" font-medium">Email</div>
					</div>
					<div className="h-[1px] w-full bg-gray-900"></div>
					{midtermEmailInstructors.map((instructor) => (
						<div
							key={instructor.empid}
							className="flex justify-between"
						>
							<div className="">{instructor.name}</div>
							<div className="">{instructor.ccid}@ualberta.ca</div>
						</div>
					))}
				</div>
			</CustomTabPanel>
		</div>
	);
}
