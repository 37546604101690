import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { DialogContentText, Typography } from "@mui/material";
import { getLDAPUserByEmpId } from "../Api/Getters";
import isAuthenticated from "./isAuthenticated";
import CircularProgress from "@mui/material/CircularProgress";

const filter = createFilterOptions();

export default function FacultyRankAutocomplete(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const [options, setOptions] = React.useState([]);
	const [value, setValue] = React.useState(null);
	const [openDialog, setOpenDialog] = React.useState(false);
	const [inputValue, setInputValue] = useState("");
	const [openSearch, setOpenSearch] = useState(false);
	const [checked, setChecked] = React.useState(false);
	const [readyToAdd, setReadyToAdd] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		if (props.options && props.options.length > 0 && props.currentRow) {
			const roles = props.currentRow.roles;
			const unique = [...new Set(props.options)];
			unique.forEach((element, index) => {
				const newElement = {
					key: index,
					empid: element.empid,
					name: element.name,
					ccid: element.ccid,
					rank: element.rank,
				};
				unique[index] = newElement;
			});
			if (roles && roles.length > 0) {
				const existingRolesList = [];
				roles.forEach((role) => {
					const index = unique.findIndex(
						(element) => element.empid === role.empid
					);
					const existingRole = unique[index];
					if (index > -1) {
						existingRole.key = index;
						existingRole.role = role.class_role;
						existingRolesList.push(existingRole);
						unique.splice(index, 1);
					}
					props.setExistingRoles(existingRolesList);
				});
			}
			setOptions(unique);
		}
	}, [props.options, props.currentRow]);

	const [dialogValue, setDialogValue] = React.useState({
		firstName: "",
		lastName: "",
		empid: "",
		ccid: "",
		role: "",
	});

	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	async function checkExistingFacultyRank() {
		const existingFacultyRank = props.checkExistingFacultyRank(
			dialogValue.empid
		);
		if (existingFacultyRank !== false) {
			setReadyToAdd(false);
			setErrorMessage(
				"Faculty member with this employee ID already exists in our database."
			);
		} else {
			getLDAPUserByEmpId(token, dialogValue.empid).then((res) => {
				if (res) {
					if (
						res.first_name &&
						res.last_name &&
						res.ccid &&
						res.first_name.length > 0 &&
						res.last_name.length > 0 &&
						res.ccid.length > 0
					) {
						setSearchedLDAP(true);
						setDialogValue({
							...dialogValue,
							firstName: res.first_name,
							lastName: res.last_name,
							ccid: res.ccid,
						});

						setReadyToAdd(true);
						setChecked(true);
						setErrorMessage("");
						setSuccessMessage("Ready to add instructor.");
					} else {
						setErrorMessage("Employee ID not found in LDAP.");
					}
				} else {
					setErrorMessage("Employee ID not found in LDAP.");
				}
			});

			//success
		}
	}

	const handleChangeAddInstructor = (fieldName) => (event) => {
		setReadyToAdd(false);
		setChecked(false);
		setErrorMessage("");
		setSuccessMessage("");
		if (fieldName === "empid") {
			setSearchedLDAP(false);
		}
		setDialogValue({
			...dialogValue,
			[fieldName]: event.target.value,
		});
	};

	const handleSubmit = async () => {
		setChecked(false);
		setErrorMessage("");
		setReadyToAdd(false);
		setSuccessMessage("");
		setInputValue("");
		handleClose();
		/* setOpenSearch(false); */
	};

	const handleAddInstructorToFacultyRank = async () => {
		setLoading(true);
		const newInstructor = {
			name: dialogValue.lastName.trim() + ", " + dialogValue.firstName.trim(),
			empid: dialogValue.empid,
			ccid: dialogValue.ccid,
			rank: "INSTRUCTOR",
		};
		const success = await props.addInstructorToFacultyRank(newInstructor);
		if (success) {
			await handleSubmit();
			setLoading(false);
		} else {
			setSuccessMessage("");
			setLoading(false);
		}
	};

	const handleClose = () => {
		setSearchedLDAP(false);
		setChecked(false);
		setErrorMessage("");
		setSuccessMessage("");
		setDialogValue({
			firstName: "",
			lastName: "",
			empid: "",
			ccid: "",
			role: "",
		});
		setOpenDialog(false);
	};

	const [searchedLDAP, setSearchedLDAP] = useState(false);

	const handleClearInputs = () => {
		setInputValue("");
		setValue(null);
	};

	return (
		<React.Fragment>
			<Autocomplete
				value={value}
				onChange={(event, newValue) => {
					if (typeof newValue === "string") {
						// timeout to avoid instant validation of the dialog's form.
						setTimeout(() => {
							setOpenSearch(false);
							setOpenDialog(true);
							setDialogValue({
								name: newValue,
								empid: "",
							});
						});
					} else if (newValue && newValue.inputValue) {
						setOpenSearch(false);
						setOpenDialog(true);
						setDialogValue({
							name: newValue.inputValue,
							empid: "",
						});
					} else {
						setValue(newValue);
						setOpenSearch(false);
						props.setNewRoleValue(newValue);
					}
				}}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
					setOpenSearch(newInputValue.length > 2);
				}}
				filterOptions={(options, params) => {
					const filtered = filter(options, params);

					if (params.inputValue !== "") {
						filtered.push({
							inputValue: params.inputValue,
							name: `Add "${params.inputValue}" as a new instructor?`,
						});
					}

					return filtered;
				}}
				id="free-solo-dialog-demo"
				options={options}
				getOptionLabel={(option) => {
					if (typeof option === "string") {
						return option;
					}
					if (option.inputValue) {
						return option.inputValue;
					}
					// Convert empid to a 7-digit string
					const paddedEmpid = String(option.empid).padStart(7, "0");
					return `${option.name} - ${paddedEmpid} - ${option.ccid}`;
				}}
				disableClearable
				filterSelectedOptions
				selectOnFocus
				clearOnBlur
				renderOption={(props, option) => {
					const paddedEmpid = String(option.empid).padStart(7, "0");
					return (
						<li {...props}>
							{option.name} - {paddedEmpid} - {option.ccid}
						</li>
					);
				}}
				sx={{ width: "100%" }}
				freeSolo
				open={openSearch}
				renderInput={(params) => (
					<div className="relative">
						<TextField
							{...params}
							label="Enter Name, Employee ID, or CCID"
						/>
						{inputValue.length > 0 && (
							<button
								onClick={handleClearInputs}
								className="absolute right-4 px-2 top-3  bg-white rounded-full border-2 border-transparent hover:bg-gray-200"
							>
								x
							</button>
						)}
					</div>
				)}
			/>
			<Dialog
				open={openDialog}
				onClose={handleClose}
				maxWidth="lg"
			>
				<form>
					<DialogTitle>Add New Instructor</DialogTitle>
					<DialogContent
						sx={{
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
							gap: "10px",
						}}
					>
						<DialogContentText>
							{" "}
							NOTE: This form will NOT add this instructor to the course. After
							adding the instructor to our database through this form, you will
							need to continue on in the previous step and add them to the
							course.{" "}
						</DialogContentText>
						<TextField
							required
							margin="dense"
							id="empid"
							value={dialogValue.empid || ""}
							onChange={handleChangeAddInstructor("empid")}
							label="Employee ID"
							variant="standard"
						/>
						{searchedLDAP && (
							<>
								<TextField
									autoFocus
									required
									margin="dense"
									id="lastName"
									value={dialogValue.lastName || ""}
									onChange={handleChangeAddInstructor("lastName")}
									label="Last Name"
									type="text"
									variant="standard"
								/>
								<TextField
									required
									margin="dense"
									id="firstName"
									value={dialogValue.firstName || ""}
									onChange={handleChangeAddInstructor("firstName")}
									label="First Name"
									type="text"
									variant="standard"
								/>

								<TextField
									required
									margin="dense"
									id="ccid"
									disabled
									value={dialogValue.ccid || ""}
									/* onChange={handleChangeAddInstructor("ccid")} */
									label="CCID"
									type="text"
									variant="standard"
								/>
							</>
						)}
					</DialogContent>
					<DialogActions
						sx={{ display: "flex", justifyContent: "flex-start", pl: 2 }}
					>
						{checked ? (
							readyToAdd ? (
								<Button
									disabled={
										(dialogValue.lastName &&
											dialogValue.lastName.length === 0) ||
										(dialogValue.firstName &&
											dialogValue.firstName.length === 0) ||
										(dialogValue.empid && dialogValue.empid.length === 0) ||
										(dialogValue.ccid && dialogValue.ccid.length === 0) ||
										loading
									}
									onClick={handleAddInstructorToFacultyRank}
								>
									{" "}
									{loading ? <CircularProgress size={20} /> : "Add"}
								</Button>
							) : (
								<Button disabled>Add</Button>
							)
						) : (
							<Button
								variant="outlined"
								disabled={!dialogValue.empid || loading}
								onClick={checkExistingFacultyRank}
							>
								Check
							</Button>
						)}
						<Button onClick={handleClose}>Cancel</Button>
						<Typography sx={{ color: "green" }}>{successMessage}</Typography>
						<Typography sx={{ color: "red" }}>{errorMessage}</Typography>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	);
}
