import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { toggleBulkGradesSubmitted } from "../../../components/Api/Updates";

export default function ConfirmGradesSubmittedDialog(props) {
	const handleClose = () => {
		props.setOpen(false);
	};

	const handleConfirmGradesSubmitted = async () => {
		const response = await toggleBulkGradesSubmitted(props.token, props.selected, props.operation);
		console.log(response);

		if (response.status === 200) {
			props.setSelected([]);
			props.refreshData();
			props.setOpen(false);
		}
	};
	return (
		<div>
			<Dialog open={props.open} onClose={handleClose} maxWidth="2xl">
				<DialogTitle textAlign={"center"}>Confirm Submission</DialogTitle>

				<div className="flex flex-col gap-5 max-w-3xl p-4 ">
					<p className="text-center">
						You have selected{" "}
						{props.selected.length > 0 &&
							(props.selected.length >= 1 ? `${props.selected.length} classes` : "1 class")}{" "}
						to confirm grades finalized.
					</p>
					<div className="grid grid-cols-1 max-h-[300px] overflow-y-auto text-left bg-gray-100 p-1 rounded-md divide-y divide-gray-300">
						{props.chosenClasses.map((chosenClass) => (
							<p key={chosenClass.cls_uqid}>
								{chosenClass.ctitle} - {chosenClass.name}
							</p>
						))}
					</div>
					<p>
						If you proceed, all selected classes will be confirmed as having grades finalized and
						instructors will be sent their survey results at TSQS's earliest notice.
					</p>
					<p>
						If a class is finalized by mistake, please reverse the action and contact the TSQS team
						immediately to ensure we do not send out the results.
					</p>
					<p sx={{ maxWidth: "600px", color: "black" }}>
						If you are sure you want to proceed, click on "Finalize".
					</p>
				</div>

				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button
						onClick={handleConfirmGradesSubmitted}
						autoFocus
						variant="contained"
						color="primary"
					>
						Finalize
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
