import { useEffect, useState } from "react";
import { listOfAnswerOptions, yesOrNo, listOfKeys } from "../utils/common";
import { setDate, getInitials } from "../utils/helpers";
import axios from "axios";
import ClassDropDown from "../components/ClassDropDown";
import { getReqNum } from "../utils/api";
import { postRFSData } from "../../../components/Api/Post";

export default function MRScor(props) {
	const {
		navigate,
		state,
		setState,
		token,
		ccid,
		classNumList,
		handlePrint,
		instructorClasses,
	} = props;

	const [yesOrNoHelpText, setYesOrNoHelpText] = useState(" ");
	const [weightingHelpText, setWeightingHelpText] = useState(" ");

	const [notesHelpText, setNotesHelpText] = useState(" ");
	const [classNumHelpText, setClassNumHelpText] = useState(" ");
	const [numQuestionsHelpText, setNumQuestionsHelpText] = useState(" ");
	const [multiple, setMultiple] = useState(false);
	const [finalNotes, setFinalNotes] = useState("");

	const [errorState, setErrorState] = useState({
		lqNum: false,
		hqNum: false,
		qLen: false,
		yesOrNo: false,
		weightingSingle: false,
		weightingMultiple: false,
		schema: false,
		notes: false,
		classNum: false,
		examTotalMarks: false,
	});

	useEffect(() => {
		//set state.numMultipleResponse and state.numSingleResponse based on selection
		if (state.yesOrNo === "No" && state.multipleResponseQuestions) {
			setState((prev) => ({
				...prev,
				numMultipleResponse: state.multipleResponseQuestions.length,
				numSingleResponse:
					Number(state.hqNum) -
					Number(state.lqNum) +
					1 -
					state.multipleResponseQuestions.length,
			}));
		} else if (state.yesOrNo === "Yes") {
			setState((prev) => ({
				...prev,
				numMultipleResponse: Number(state.hqNum) - Number(state.lqNum) + 1,
				numSingleResponse: 0,
			}));
		}
	}, [
		state.yesOrNo,
		state.multipleResponseQuestions,
		state.lqNum,
		state.hqNum,
	]);
	const handleChangeClassDropdown = (event) => {
		const isMultiple = event.target.checked;
		setMultiple(isMultiple);
		// If multiple is false, use an empty string; if true, use an empty array.
		setState({ ...state, valueClassNum: isMultiple ? [] : "" });
	};
	// -------------------------------
	// TOTAL MARKS LOGIC (CLEANED UP)
	// -------------------------------
	useEffect(() => {
		// Only update total marks if all required values exist.
		if (state.lqNum && state.hqNum && state.qLen && state.yesOrNo) {
			const lq = Number(state.lqNum);
			const hq = Number(state.hqNum);
			const totalQuestions = hq - lq + 1;
			let totalMarks = 0;

			if (state.yesOrNo === "Yes") {
				// Entire exam is multiple response: use the single-response weighting for all questions.
				if (state.weightingMultiple) {
					totalMarks = totalQuestions * Number(state.weightingMultiple);
				}
			} else if (state.yesOrNo === "No") {
				// Mixed exam: use weighting for multiple response and single response.
				// Ensure we have numeric values for numMultipleResponse and numSingleResponse.
				const numMR = state.numMultipleResponse
					? Number(state.numMultipleResponse)
					: 0;
				// If numSingleResponse isn’t set, assume the rest are single response.
				const numSingle = state.numSingleResponse
					? Number(state.numSingleResponse)
					: totalQuestions - numMR;
				if (state.weightingMultiple && state.weightingSingle) {
					totalMarks =
						numMR * Number(state.weightingMultiple) +
						numSingle * Number(state.weightingSingle);
				}
			}

			setState((prev) => ({ ...prev, examTotalMarks: totalMarks }));
		}
	}, [
		state.lqNum,
		state.hqNum,
		state.qLen,
		state.weightingSingle,
		state.weightingMultiple,
		state.yesOrNo,
		state.numMultipleResponse,
		state.numSingleResponse,
		state.multipleResponseQuestions,
	]);

	// -------------------------------
	// GRID LOGIC (for multiple response question selection)
	// -------------------------------
	// Toggles a single question (button) on or off.
	const toggleMRQuestion = (questionNumber) => {
		setState((prev) => {
			const current = prev.multipleResponseQuestions || [];
			if (current.includes(questionNumber)) {
				return {
					...prev,
					multipleResponseQuestions: current.filter(
						(q) => q !== questionNumber
					),
				};
			} else {
				return {
					...prev,
					multipleResponseQuestions: [...current, questionNumber],
				};
			}
		});
	};

	// Fixed number of columns for the grid.
	const columns = 10;
	// Toggle an entire row.
	const toggleRow = (rowIndex) => {
		const rowQuestions = [];
		const start = Number(state.lqNum) + rowIndex * columns;
		for (let i = 0; i < columns; i++) {
			const qNum = start + i;
			if (qNum <= Number(state.hqNum)) {
				rowQuestions.push(qNum);
			}
		}
		setState((prev) => {
			const current = prev.multipleResponseQuestions || [];
			const allSelected = rowQuestions.every((q) => current.includes(q));
			const newSelections = allSelected
				? current.filter((q) => !rowQuestions.includes(q))
				: Array.from(new Set([...current, ...rowQuestions]));
			return { ...prev, multipleResponseQuestions: newSelections };
		});
	};

	// Toggle all questions in the grid.
	const toggleAll = () => {
		setState((prev) => {
			const numQuestions =
				state.lqNum && state.hqNum
					? Number(state.hqNum) - Number(state.lqNum) + 1
					: 0;
			if (numQuestions > 0) {
				const allQuestions = [];
				for (let i = 0; i < numQuestions; i++) {
					allQuestions.push(Number(state.lqNum) + i);
				}
				const current = prev.multipleResponseQuestions || [];
				const allSelected = allQuestions.every((q) => current.includes(q));
				return {
					...prev,
					multipleResponseQuestions: allSelected ? [] : allQuestions,
				};
			}
			return prev;
		});
	};

	// Reset (clear) all toggles.
	const resetToggles = () => {
		setState((prev) => ({ ...prev, multipleResponseQuestions: [] }));
	};

	// -------------------------------
	// HANDLERS FOR INPUT CHANGES & SUBMISSION
	// -------------------------------
	const handleMrScorChange = (event) => {
		const { name, value } = event.target;
		setState((prev) => ({ ...prev, [name]: value }));

		if (name === "yesOrNo") {
			setErrorState((prev) => ({ ...prev, yesOrNo: false }));
		}
		if (name === "weightingSingle") {
			setErrorState((prev) => ({ ...prev, weightingSingle: false }));
		}
		if (name === "weightingMultiple") {
			setErrorState((prev) => ({ ...prev, weightingMultiple: false }));
		}
		if (name === "schema") {
			setErrorState((prev) => ({ ...prev, schema: false }));
		}

		if (name === "lqNum") {
			setErrorState((prev) => ({ ...prev, lqNum: false }));
		}

		if (name === "hqNum") {
			setErrorState((prev) => ({ ...prev, hqNum: false }));
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setState((prev) => ({ ...prev, [name]: value }));
		if (name === "notes") {
			setErrorState((prev) => ({ ...prev, notes: false }));
			setNotesHelpText(" ");
		}
	};

	const handleSubmit = () => {
		if (state.lqNum === "") {
			setNumQuestionsHelpText(
				"Please enter a value for lowest question number"
			);
			setErrorState((prev) => ({ ...prev, lqNum: true }));
		}
		if (state.hqNum === "") {
			setNumQuestionsHelpText(
				"Please enter a value for highest question number"
			);
			setErrorState((prev) => ({ ...prev, hqNum: true }));
		}
		if (state.yesOrNo === "") {
			setYesOrNoHelpText(
				"Please indicate whether the entire exam is multiple response"
			);
			setErrorState((prev) => ({ ...prev, yesOrNo: true }));
		}
		if (state.weightingMultiple === "") {
			setWeightingHelpText(
				"Please select a weight for multiple response questions"
			);
			setErrorState((prev) => ({ ...prev, weightingMultiple: true }));
		}
		if (state.weightingSingle === "" && state.yesOrNo === "No") {
			setWeightingHelpText(
				"Please select a weight for single response questions"
			);
			setErrorState((prev) => ({ ...prev, weightingSingle: true }));
		}
		if (state.schema === "") {
			setErrorState((prev) => ({ ...prev, schema: true }));
		}
		if (state.valueClassNum.length === 0 || !state.valueClassNum) {
			setErrorState((prev) => ({ ...prev, classNum: true }));
			setClassNumHelpText("Please select a class number");
		} else if (
			Number(state.lqNum) >= 1 &&
			Number(state.hqNum) <= 250 &&
			state.instructorName.length > 4 &&
			state.deptID !== "" &&
			state.email1 !== "" &&
			state.address !== "" &&
			(state.valueClassNum > 0 || state.valueClassNum.length > 0) &&
			state.examTotalMarks > 0 &&
			state.schema !== "" &&
			state.weightingMultiple !== ""
		) {
			postData()
				.then(async (response) => {
					let reqnum = response.reqnum;
					await handlePrint(reqnum);
					navigate("/success");
				})
				.catch((error) => {
					console.log("error", error);
					alert(
						"There was an error submitting your request, please try again. If the problem persists, please contact TSQS for assistance."
					);
				});
		} else {
			alert("Please fill out all required fields.");
		}
	};

	const addLeadingZerosQuestions = (numQuestions) => {
		let numQuestionsString = numQuestions.toString();

		if (numQuestionsString.length === 1) {
			numQuestionsString = "00".concat(numQuestionsString);
		} else if (numQuestionsString.length === 2) {
			numQuestionsString = "0".concat(numQuestionsString);
		}
		return numQuestionsString;
	};

	const addLeadingZerosAnswers = (numAnswers) => {
		let numAnswersString = numAnswers.toString();
		if (numAnswersString.length === 1) {
			numAnswersString = "0".concat(numAnswersString);
		}
		return numAnswersString;
	};

	const gradingSchemeOptions = ["Rights Minus Wrongs", "All or None", "Other"];

	console.log(finalNotes);

	useEffect(() => {
		//turn array of state.multipleresponsequestions into a string
		let notes = state.notes;
		let additionalNotes = `MRSCOR: ${state.examTotalMarks} total marks. 
		Weights: ${state.weightingSingle && `${state.weightingSingle} SR,`} ${
			state.weightingMultiple
		} MR.
		${
			state.yesOrNo !== "Yes"
				? state.multipleResponseQuestions.join(",")
				: "All questions"
		} are MR.
		Grade scheme: ${state.schema}
		Schema Notes: ${state.schemaNotes}
		Additional Notes: ${notes}`;

		const flatAdditionalNotes = additionalNotes.replace(/\s+/g, " ").trim();
		setFinalNotes(flatAdditionalNotes);
	}, [
		state.notes,
		state.valueNumKeys,
		state.compKey1Weight,
		state.compKey2Weight,
		state.compKey3Weight,
		state.compKey4Weight,
		state.compKey5Weight,
		state.schemaNotes,
		state.weightingSingle,
		state.weightingMultiple,
		state.examTotalMarks,
		state.multipleResponseQuestions,
		state.schema,
		state.yesOrNo,
	]);

	const getClassTitle = (classNum) => {
		let classTitle = "";
		classNumList.forEach((classItem) => {
			if (classItem.value === classNum) {
				classTitle = classItem.label;
			}
		});
		let splitTitle = classTitle.split(" ");
		if (splitTitle.length > 4) {
			splitTitle[0] = splitTitle[0].concat(splitTitle[1]);
			splitTitle.splice(1, 1);
			classTitle = splitTitle.join(" ");
		} else {
			classTitle = splitTitle.join(" ");
		}
		setState({
			...state,
			courseTitle: classTitle,
		});
		return classTitle;
	};

	useEffect(() => {
		//calculate NOP values as 1/3 of total marks
		if (
			state.hqNum &&
			state.lqNum &&
			state.lqNum !== "" &&
			state.hqNum !== ""
		) {
			let NOP = Math.floor((Number(state.hqNum) - Number(state.lqNum) + 1) / 3);

			let formattedNOP = addLeadingZerosQuestions(NOP.toString());
			setState({
				...state,
				n: formattedNOP.split("")[0],
				o: formattedNOP.split("")[1],
				p: formattedNOP.split("")[2],
			});
		}
	}, [state.hqNum, state.lqNum]);

	const postData = async (pulledReqnum) => {
		let hqNum = addLeadingZerosQuestions(String(state.hqNum));
		let lqNum = addLeadingZerosQuestions(String(state.lqNum));
		let numAnswers = addLeadingZerosAnswers(state.qLen);

		let classTitle = classNumList.find(
			(item) =>
				item.value === state.valueClassNum[0] ||
				item.value === state.valueClassNum
		).label;

		const data = {
			reqnum: pulledReqnum,
			userid: ccid,
			caddr1: state.instructorName,
			accountnum: 502306,
			coursetitle: classTitle.substring(0, 16),
			dept: state.deptID,
			program: "2",
			caddr2: state.department
				.trim()
				.replace(/ *\([^)]*\) */g, "")
				.substring(0, 32),
			caddr3: state.address,
			rqdate: setDate(),
			lqnum: lqNum,
			hqnum: hqNum,
			qlen: numAnswers,
			nkeys: "1",
			a: "3",
			b: "1",
			c: "1",
			d: "1",
			e: "1",
			f: "0",
			k: "7",
			l: "0",
			m: "5",
			n: state.n,
			o: state.o,
			p: state.p,
			pocketflag: "1",
			initials: getInitials(state.instructorName),
			instructor_name: state.instructorName,
			email1: state.email1,
			email2: state.email2,
			phone: state.phone,
			speedcode: 84311,
			numopen: state.numOpen,
			docsize: state.docSize,
			notes: finalNotes,
			status: "Received",
			classNum: Array.isArray(state.valueClassNum)
				? state.valueClassNum.join(",")
				: String(state.valueClassNum),
		};

		const response = await postRFSData(token, data);

		return response;
	};

	const postDataProcesses = (pulledReqnum) => {
		axios({
			headers: {
				Authorization: `Token ${token}`,
			},
			method: "post",
			url: process.env.REACT_APP_BACKEND_HOST.concat(
				"/scanner/processes/".concat(pulledReqnum).concat("/")
			),
			data: {
				reqnum: pulledReqnum,
				reportnum: "1",
				reporttype: "Itemanal",
				parameters: "",
				filename: "",
			},
		})
			.catch(function (error) {
				if (error.response) {
					console.log("data", error.response.data);
				}
			})
			.then(
				axios({
					headers: {
						Authorization: `Token ${token}`,
					},
					method: "post",
					url: process.env.REACT_APP_BACKEND_HOST.concat(
						"/scanner/processes/".concat(pulledReqnum).concat("/")
					),
					data: {
						reqnum: pulledReqnum,
						reportnum: "2",
						reporttype: "Examtab",
						parameters: "",
						filename: "",
					},
				}).catch(function (error) {
					if (error.response) {
						console.log("data", error.response.data);
					}
				})
			)
			.then(
				axios({
					headers: {
						Authorization: `Token ${token}`,
					},
					method: "post",
					url: process.env.REACT_APP_BACKEND_HOST.concat(
						"/scanner/processes/".concat(pulledReqnum).concat("/")
					),
					data: {
						reqnum: pulledReqnum,
						reportnum: "3",
						reporttype: "Wrongs",
						parameters: "",
						filename: "",
					},
				}).catch(function (error) {
					if (error.response) {
						console.log("data", error.response.data);
					}
				})
			)
			.then(
				axios({
					headers: {
						Authorization: `Token ${token}`,
					},
					method: "post",
					url: process.env.REACT_APP_BACKEND_HOST.concat(
						"/scanner/processes/".concat(pulledReqnum).concat("/")
					),
					data: {
						reqnum: pulledReqnum,
						reportnum: "4",
						reporttype: "eClass",
						parameters: "W,SR,".concat(state.valueClassNum),
						filename: "",
					},
				}).catch(function (error) {
					if (error.response) {
						console.log("data", error.response.data);
					}
				})
			)
			.then(
				axios({
					headers: {
						Authorization: `Token ${token}`,
					},
					method: "post",
					url: process.env.REACT_APP_BACKEND_HOST.concat(
						"/scanner/processes/".concat(pulledReqnum).concat("/")
					),
					data: {
						reqnum: pulledReqnum,
						reportnum: "5",
						reporttype: "ClassList",
						parameters: "sn",
						filename: "",
					},
				}).catch(function (error) {
					if (error.response) {
						console.log("data", error.response.data);
					}
				})
			)
			.then(navigate("/success"));
	};

	// -------------------------------
	// CALCULATIONS FOR THE GRID LAYOUT
	// -------------------------------
	const numQuestions =
		state.lqNum && state.hqNum
			? Number(state.hqNum) - Number(state.lqNum) + 1
			: 0;
	const numRows = Math.ceil(numQuestions / columns);

	return (
		<div className="grid grid-cols-2 gap-4 w-full">
			{/* Lowest question number */}
			<div className="flex flex-col  col-span-2">
				<label
					htmlFor="lqNum"
					className="font-medium text-primary"
				>
					Lowest question number on the exam?*
				</label>
				<input
					type="number"
					id="lqNum"
					name="lqNum"
					value={state.lqNum || ""}
					onChange={handleMrScorChange}
					className={`mt-1 p-2 border rounded ${
						errorState.lqNum ? "border-red-500" : "border-gray-300"
					}`}
				/>
				{errorState.lqNum && (
					<span className="text-red-500 text-xs mt-1">
						{numQuestionsHelpText}
					</span>
				)}
			</div>

			{/* Highest question number */}
			<div className="flex flex-col col-span-2">
				<label
					htmlFor="hqNum"
					className="font-medium text-primary"
				>
					Highest question number on the exam?*
				</label>
				<input
					type="number"
					id="hqNum"
					name="hqNum"
					value={state.hqNum || ""}
					onChange={handleMrScorChange}
					className={`mt-1 p-2 border rounded ${
						errorState.hqNum ? "border-red-500" : "border-gray-300"
					}`}
				/>
				{errorState.hqNum && (
					<span className="text-red-500 text-xs mt-1">
						{numQuestionsHelpText}
					</span>
				)}
			</div>

			{/* Answer options per question */}
			<div className="flex flex-col col-span-2">
				<label
					htmlFor="qLen"
					className="font-medium text-primary"
				>
					Number of answer options for each question*
				</label>
				<select
					id="qLen"
					name="qLen"
					value={state.qLen || ""}
					onChange={handleMrScorChange}
					className={`mt-1 hover:cursor-pointer p-2 border rounded ${
						errorState.qLen ? "border-red-500" : "border-gray-300"
					}`}
				>
					<option value="">Select an option</option>
					{listOfAnswerOptions.map((option) => (
						<option
							key={option.key}
							value={option.value}
						>
							{option.label}
						</option>
					))}
				</select>
				{errorState.qLen && (
					<span className="text-red-500 text-xs mt-1">
						{numQuestionsHelpText}
					</span>
				)}
			</div>
			<div className="flex flex-col col-span-2">
				<label
					htmlFor="valueNumKeys"
					className="font-medium text-primary"
				>
					Number of answer keys/exam versions*
				</label>

				<select
					id="valueNumKeys"
					name="valueNumKeys"
					value={state.valueNumKeys || ""}
					onChange={handleMrScorChange}
					className={`mt-1 hover:cursor-pointer p-2 border rounded ${
						errorState.valueNumKeys ? "border-red-500" : "border-gray-300"
					}`}
				>
					<option
						value=""
						key="997"
					>
						Select an option
					</option>
					{listOfKeys.map((option) => (
						<option
							key={option.key}
							value={option.value}
						>
							{option.label}
						</option>
					))}
				</select>

				{errorState.valueNumKeys && (
					<span className="text-red-500 text-xs mt-1">
						{numQuestionsHelpText}
					</span>
				)}
			</div>

			{/* Entire exam multiple response dropdown */}
			<div className="flex flex-col col-span-2">
				<label
					htmlFor="yesOrNo"
					className="font-medium text-primary"
				>
					Is the entire exam (scantron portion) multiple response?*
				</label>
				<select
					id="yesOrNo"
					name="yesOrNo"
					// disabled={state.hqNum === ""}
					value={state.yesOrNo || ""}
					onChange={handleMrScorChange}
					className={`mt-1 p-2 border rounded disabled:hover:cursor-pointer ${
						errorState.yesOrNo ? "border-red-500" : "border-gray-300"
					}`}
				>
					<option value="">Select an option</option>
					{yesOrNo.map((option) => (
						<option
							key={option.value}
							value={option.value}
						>
							{option.label}
						</option>
					))}
				</select>
				{errorState.yesOrNo && (
					<span className="text-red-500 text-xs mt-1">{yesOrNoHelpText}</span>
				)}
			</div>

			{/* --- MULTIPLE RESPONSE GRID (for exams that are not entirely multiple response) --- */}
			{state.yesOrNo === "No" && (
				<>
					<div className="flex flex-col col-span-2">
						<label className="font-medium text-primary">
							Select which questions are multiple response:*
						</label>
						{numQuestions > 0 ? (
							<>
								<div className="flex gap-4 my-2">
									<button
										onClick={toggleAll}
										className="px-4 py-2 bg-blue-500 text-white rounded"
									>
										Select All
									</button>
									<button
										onClick={resetToggles}
										className="px-4 py-2 bg-red-500 text-white rounded"
									>
										Reset
									</button>
								</div>
								{Array.from({ length: numRows }).map((_, rowIndex) => {
									const rowQuestions = [];
									for (let i = 0; i < columns; i++) {
										const qNum = Number(state.lqNum) + rowIndex * columns + i;
										if (qNum <= Number(state.hqNum)) {
											rowQuestions.push(qNum);
										}
									}
									return (
										<div
											key={rowIndex}
											className="flex items-center gap-2 my-1"
										>
											<div className="grid grid-cols-10 gap-2 flex-grow">
												{rowQuestions.map((questionNumber) => {
													const isSelected =
														state.multipleResponseQuestions &&
														state.multipleResponseQuestions.includes(
															questionNumber
														);
													return (
														<button
															key={questionNumber}
															onClick={() => toggleMRQuestion(questionNumber)}
															className={`p-2 border rounded ${
																isSelected
																	? "bg-blue-500 text-white"
																	: "bg-gray-200"
															}`}
														>
															{questionNumber}
														</button>
													);
												})}
											</div>
											<button
												onClick={() => toggleRow(rowIndex)}
												className="p-2 border rounded bg-gray-300"
											>
												{/* Row toggle button */}
											</button>
										</div>
									);
								})}
							</>
						) : (
							<p className="text-gray-500 mt-2">
								Please specify the question range above.
							</p>
						)}
					</div>

					{/* Weighting for single response questions */}
					<div className="flex flex-col col-span-2">
						<label
							htmlFor="weightingSingle"
							className="font-medium text-primary"
						>
							What weight would you like to assign to{" "}
							{state.yesOrNo === "No"
								? "the single response questions?*"
								: "each question?*"}
						</label>
						<select
							id="weightingSingle"
							name="weightingSingle"
							value={state.weightingSingle || ""}
							onChange={handleMrScorChange}
							className={`mt-1 p-2 border rounded ${
								errorState.weightingSingle
									? "border-red-500"
									: "border-gray-300"
							}`}
						>
							<option value="">Select an option</option>
							{listOfKeys.map((option) => (
								<option
									key={option.key}
									value={option.value}
								>
									{option.label}
								</option>
							))}
						</select>
						{errorState.weightingSingle && (
							<span className="text-red-500 text-xs mt-1">
								{weightingHelpText}
							</span>
						)}
					</div>
				</>
			)}

			{/* Weighting for multiple response questions */}
			<div className="flex flex-col col-span-2">
				<label
					htmlFor="weightingMultiple"
					className="font-medium text-primary"
				>
					What weight would you like to assign to{" "}
					{state.yesOrNo === "No"
						? "the multiple response questions?*"
						: "each question?*"}
				</label>
				<select
					id="weightingMultiple"
					name="weightingMultiple"
					value={state.weightingMultiple || ""}
					onChange={handleMrScorChange}
					className={`mt-1 p-2 border rounded ${
						errorState.weightingMultiple ? "border-red-500" : "border-gray-300"
					}`}
				>
					<option value="">Select an option</option>
					{listOfKeys.map((option) => (
						<option
							key={option.key}
							value={option.value}
						>
							{option.label}
						</option>
					))}
				</select>
				{errorState.weightingMultiple && (
					<span className="text-red-500 text-xs mt-1">{weightingHelpText}</span>
				)}
			</div>
			{/* Current configuration display */}
			{((state.yesOrNo === "Yes" && state.weightingMultiple) ||
				(state.yesOrNo === "No" &&
					state.weightingSingle &&
					state.weightingMultiple)) && (
				<div className="flex flex-col col-span-2">
					<label className="font-medium text-primary">
						Current Configuration:
					</label>
					{/* <div className="flex gap-2">Number of Questions: {numQuestions || "N/A"}</div>
					<div className="flex gap-2">Number of Answer Options: {state.qLen}</div> */}
					<div className="flex gap-2">
						Number of Multiple Response Questions: {state.numMultipleResponse}
					</div>
					{state.yesOrNo === "No" && (
						<div className="flex gap-2">
							Number of Single Response Questions: {state.numSingleResponse}
						</div>
					)}
					<div className="flex gap-2">
						Exam is out of {state.examTotalMarks || "N/A"} total marks.
					</div>
				</div>
			)}

			{/* Overanswering handling */}
			<div className="flex flex-col col-span-2">
				<label
					htmlFor="schema"
					className="font-medium text-primary flex flex-col gap-2"
				>
					Which grading scheme would you like to use?*
				</label>
				<select
					id="schema"
					name="schema"
					value={state.schema || ""}
					onChange={handleMrScorChange}
					className={`mt-1 p-2 border rounded ${
						errorState.schema ? "border-red-500" : "border-gray-300"
					}`}
				>
					<option value="">Select a grading scheme</option>
					{gradingSchemeOptions.map((option) => (
						<option
							key={option}
							value={option}
						>
							{option}
						</option>
					))}
				</select>
				{state.schema === "Other" && (
					<textarea
						id="schemaNotes"
						name="schemaNotes"
						placeholder="(REQUIRED) e.g. All or nothing, negative marks possible, etc."
						value={state.schemaNotes || ""}
						onChange={handleMrScorChange}
						className={`mt-1 p-2  border rounded ${
							errorState.schemaNotes ? "border-red-500" : "border-gray-300"
						}`}
					/>
				)}
				{state.schema === "Rights Minus Wrongs" && (
					<div className="px-1 py-2 font-medium text-gray-700 flex flex-col gap-2">
						<p>
							The most common marking scheme for Multiple Response exams. With
							this scheme, the student's grade for each question is calculated
							as the number of correct answers they selected minus the number of
							incorrect answers they selected.
						</p>
						<p>
							Example: Question 5 on your exam has 3 correct answers and 2
							incorrect answers. A student selects 2 correct answers and 1
							incorrect answer. Their grade for this question would be 2 - 1 =
							1.
						</p>
					</div>
				)}
				{state.schema === "All or None" && (
					<div className="px-1 py-2 font-medium text-gray-700 flex flex-col gap-2">
						<p>
							With this scheme, marks are awarded to the student only if they
							select all the correct answers and none of the incorrect answers.
						</p>
					</div>
				)}
			</div>
			<ClassDropDown
				listValues={classNumList}
				onChange={handleMrScorChange}
				multiple={multiple}
				inputlabel={`Click here to select your class${
					multiple ? "es" : ""
				} (REQUIRED):  `}
				onChangeSwitch={handleChangeClassDropdown}
				helperText={classNumHelpText}
				error={errorState.classNum}
				value={state.valueClassNum}
				name="valueClassNum"
				className="w-full col-span-2 "
			/>
			{/* Additional comments */}
			<div className="flex flex-col col-span-2">
				<label
					htmlFor="notes"
					className="font-medium text-primary"
				>
					Additional Notes (optional):
				</label>
				<textarea
					id="notes"
					name="notes"
					value={state.notes}
					onChange={handleChange}
					placeholder="e.g. Omit Question 21"
					className={`mt-1 p-2 border rounded ${
						errorState.notes ? "border-red-500" : "border-gray-300"
					}`}
					rows={4}
				></textarea>
				{errorState.notes && (
					<span className="text-red-500 text-xs mt-1">{notesHelpText}</span>
				)}
			</div>

			{/* Submit button */}
			<div className="col-span-2">
				<button
					onClick={handleSubmit}
					className="w-full p-2 bg-[#275d38] text-white rounded hover:bg-[#163520]"
				>
					Submit
				</button>
			</div>
		</div>
	);
}
