import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useState, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { Button, Switch, TextField } from "@mui/material";
import isAuthenticated from "../../Utils/isAuthenticated";
import Person2Icon from "@mui/icons-material/Person2";
import InstructorSearchAutocomplete from "../../Utils/InstructorSearchAutocomplete";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import { toggleWebUserSettings } from "../../Api/Post";

export default function ProfileMenu(props) {
	const auth = isAuthenticated();
	const userSettings = auth ? auth.userSettings : null;
	const token = auth ? auth.token : null;

	const access = auth ? auth.access : null;
	const isAdmin = access ? access.includes("ADMIN") : false;
	const ccid = auth ? auth.ccid : null;
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const [openUserSettings, setOpenUserSettings] = useState(false);
	const [checked, setChecked] = useState(false);
	const [openImpersonate, setOpenImpersonate] = useState(false);

	const [empidValue, setEmpidValue] = useState("");
	const [ccidValue, setCcidValue] = useState("");

	const [manualRelease, setManualRelease] = useState(
		auth ? auth.manual_release : false
	);

	// useEffect(() => {
	// 	if (userSettings) {
	// 		setManualRelease(userSettings.manual_release);
	// 	}
	// }, [userSettings]);

	const handleChangeManualRelease = async (event) => {
		event.stopPropagation();
		const setting = "manual_release";
		const response = await toggleWebUserSettings(token, ccid, setting);
		if (response) {
			setManualRelease(!manualRelease);
		}
	};

	const firstName = sessionStorage.getItem("first_name");

	const avatarLetter = firstName?.charAt(0).toUpperCase();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleChangeEmpidValue = (event) => {
		setEmpidValue(event.target.value);
	};

	const handleChangeCcidValue = (event) => {
		setCcidValue(event.target.value);
	};

	const handleSetEmpid = () => {
		sessionStorage.setItem("id", empidValue);
		setTimeout(() => {
			window.location.reload();
		}, 1000);
	};

	const handleSetCcid = () => {
		sessionStorage.setItem("ccid", ccidValue);
		setTimeout(() => {
			window.location.reload();
		}, 1000);
	};

	const handleOpenUserSettings = (event) => {
		event.stopPropagation();
		setOpenUserSettings(!openUserSettings);
	};

	const handleOpenImpersonate = (event) => {
		event.stopPropagation();
		setOpenImpersonate(!openImpersonate);
	};

	const handleLogout = () => {
		props.Logout();
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpenUserSettings(false);
	};

	const handleChangeDarkMode = (event) => {
		event.stopPropagation();
		setChecked(!checked);
		props.toggleDarkMode();
	};

	const handleClickAutocomplete = (event) => {
		event.stopPropagation();
	};

	return (
		<React.Fragment>
			<Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
				<Tooltip title="Account settings">
					<IconButton
						onClick={handleClick}
						size="small"
						sx={{ ml: 2 }}
						aria-controls={open ? "account-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={open ? "true" : undefined}
					>
						<Avatar sx={{ width: 32, height: 32 }}>{avatarLetter}</Avatar>
					</IconButton>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				MenuListProps={{
					disableListWrap: true,
				}}
				slotProps={{
					paper: {
						elevation: 0,
						sx: {
							overflow: "visible",
							filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
							mt: 1.5,
							"& .MuiAvatar-root": {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							"&:before": {
								content: '""',
								display: "block",
								position: "absolute",
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: "background.paper",
								transform: "translateY(-50%) rotate(45deg)",
								zIndex: 0,
							},
						},
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<MenuItem>CCID: {ccid}</MenuItem>
				<MenuItem onClick={handleOpenUserSettings}>
					<ListItemIcon>
						<Settings fontSize="small" />
					</ListItemIcon>
					<ListItemIcon sx={{}}>
						{openUserSettings ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
					</ListItemIcon>
					Settings
				</MenuItem>
				{openUserSettings && userSettings ? (
					<div>
						<MenuItem
							onClick={handleChangeDarkMode}
							className="flex gap-2"
						>
							<div>Manual SPOT Release</div>
							<ListItemIcon>
								<ScheduleSendIcon fontSize="small" />
							</ListItemIcon>
							<Switch
								checked={manualRelease}
								onChange={handleChangeManualRelease}
								inputProps={{ "aria-label": "controlled" }}
							/>
						</MenuItem>

						<Divider />
						{/* <MenuItem onClick={handleChangeDarkMode} disabled>
							<ListItemIcon>
								<DarkModeIcon fontSize="small" />
							</ListItemIcon>
							Dark Mode
							<Switch
								checked={checked}
								onChange={handleChange}
								inputProps={{ "aria-label": "controlled" }}
							/>
						</MenuItem> */}
					</div>
				) : null}
				{isAdmin ? (
					<MenuItem onClick={handleOpenImpersonate}>
						<ListItemIcon>
							<Person2Icon fontSize="small" />
						</ListItemIcon>
						<ListItemIcon sx={{}}>
							{openImpersonate ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
						</ListItemIcon>
						Impersonate
					</MenuItem>
				) : null}

				{openImpersonate ? (
					<MenuItem
						onKeyDown={(e) => e.stopPropagation()}
						sx={{
							width: "200px",
							display: "flex",
							flexDirection: "column",
							gap: 1,
						}}
					>
						<InstructorSearchAutocomplete onClick={handleClickAutocomplete} />

						<Divider />
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<TextField
								label="EmpId"
								variant="outlined"
								size="small"
								onChange={handleChangeEmpidValue}
							/>
							<Button
								variant="contained"
								size="small"
								sx={{ ml: 1 }}
								onClick={handleSetEmpid}
							>
								Set
							</Button>
						</Box>
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<TextField
								label="CCID"
								variant="outlined"
								size="small"
								onChange={handleChangeCcidValue}
							/>
							<Button
								variant="contained"
								size="small"
								sx={{ ml: 1 }}
								onClick={handleSetCcid}
							>
								Set
							</Button>
						</Box>
					</MenuItem>
				) : null}

				<MenuItem onClick={handleLogout}>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
					Logout
				</MenuItem>
			</Menu>
		</React.Fragment>
	);
}
