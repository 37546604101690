import React, { useState, useMemo, useEffect } from "react";
import { useDepartmentsQuery } from "../../../Hooks/useDepartmentsQuery";
import isAuthenticated from "../../../components/Utils/isAuthenticated";
import { useTermsQueryFull } from "../../../Hooks/useTermsQuery";
import ListSelect from "../../../components/Utils/ListSelect";
import { getClassesByDeptIdAndTerm } from "../../../components/Api/Getters";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ConfirmGradesSubmittedDialog from "./ConfirmGradesSubmittedDialog";
import ConfirmUndoDialog from "./ConfirmUndoDialog";
import ConfirmOptStatusDialog from "./ConfirmOptStatusDialog";
import InfoIcon from "@mui/icons-material/Info";
import ExplanationDialog from "./ExplanationDialog";

export default function SPOTGradesSubmitted() {
	const auth = isAuthenticated();
	const token = auth.token;
	const [termValue, setTermValue] = useState("");
	const [termNumber, setTermNumber] = useState(null);
	const [terms, setTerms] = useState([]);
	const primaryUserDept = auth.dept_ids.includes(",") ? auth.dept_ids.split(",")[0] : auth.dept_ids;
	const userDepts = useMemo(() => auth.dept_ids.split(","), [auth.dept_ids]);
	const [departmentsList, setDepartmentsList] = useState([]);
	const [selectedDept, setSelectedDept] = useState(null);
	const [deptId, setDeptId] = useState(null);
	const [selected, setSelected] = useState([]);
	const [deptClasses, setDeptClasses] = useState([]);
	const [chosenClasses, setChosenClasses] = useState([]);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [deptOptStatus, setDeptOptStatus] = useState(null);

	// Sorting & filtering state
	const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
	const [filters, setFilters] = useState({
		ctitle: "",
		name: "",
		survey_start: "",
		survey_end: "",
		rel_date: "",
		rpdate: "",
	});

	const [ctitle, setCtitle] = useState("");
	const [openUndo, setOpenUndo] = useState(false);
	const [operation, setOperation] = useState("");
	const [cls_uqid, setClsUqid] = useState("");
	const [openOptStatus, setOpenOptStatus] = useState(false);

	const [openExplanationDialog, setOpenExplanationDialog] = useState(false);

	// Fetch classes when termNumber and deptId are set
	useEffect(() => {
		const getClasses = async () => {
			const classes = await getClassesByDeptIdAndTerm(token, deptId, termNumber);
			setDeptClasses(classes);
		};
		if (termNumber && deptId) {
			getClasses();
		}
	}, [termNumber, deptId, token]);

	const { data: termsList } = useTermsQueryFull(token);
	const { data: departments, refetch: refetchDepartments } = useDepartmentsQuery(token);

	useEffect(() => {
		if (selected && selected.length > 0 && deptClasses && deptClasses.length > 0) {
			const selectedClasses =
				filteredData.length > 0
					? filteredData.filter((item) => selected.includes(item.cls_uqid))
					: deptClasses.filter((item) => selected.includes(item.cls_uqid));
			//sort selectedClasses on Subject_Id,cnumber,ctype,section in that order

			setChosenClasses(selectedClasses);
		}
	}, [selected, deptClasses]);

	useEffect(() => {
		if (primaryUserDept && departments && departments.length > 0) {
			const dept = departments.find((dept) => Number(dept.key) === Number(primaryUserDept));
			if (dept) {
				setDeptOptStatus(dept.grades_finalized);
				setSelectedDept(dept.department);
				setDeptId(Number(primaryUserDept));
			}
		}
	}, [primaryUserDept, departments]);

	useEffect(() => {
		if (departments && departments.length > 0) {
			// Map object to list of department names
			const filteredDepts = departments.filter((dept) => userDepts.includes(String(dept.key)));
			const deptNames = filteredDepts.map((dept) => dept.department);
			setDepartmentsList(deptNames);
		}
	}, [departments, userDepts]);

	useEffect(() => {
		if (termsList && termsList.length > 0) {
			const termDescriptions = termsList.map((term) => term.desc);
			const termNumbers = termsList.map((term) => term.term);
			const terms = termNumbers.map((term, index) => {
				return termDescriptions[index] + " (" + term + ")";
			});
			setTerms(terms);
			/*uupdate before push */
			// For example, select second highest term
			const highestTermNumber = Math.max(...termNumbers);
			const highestTermIndex = termNumbers.indexOf(highestTermNumber);
			const highestTerm = terms[highestTermIndex];
			// const secondHighestTermNumber = Math.max(
			// 	...termNumbers.filter((term) => term !== highestTermNumber)
			// );
			// const secondHighestTermIndex = termNumbers.indexOf(
			// 	secondHighestTermNumber
			// );
			// const secondHighestTerm = terms[secondHighestTermIndex];
			setTermValue(highestTerm);
		}
	}, [termsList]);

	useEffect(() => {
		if (termsList && termsList.length > 0 && termValue && termValue.length > 0) {
			// Get value in brackets of termValue
			const termNumber = termValue.match(/\(([^)]+)\)/)[1];
			setTermNumber(Number(termNumber));
		}
	}, [termsList, termValue]);

	const handleDepartmentChange = (event) => {
		const deptName = event.target.value;
		setSelectedDept(deptName);
		const dept = departments.find((dept) => dept.department === deptName);
		setDeptId(Number(dept.key));
		setSelected([]);
		setFilters({
			ctitle: "",
			name: "",
			survey_start: "",
			survey_end: "",
			rel_date: "",
			rpdate: "",
		});
		setSortConfig({ key: null, direction: "asc" });
	};

	// Handle selection toggling for each row
	const handleSelect = (id, rpdate, relDate) => {
		if (rpdate !== null || relDate !== null) {
			return;
		}
		setSelected((prev) => (prev.includes(id) ? prev.filter((sid) => sid !== id) : [...prev, id]));
	};

	const refreshData = async () => {
		const classes = await getClassesByDeptIdAndTerm(token, deptId, termNumber);
		setDeptClasses(classes);
	};

	const selectAll = () =>
		setSelected(
			filteredData.length > 0
				? filteredData
						.filter((item) => item.rpdate === null && item.rel_date === null)
						.map((item) => item.cls_uqid)
				: deptClasses
						.filter((item) => item.rpdate === null && item.rel_date === null)
						.map((item) => item.cls_uqid)
		);

	const deselectAll = () => setSelected([]);

	const toggleSelected = (operation) => {
		setOperation(operation);
		setOpenConfirm(true);
	};

	const requestSort = (key) => {
		if (sortConfig.key !== key) {
			setSortConfig({ key, direction: "asc" });
		} else if (sortConfig.direction === "asc") {
			setSortConfig({ key, direction: "desc" });
		} else if (sortConfig.direction === "desc") {
			setSortConfig({ key: null, direction: null });
		}
	};

	// Use memo to calculate sorted data
	const sortedData = useMemo(() => {
		let sortableData = [...deptClasses];
		if (sortConfig.key !== null) {
			sortableData.sort((a, b) => {
				let aVal = a[sortConfig.key];
				let bVal = b[sortConfig.key];

				// Convert date strings to Date objects for proper sorting.
				if (
					sortConfig.key === "survey_start" ||
					sortConfig.key === "survey_end" ||
					sortConfig.key === "rpdate" ||
					sortConfig.key === "rel_date"
				) {
					aVal = aVal ? new Date(aVal) : new Date(0);
					bVal = bVal ? new Date(bVal) : new Date(0);
				} else {
					// Compare as lower-case strings for non-date columns.
					aVal = aVal ? aVal.toString().toLowerCase() : "";
					bVal = bVal ? bVal.toString().toLowerCase() : "";
				}
				if (aVal < bVal) {
					return sortConfig.direction === "asc" ? -1 : 1;
				}
				if (aVal > bVal) {
					return sortConfig.direction === "asc" ? 1 : -1;
				}
				return 0;
			});
		}
		return sortableData;
	}, [deptClasses, sortConfig]);

	// Apply filtering on the sorted data
	const filteredData = useMemo(() => {
		let filtered = sortedData;
		Object.keys(filters).forEach((key) => {
			if (filters[key]) {
				filtered = filtered.filter((item) =>
					item[key]
						? item[key].toString().toLowerCase().includes(filters[key].toLowerCase())
						: false
				);
			}
		});
		return filtered;
	}, [sortedData, filters]);
	const allSelected =
		(selected.length ===
			filteredData.filter((item) => item.rpdate === null && item.rel_date === null).length &&
			filteredData.length > 0 &&
			selected.length !== 0) ||
		(selected.length ===
			deptClasses.filter((item) => item.rpdate === null && item.rel_date === null).length &&
			deptClasses.length > 0 &&
			selected.length !== 0);

	const handleClickApproved = (event, ctitle, cls_uqid) => {
		event.stopPropagation();
		setCtitle(ctitle);
		setClsUqid(cls_uqid);
		setOperation("undo");
		setOpenUndo(true);
	};

	const handleChangeOptStatus = () => {
		setOpenOptStatus(true);
	};

	return (
		<div className="p-6 min-h-[120dvh]">
			<ConfirmUndoDialog
				open={openUndo}
				setOpen={setOpenUndo}
				chosenClasses={chosenClasses}
				operation={operation}
				token={token}
				refreshData={refreshData}
				ctitle={ctitle}
				cls_uqid={cls_uqid}
			/>
			<ConfirmGradesSubmittedDialog
				open={openConfirm}
				setOpen={setOpenConfirm}
				selected={selected}
				setSelected={setSelected}
				chosenClasses={chosenClasses}
				operation={operation}
				token={token}
				refreshData={refreshData}
			/>
			<ConfirmOptStatusDialog
				open={openOptStatus}
				setOpen={setOpenOptStatus}
				deptOptStatus={deptOptStatus}
				deptId={deptId}
				setDeptOptStatus={setDeptOptStatus}
				token={token}
				refetchDepartments={refetchDepartments}
			/>
			<ExplanationDialog open={openExplanationDialog} setOpen={setOpenExplanationDialog} />
			<div className="flex items-center  mb-4 gap-4">
				<h1 className="text-2xl font-bold">SPOT Grades Finalized</h1>
				<button
					onClick={() => setOpenExplanationDialog(true)}
					className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex gap-2 hover:ring-2 "
				>
					Click for Explanation
					<InfoIcon />
				</button>
			</div>
			<div className="flex items-center justify-start w-full gap-2 py-4">
				<ListSelect
					className="termSelect"
					list={terms}
					label="Term"
					width="200px"
					size="small"
					marginBottom="0px"
					value={termValue || ""}
					onChange={(e) => setTermValue(e.target.value)}
				/>
				<ListSelect
					className="deptSelect"
					list={departmentsList}
					label="Department"
					width="350px"
					size="small"
					marginBottom="0px"
					value={selectedDept || ""}
					onChange={handleDepartmentChange}
				/>
				<label
					className={`px-4 py-2 flex items-center gap-4 text-white rounded hover:cursor-pointer hover:ring-2 ${
						deptOptStatus === "AUTO"
							? "hover:bg-green-800 bg-green-700 "
							: "hover:bg-red-500 bg-red-400 "
					}`}
					onClick={refreshData}
				>
					<input
						type="checkbox"
						checked={deptOptStatus === "AUTO"}
						onChange={handleChangeOptStatus}
						className="accent-green-800 w-4 h-4"
					/>
					<p>
						Department is currently{" "}
						{deptOptStatus === "AUTO" ? (
							<span>
								<span className="font-semibold ">OPTED IN</span> to{" "}
								<span className="underline">automated</span> grade finalization (default)
							</span>
						) : (
							"OPTED OUT of automated grade finalization"
						)}
					</p>
				</label>
			</div>

			<div className="flex justify-between gap-2 bg-gray-100 rounded-t-lg p-4">
				<div className="flex gap-2">
					<button
						disabled={selected.length === 0}
						onClick={() => toggleSelected("confirm")}
						className="px-4 py-2 bg-green-700 text-white rounded hover:bg-green-800 disabled:bg-gray-200 disabled:text-gray-500"
					>
						Confirm Grades Finalized ({selected.length} courses)
					</button>
				</div>
			</div>

			<div
				className={`overflow-x-auto bg-gray-100 px-4 max-h-[70dvh] overflow-y-auto flex flex-col ${
					deptOptStatus === "AUTO" ? "pointer-events-none opacity-50" : ""
				}`}
			>
				<table className="min-w-full bg-white divide-y divide-gray-200">
					<thead className="bg-gray-50 sticky top-0 z-10">
						<tr>
							<th className="px-6 py-3 text-left">
								<input
									type="checkbox"
									checked={allSelected}
									onChange={(e) => (e.target.checked ? selectAll() : deselectAll())}
								/>
							</th>
							{/* Example column: Class Title */}
							<th className="px-6 py-3 text-left">
								<div
									className="flex items-center cursor-pointer"
									onClick={() => requestSort("ctitle")}
								>
									<span className="text-xs font-medium text-gray-500 uppercase tracking-wider">
										Class Title
									</span>
									{sortConfig.key !== "ctitle" && <SwapVertIcon sx={{ width: 12, height: 12 }} />}

									{sortConfig.key === "ctitle" &&
										(sortConfig.direction === "asc" ? (
											<ArrowUpwardIcon sx={{ width: 12, height: 12 }} />
										) : (
											<ArrowDownwardIcon sx={{ width: 12, height: 12 }} />
										))}
								</div>
								<input
									type="text"
									placeholder="Search"
									value={filters.ctitle}
									onChange={(e) => setFilters((prev) => ({ ...prev, ctitle: e.target.value }))}
									className="mt-1 p-1 border rounded w-full text-xs"
								/>
							</th>
							{/* Instructor Column */}
							<th className="px-6 py-3 text-left">
								<div
									className="flex items-center cursor-pointer"
									onClick={() => requestSort("name")}
								>
									<span className="text-xs font-medium text-gray-500 uppercase tracking-wider">
										Instructor
									</span>
									{sortConfig.key !== "name" && <SwapVertIcon sx={{ width: 12, height: 12 }} />}

									{sortConfig.key === "name" &&
										(sortConfig.direction === "asc" ? (
											<ArrowUpwardIcon sx={{ width: 12, height: 12 }} />
										) : (
											<ArrowDownwardIcon sx={{ width: 12, height: 12 }} />
										))}
								</div>
								<input
									type="text"
									placeholder="Search"
									value={filters.name}
									onChange={(e) => setFilters((prev) => ({ ...prev, name: e.target.value }))}
									className="mt-1 p-1 border rounded w-full text-xs"
								/>
							</th>
							{/* Start Date Column */}
							<th className="px-6 py-3 text-left">
								<div
									className="flex items-center cursor-pointer"
									onClick={() => requestSort("survey_start")}
								>
									<span className="text-xs font-medium text-gray-500 uppercase tracking-wider">
										Start
									</span>
									{sortConfig.key !== "survey_start" && (
										<SwapVertIcon sx={{ width: 12, height: 12 }} />
									)}

									{sortConfig.key === "survey_start" &&
										(sortConfig.direction === "asc" ? (
											<ArrowUpwardIcon sx={{ width: 12, height: 12 }} />
										) : (
											<ArrowDownwardIcon sx={{ width: 12, height: 12 }} />
										))}
								</div>
								<input
									type="text"
									placeholder="Search"
									value={filters.survey_start}
									onChange={(e) =>
										setFilters((prev) => ({
											...prev,
											survey_start: e.target.value,
										}))
									}
									className="mt-1 p-1 border rounded w-full  max-w-20 text-xs"
								/>
							</th>
							{/* End Date Column */}
							<th className="px-6 py-3 text-left">
								<div
									className="flex items-center cursor-pointer"
									onClick={() => requestSort("survey_end")}
								>
									<span className="text-xs font-medium text-gray-500 uppercase tracking-wider">
										End
									</span>
									{sortConfig.key !== "survey_end" && (
										<SwapVertIcon sx={{ width: 12, height: 12 }} />
									)}

									{sortConfig.key === "survey_end" &&
										(sortConfig.direction === "asc" ? (
											<ArrowUpwardIcon sx={{ width: 12, height: 12 }} />
										) : (
											<ArrowDownwardIcon sx={{ width: 12, height: 12 }} />
										))}
								</div>
								<input
									type="text"
									placeholder="Search"
									value={filters.survey_end}
									onChange={(e) =>
										setFilters((prev) => ({
											...prev,
											survey_end: e.target.value,
										}))
									}
									className="mt-1 p-1 border rounded w-full  max-w-20 text-xs"
								/>
							</th>
							{/* Status Column */}
							<th className="px-6 py-3 text-left">
								<div
									className="flex items-center cursor-pointer"
									onClick={() => requestSort("rel_date")}
								>
									<span className="text-xs font-medium text-gray-500 uppercase tracking-wider">
										Status
									</span>
									{sortConfig.key !== "rel_date" && <SwapVertIcon sx={{ width: 12, height: 12 }} />}

									{sortConfig.key === "rel_date" &&
										(sortConfig.direction === "asc" ? (
											<ArrowUpwardIcon sx={{ width: 12, height: 12 }} />
										) : (
											<ArrowDownwardIcon sx={{ width: 12, height: 12 }} />
										))}
								</div>
							</th>
							{/* Results Sent Column */}
							<th className="px-6 py-3 text-left">
								<div
									className="flex items-center cursor-pointer"
									onClick={() => requestSort("rpdate")}
								>
									<span className="text-xs font-medium text-gray-500 uppercase tracking-wider ">
										Instructor Emailed
									</span>
									<SwapVertIcon sx={{ width: 12, height: 12 }} />
									{sortConfig.key === "rpdate" &&
										(sortConfig.direction === "asc" ? (
											<ArrowUpwardIcon sx={{ width: 12, height: 12 }} />
										) : (
											<ArrowDownwardIcon sx={{ width: 12, height: 12 }} />
										))}
								</div>
							</th>
						</tr>
					</thead>

					<tbody className="divide-y divide-gray-200 max-h-96 overflow-y-auto">
						{filteredData.map((classItem) => (
							<tr
								key={classItem.cls_uqid}
								disabled={classItem.rpdate !== null || classItem.rel_date !== null}
								onClick={() =>
									handleSelect(classItem.cls_uqid, classItem.rpdate, classItem.rel_date)
								}
								className="hover:bg-gray-200 hover:cursor-pointer "
							>
								<td className="px-6 py-2 whitespace-nowrap">
									<input
										disabled={classItem.rpdate !== null || classItem.rel_date !== null}
										type="checkbox"
										checked={selected.includes(classItem.cls_uqid)}
										readOnly
										//onChange={() => handleSelect(classItem.cls_uqid)}
									/>
								</td>
								<td className="px-6 py-2 whitespace-nowrap max-w-60 overflow-clip">
									{classItem.ctitle}
								</td>
								<td className="px-6 py-2 whitespace-nowrap max-w-40 overflow-clip">
									{classItem.name}
								</td>
								<td className="px-6 py-2 whitespace-nowrap max-w-20 overflow-clip">
									{classItem.survey_start}
								</td>
								<td className="px-6 py-2 whitespace-nowrap">{classItem.survey_end}</td>
								<td className="px-6 py-2 whitespace-nowrap">
									{classItem.rel_date !== null ? (
										<button
											onClick={(event) =>
												handleClickApproved(event, classItem.ctitle, classItem.cls_uqid)
											}
											disabled={classItem.rpdate !== null}
											className={`flex gap-1 items-center py-1 px-2 rounded-md   ${
												classItem.rpdate !== null
													? "bg-gray-100 text-gray-500 hover:cursor-not-allowed"
													: "bg-green-700 text-white hover:ring-2 hover:bg-green-800"
											}`}
										>
											<p className="">Finalized </p>
											<p>{classItem.rel_date.split("T")[0]}</p>
										</button>
									) : (
										<div
											className={` inline-flex items-center  p-1 rounded-md hover:cursor-pointer bg-gray-100 text-gray-500`}
										>
											<span className="">Not Finalized</span>
										</div>
									)}
								</td>
								<td className="px-6 py-2 whitespace-nowrap">{classItem.rpdate}</td>
							</tr>
						))}
					</tbody>
				</table>
				{filteredData.length === 0 && (
					<div className="w-full border-2 border-red-300 rounded-b-lg">
						<p className="text-center p-4 text-red-700">
							No classes found (yet). Please wait until surveys for your classes have concluded,
							they will not be displayed here until that time.
						</p>
					</div>
				)}
			</div>
			<div className="flex justify-between w-full px-6 py-2 bg-gray-100 rounded-b-lg">
				<div className=" "></div>
				{selected?.length > 0 && (
					<p className="text-sm font-semibold text-gray-500 ">
						{selected.length}/{filteredData.length > 0 ? filteredData.length : deptClasses.length}{" "}
						rows selected
					</p>
				)}
			</div>
		</div>
	);
}
