import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmCancelDialog(props) {
	const handleClose = () => {
		props.setOpen(false);
	};

	const handleConfirmClearSelection = () => {
		props.setConfirmSubmitAllSelected(false);
		//set pending to false for all classes
		const updatedRows = props.rows.map((row) => {
			if (row.pending) {
				return { ...row, evaluate: "", pending: false };
			}
			return row;
		});
		props.setRows(updatedRows);
		props.setCustomCheckboxSelection([]);
		props.setUnsavedChanges(false);
		props.setBatchUpdated(false);
		props.setOpen(false);
	};
	return (
		<div>
			<Dialog
				open={props.open}
				onClose={handleClose}
				maxWidth="xl"
			>
				<DialogTitle textAlign={"center"}>Confirm Cancellation:</DialogTitle>

				<DialogContent
					sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
				>
					<DialogContentText sx={{ maxWidth: "600px", color: "black" }}>
						You have made changes to multiple courses without saving/submitting.
						If you proceed to cancel, all changes will be lost.
					</DialogContentText>
					<DialogContentText sx={{ maxWidth: "600px", color: "black" }}>
						If you are sure you want to proceed, click on "Confirm
						Cancellation".
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button
						onClick={handleConfirmClearSelection}
						autoFocus
						color="error"
					>
						Confirm Cancellation
					</Button>
					<Button
						onClick={handleClose}
						variant="contained"
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
