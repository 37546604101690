import React, { useEffect, useState } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField, Button, Box, Checkbox, Divider } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
// import {BsSearch} from "react-icons/bs";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
// import Divider from '@mui/material/Divider';
import CircularProgress from "@mui/material/CircularProgress";
import isAuthenticated from "../../../../components/Utils/isAuthenticated";
import { useSTDUsriSearchQuery } from "../../../../Hooks/useSTDUsriSearchQuery";
import { BsSearch } from "react-icons/bs";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import { height } from "@mui/system";

export default function USRIStudentSearch() {
	const auth = isAuthenticated();
	const token = auth.token;
	const [loadingBar, setLoadingBar] = useState(true); // To show/hide loading bar
	const [terms, setTerms] = useState([]);
	const [insts, setInsts] = useState([]);
	const [selectedTerms, setSelectedTerms] = useState([]);
	const [isError, setIsError] = useState(false);
	const navigate = useNavigate();
	const [subject, setSubject] = useState("");
	const [catalogue, setCatalogue] = useState("");
	const [instName, setInstName] = useState("");
	const [subjectError, setSubjectError] = useState(false);
	const [nameError, setNameError] = useState(false);

	const [courseSelected, setCourseSelected] = useState(false);

	const [empid, setEmpid] = useState("");

	const [openSearch, setOpenSearch] = useState(false);

	useEffect(() => {
		if (subject.length > 0 || catalogue.length > 0) {
			setCourseSelected(true);
		} else {
			setCourseSelected(false);
		}
	}, [subject, catalogue]);

	function getEMPID(name) {
		const employee = insts.find((item) => item.name === name);
		return employee ? employee.empid : "?";
	}

	function searchReports() {
		if (selectedTerms.length > 0 && (subject.length > 1 || instName.length > 1)) {
			setSubjectError(false);
			setIsError(false);
			navigate({
				pathname: "/USRI-Student-Search-Results",
				search: createSearchParams({
					term: selectedTerms.toString(),

					empid: instName.length > 1 ? getEMPID(instName).toString() : "None",
					course: subject.length > 1 ? subject.concat(" ", catalogue).toString() : "None",
				}).toString(),
			});
		} else {
			setIsError(true);
		}
	}

	const { data: allSearchParams, isFetched } = useSTDUsriSearchQuery(token);

	useEffect(() => {
		if (isFetched) {
			setLoadingBar(false);
			let data = allSearchParams;
			let term_info = [];

			let inst_info = [];

			const terms = data.terms;

			const insts = data.faculty_rank;
			terms.forEach((term) => {
				const termObj = {
					term: term.term,
					season: term.desc_term.split(" ")[0],
					year: term.desc_term.split(" ")[1],
					desc_term: term.desc_term.concat(" (", term.term, ")"),
				};
				term_info.push(termObj);
			});

			insts.forEach((inst) => {
				inst_info.push({
					empid: inst.empid,
					name: inst.name,
					unit: inst.unit,
					label: inst.name,
				});
			});
			setTerms(term_info.sort((a, b) => b.term - a.term));

			setInsts(
				inst_info.sort((a, b) =>
					a.name.trim().split(", ")[0].localeCompare(b.name.trim().split(", ")[0])
				)
			);
		}
	}, [isFetched, allSearchParams]);

	const handleTermChange = (event, newValue) => {
		if (newValue.length > 0) {
			const selections = newValue.map((termObj) => {
				const matchingObject = terms.find((obj) => obj.desc_term === termObj.desc_term);
				return matchingObject ? matchingObject.term : null;
			});
			setSelectedTerms(selections);
		} else {
			setSelectedTerms([]);
		}
	};

	return (
		<div style={{ height: "100%", maxHeight: "calc(100% - 160px)" }}>
			<div className="Bread Crumbs" style={{ paddingLeft: "10px" }}>
				<Breadcrumbs separator=">" style={{ marginTop: "10px", marginBottom: "20px" }}>
					<Link underline="always" color="#275d38" href="/">
						<Typography color="#275d38" sx={{ display: "flex", alignItems: "center" }}>
							<HomeIcon sx={{ mr: 0.5 }} />
							Home
						</Typography>
					</Link>

					<Typography color="#275d38" fontWeight={600}>
						Student USRI Report Search
					</Typography>
				</Breadcrumbs>
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Typography variant="h2">Student USRI Report Search</Typography>

				<Box
					sx={{
						textAlign: "center",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						paddingTop: "15px",
						width: "500px",
						paddingTop: "30px",
						gap: "5px",
					}}
				>
					<div
						className={`flex gap-2 w-full items-center border-20 ${
							selectedTerms.length === 0 ? "border-yellow-600" : "border-primary"
						} px-1 py-4 rounded-md`}
					>
						<div
							className={` ${
								selectedTerms.length === 0 ? "text-yellow-600" : "text-primary"
							} h-full `}
						>
							<LooksOneIcon sx={{ width: "45px", height: "100%" }} />
						</div>
						<Autocomplete
							freeSolo
							multiple
							limitTags={3}
							onChange={handleTermChange}
							size="small"
							options={terms.sort((a, b) => {
								// First sort by season using the seasonOrder dictionary
								/* if (seasonOrder[a.season] < seasonOrder[b.season]) return 1;
							if (seasonOrder[a.season] > seasonOrder[b.season]) return -1; */

								// If seasons are the same, sort by year
								return b.term - a.term;
							})}
							/* groupBy={(option) => option.season} */
							getOptionLabel={(option) => option.desc_term}
							disableCloseOnSelect
							sx={{ width: "100%" }}
							renderInput={(params) => (
								<TextField
									{...params}
									label="SELECT TERM(S) (REQUIRED)"
									required
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{loadingBar ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
						/>
					</div>

					<div
						className={`flex gap-2 w-full items-center border-20 ${
							selectedTerms.length === 0
								? "border-gray-200"
								: subject.length > 0 || catalogue.length > 0 || (empid && empid !== "?")
								? "border-primary"
								: "border-yellow-600"
						} px-1 py-6 rounded-md max-w-[500px] shadoww`}
					>
						<div
							className={` h-full ${
								selectedTerms.length === 0
									? "text-gray-200"
									: subject.length > 0 || catalogue.length > 0 || (empid && empid !== "?")
									? "text-primary"
									: "text-yellow-600"
							}`}
						>
							<LooksTwoIcon sx={{ width: "45px", height: "100%" }} />
						</div>
						<div className="flex flex-col gap-6 w-full p-1">
							<Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
								<TextField
									disabled={(empid && empid !== "?") || selectedTerms.length === 0}
									label="Subject"
									value={subject}
									helperText="ex: ''MATH''"
									size="small"
									style={{ width: "50%", height: "40px" }}
									onChange={(e) => {
										setSubject(e.target.value.replace(/[^a-zA-Z]/g, "").toUpperCase());
									}}
								/>
								<TextField
									disabled={(empid && empid !== "?") || selectedTerms.length === 0}
									label="Catalogue #"
									value={catalogue}
									helperText="ex: ''100''"
									size="small"
									style={{ width: "50%", height: "40px" }}
									onChange={(e) => {
										setCatalogue(e.target.value.replace(/\D/g, ""));
									}}
								/>
							</Box>
							{subjectError && (
								<p style={{ color: "red", textAlign: "center", paddingTop: "10px" }}>
									Please fill out the subject field.
								</p>
							)}

							<Divider
								orientation="horizontal"
								style={{
									width: "95%",
									marginLeft: "5px",
									color: selectedTerms.length > 0 ? "black" : "gray",
								}}
							>
								OR
							</Divider>

							<Box sx={{ display: "flex", flexDirection: "row" }}>
								<Autocomplete
									disabled={
										subject.length > 0 || selectedTerms.length === 0 || catalogue.length > 0
									}
									freeSolo
									open={openSearch}
									style={{ width: "100%", height: "40px" }}
									options={[...new Set(insts.map((option) => option.name))]}
									ListboxProps={{ style: { maxHeight: 200, overflowY: "scroll" } }}
									onInputChange={(event, newInputValue) => {
										if (newInputValue.length < 3) {
											setOpenSearch(false);
										} else {
											setOpenSearch(true);
										}
									}}
									onChange={(event) => {
										setInstName(event.target.textContent);
										setEmpid(getEMPID(event.target.textContent));
										setOpenSearch(false);
									}}
									filterOptions={(options, { inputValue }) => {
										const trimmedInput = inputValue.trim();
										// If the input contains a space, treat it as multiple tokens.
										if (trimmedInput.includes(" ")) {
											const tokens = trimmedInput.split(/\s+/).map((token) => token.toLowerCase());
											return options.filter((option) => {
												// Assume the option is in "Lastname, Firstname" format.
												const parts = option.split(",").map((part) => part.trim().toLowerCase());
												// Check that every token appears in one of the parts.
												return tokens.every((token) => parts.some((part) => part.includes(token)));
											});
										} else {
											// For single-token input, remove commas and perform a simple case-insensitive match.
											const normalizedInput = trimmedInput.replace(/,/g, "").toLowerCase();
											return options.filter((option) =>
												option.replace(/,/g, "").toLowerCase().trim().includes(normalizedInput)
											);
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											value={instName}
											helperText="ex: 'John Doe'"
											label="Instructor Name"
											size="small"
											onChange={(event) => {
												setInstName(event.target.value);
											}}
										/>
									)}
								/>
							</Box>
							{nameError && (
								<p style={{ color: "red", textAlign: "center", paddingTop: "10px" }}>
									Please fill out the Instructor field.
								</p>
							)}
						</div>
					</div>
					<div className="flex gap-2 items-baseline justify-center w-[500px] px-1">
						<div
							className={` h-full ${
								selectedTerms.length === 0 || (!courseSelected && (empid === "?" || !empid))
									? "text-gray-200"
									: "text-primary"
							} `}
						>
							<Looks3Icon sx={{ width: "52px", height: "100%" }} />
						</div>
						<Button
							disabled={
								selectedTerms.length === 0 || (!courseSelected && (empid === "?" || !empid))
							}
							variant="contained"
							sx={{
								backgroundColor: "#275d38",
								fontSize: "16px",
								width: "100%",
								marginTop: "20px",
							}}
							onClick={searchReports}
						>
							Search Reports
						</Button>
					</div>

					{isError && (
						<p style={{ color: "red", textAlign: "center", paddingTop: "5px" }}>
							PLEASE SELECT AT LEAST ONE TERM AND DEPARTMENT
						</p>
					)}
				</Box>
			</div>
		</div>
	);
}
