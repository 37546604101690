import { useQuery } from "react-query";
import { getDepartments } from "../components/Api/Getters";

export const useDepartmentsQuery = (token) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		enabled: !!token,
		staleTime: 1000 * 5,
	};
	return useQuery(
		"departments",
		async () => {
			const departments = await getDepartments(token);
			const dnameArray = [];
			const departmentsArray = [];
			if (departments.length > 0) {
				departments.forEach((department) => {
					if (department.dname.includes("Department of ")) {
						department.dname = department.dname.replace("Department of ", "");
					}
					if (department.dname.includes("Departments of ")) {
						department.dname = department.dname.replace("Departments of ", "");
					}
					if (department.dname.includes("Division of ")) {
						department.dname = department.dname.replace("Division of ", "");
					}
					dnameArray.push({
						department: department.dname + " (" + department.faculty.trim() + ")",
						key: department.dept_id,
						display_name: department.display_name,
						faculty: department.faculty,
						grades_finalized: department.grades_finalized,
					});
					departmentsArray.push(department);
				});
			}
			dnameArray.sort((a, b) => {
				if (a.department < b.department) {
					return -1;
				}
				if (a.department > b.department) {
					return 1;
				}
				return 0;
			});

			return dnameArray;
		},
		queryOptions
	);
};
