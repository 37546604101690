import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
// import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { BsSearch } from "react-icons/bs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { PDFDocument } from "pdf-lib";
import { rankingSorter, CustomPagination, CustomNoRowsOverlay } from "../../../SecondaryFunctions";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import isAuthenticated from "../../../../components/Utils/isAuthenticated";

import { getUSRIReportData } from "../../../../components/Api/Getters";
import ReportDialog from "./ReportDialog";

const Row = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	boxShadow:
		theme.palette.mode === "dark"
			? "0px 2px 4px rgba(0, 0, 0, 0.4)"
			: "0px 2px 4px rgba(0, 0, 0, 0.2)",
	marginBottom: "3px",
	marginTop: "3px",
}));

// mappings for different reference groups
const ref_options = {
	C: "All Courses",
	CS: "All Courses Similar Class Size",
	CL: "All Courses Similar Course Level",
	CSL: "All Courses Similar Course Level/Class Size",
	F: "Courses in Faculty",
	FS: "Courses in Faculty Similar Class Size",
	FL: "Courses in Faculty Similar Course Level",
	FSL: "Courses in Faculty Similar Course Level/Class Size",
	D: "Courses in Department",
	DS: "Course in Department Similar Class Size",
	DL: "Courses in Department Similar Class Level",
	DSL: "Courses in Department Similar Class Level/Class Size",
};

export default function USRIStudentSearchResults() {
	// Define various state variables using the useState hook
	const auth = isAuthenticated();
	const token = auth.token;
	const [loaded, setLoaded] = useState(false); // To track if data is loaded
	const [loadingBar, setLoadingBar] = useState(true); // To show/hide loading bar
	const [apiFetched, setApiFetched] = useState(false); // To track API data fetching
	const [courseData, setCourseData] = useState([]); // Store fetched course data
	const [filteredCourseData, setFilteredCourseData] = useState([]); // Store filtered course data
	const [filters, setFilters] = useState({
		faculty: [],
		department: [],
		acyear: [],
		term: [],
	}); // Store filter values
	const [courseName, setCourseName] = useState(""); // Store course name for search
	const [searchParams] = useSearchParams();
	const [openReportDialog, setOpenReportDialog] = useState(false);
	const [reportData, setReportData] = useState([]);

	// Function to handle Enter key press for course search
	const handleKeypress = (e) => {
		if (e.keyCode === 13) {
			searchCourse();
		}
	};

	// Function to search for courses based on course name
	function searchCourse() {
		let temp = courseData.filter((item) => item.course.includes(courseName));
		setFilteredCourseData(temp);
	}

	// Function to clear search and reset filteredCourseData
	function handleClear() {
		setCourseName("");
		let temp = courseData.filter((item) => item.course.includes(""));
		setFilteredCourseData(temp);
	}

	// Function to update filter values
	function updateFilters(fgroup, value, checked) {
		let updatedFilters = filters;
		if (checked) {
			if (!updatedFilters[fgroup].includes(value)) {
				updatedFilters[fgroup].push(value);
			}
		} else {
			updatedFilters[fgroup] = updatedFilters[fgroup].filter((item) => item !== value);
		}
		setFilters(updatedFilters);
		applyFilters();
	}

	function applyFilters() {
		const filterCategories = ["faculty", "department", "acyear", "term"];
		let filteredData = [];
		filterCategories.forEach((category) => {
			if (filters[category].length !== 0) {
				let temp = courseData.filter((item) => filters[category].includes(item[category]));
				if (filteredData.length === 0) {
					filteredData = temp;
				} else {
					filteredData = filteredData.filter((value) => temp.includes(value));
				}
			} else {
				if (filteredData.length === 0) {
					filteredData = courseData;
				} else {
					filteredData = filteredData.filter((value) => courseData.includes(value));
				}
			}
		});
		if (filteredData.length !== 0) {
			filteredData.sort(rankingSorter("termNum", "ctitle"));
			setFilteredCourseData(filteredData);
		}
	}
	// useEffect hook to fetch and process data from the API
	useEffect(() => {
		let url = process.env.REACT_APP_BACKEND_HOST.concat("/spot/std_usri_results/");
		async function fetch_std_results(terms, empids, course) {
			const termsVal = "(" + terms + ")";

			const empidsVal = empids === "None" ? "None" : "(" + empids + ")";
			const courseVal = course === "None" ? "None" : course;
			const array = [termsVal, empidsVal, courseVal];
			const params = array.join(";");
			return new Promise((resolve, reject) => {
				axios({
					method: "get",
					url: url.concat(params).concat("/"),
					headers: {
						Authorization: `Token ${token}`,
					},
				})
					.then((response) => {
						if (response.status === 200) {
							resolve(response.data);

							const json = response.data;
							let apiData = [];
							for (let i = 0; i < json.length; i++) {
								let entry = {
									id: i + 1,
									reqnum: json[i].REQNUM,
									clsnum: json[i].CLSNUM,
									faculty: json[i].FNAME.trim(),
									department: json[i].DNAME.trim(),
									description: json[i].DESCRIPTION,
									acyear: json[i].ACYEAR.trim(),
									term: json[i].DESC_TERM.trim(),
									termNum: json[i].TERM,
									classid: json[i].CLASSID,
									course: json[i].CTITLE.trim(),
									ctype: json[i].CTYPE.trim(),
									empid: json[i].EMPID,
									name: json[i].NAME.trim(),
									report_id: json[i].REPORT_ID,
									ref1: json[i].REF1.trim(),
									ref2: json[i].REF2.trim(),
									ref3: json[i].REF3.trim(),
									ref_group: json[i].REF1.trim(),
									inst: json[i].NAME.trim().concat(" - ", json[i].EMPID),
								};
								apiData.push(entry);
							}
							setApiFetched(true);
							apiData.sort(rankingSorter("termNum", "ctitle"));
							setFilteredCourseData(apiData);
							setCourseData(apiData);
							setLoaded(true);
						}
					})
					.catch((error) => {
						console.log(error);

						reject(error);
					});
			});
		}

		if (!loaded) {
			fetch_std_results(
				searchParams.get("term"),
				searchParams.get("empid"),
				searchParams.get("course")
			);
		}
		if (loaded && loadingBar) {
			setTimeout(() => {
				setLoadingBar(false);
			}, 3000);
		}
	}, [loaded, courseData, filteredCourseData, loadingBar, apiFetched]);

	const handleOpenReportDialog = async (row) => {
		const data = await getUSRIReportData(
			token,
			row.reqnum,
			row.clsnum,

			"pub"
		);
		setReportData(data.data);
		setOpenReportDialog(true);
	};

	const columns = [
		{
			field: "course",
			headerName: "Course",
			width: 250,
			align: "center",
			headerAlign: "center",
		},
		{
			field: "description",
			headerName: "Description",
			width: 500,
			align: "center",
			headerAlign: "center",
		},
		{
			field: "term",
			headerName: "Term",
			width: 150,
			align: "center",
			headerAlign: "center",
		},
		{
			field: "name",
			headerName: "Instructor",
			width: 300,
			align: "center",
			headerAlign: "center",
		},
		{
			field: "view",
			headerName: "",
			width: 200,
			align: "center",
			renderCell: (params) => (
				<button
					onClick={() => handleOpenReportDialog(params.row)}
					className="bg-primary text-white px-6 py-2 m-2 rounded-md hover:ring-2 hover:bg-green-900 flex items-center justify-center"
				>
					<DownloadIcon />
					View Report
				</button>
			),
		},
	];

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				height: "100%",
				maxHeight: "calc(100% - 160px)",
			}}
		>
			<ReportDialog open={openReportDialog} setOpen={setOpenReportDialog} reportData={reportData} />
			<Box
				sx={{
					width: "21%",
					maxWidth: 400,
					// height:'auto',
					borderRight: "2px solid rgba(0, 0, 0, .5)",
					borderColor: "rgba(0, 0, 0, 0.2)",
					paddingLeft: "10px",
					paddingRight: "10px",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Breadcrumbs separator=">" style={{ marginTop: "10px", marginBottom: "20px" }}>
					<Link underline="always" color="#275d38" href="/">
						<Typography color="#275d38" sx={{ display: "flex", alignItems: "center" }}>
							<HomeIcon sx={{ mr: 0.5 }} />
							Home
						</Typography>
					</Link>
					<Link underline="always" color="#275d38" href="/USRI-Student-Search">
						<Typography color="#275d38">Student USRI Report Search</Typography>
					</Link>
					<Typography color="#275d38" fontWeight={600}>
						USRI Search Results
					</Typography>
				</Breadcrumbs>

				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						marginBottom: "20px",
					}}
				>
					<TextField
						label="Course Title"
						size="small"
						value={courseName}
						onChange={(e) => {
							setCourseName(e.target.value.toUpperCase());
						}}
						onKeyUp={handleKeypress}
						sx={{ width: "322px" }}
						InputProps={{
							endAdornment: (
								<IconButton onClick={handleClear}>
									{courseName.length > 0 ? <ClearIcon /> : ""}
								</IconButton>
							),
						}}
					/>

					<Button
						variant="contained"
						style={{
							backgroundColor: "#275d38",
							fontSize: "1.2rem",
							marginLeft: "2px",
						}}
						onClick={searchCourse}
					>
						<BsSearch />
					</Button>
				</Box>

				<Accordion disableGutters>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>Academic Year</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{[...new Set(courseData.map((item) => item.acyear))].map((acyear, index) => (
							<FormControlLabel
								key={index}
								control={
									<Checkbox
										onChange={(e) => {
											updateFilters("acyear", e.target.value, e.target.checked);
										}}
									/>
								}
								value={acyear}
								label={acyear}
							/>
						))}
					</AccordionDetails>
				</Accordion>

				<Accordion disableGutters>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>Term</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{[...new Set(courseData.map((item) => item.term))].map((term, index) => (
							<FormControlLabel
								key={index}
								control={
									<Checkbox
										onChange={(e) => {
											updateFilters("term", e.target.value, e.target.checked);
										}}
									/>
								}
								value={term}
								label={term}
							/>
						))}
					</AccordionDetails>
				</Accordion>
			</Box>
			<div
				style={{
					paddingTop: "15px",
					width: "80%",
					maxWidth: "1250px",
					margin: "0 auto",
				}}
			>
				<DataGrid
					getRowHeight={() => "auto"}
					disableColumnFilter
					disableColumnSelector
					disableColumnMenu
					disableSelectionOnClick
					getRowId={(row) => row.id}
					style={{ textAlign: "center" }}
					columns={columns}
					rows={filteredCourseData}
					density="compact"
					pageSize={100}
					rowsPerPageOptions={[100]}
					slots={{
						Pagination: CustomPagination,
						// LoadingOverlay: LinearProgress,
						LoadingOverlay: CircularProgress,
						NoRowsOverlay: CustomNoRowsOverlay,
					}}
					loading={loadingBar}
					sx={{
						height: "82vh",
						width: "100%",
						zoom: 0.85,
						"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
							outline: "None !important",
						},
						"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
							width: "0.4em",
						},
						"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
							background: "#f1f1f1",
						},
						"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
							backgroundColor: "#888",
						},
						"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
							background: "#555",
						},
					}}
				/>
			</div>
		</div>
	);
}
