import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

export default function ReportDialog(props) {
	const { open, setOpen, reportData } = props;
	const handleClose = () => {
		setOpen(false);
	};

	const { header, pub_results, labels, class_data } = reportData;

	const reqNumKey = header?.requisition?.split("-")[0]; // "730616"
	const rows = pub_results?.[reqNumKey] || [];

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				fullScreen
				sx={{
					background: "#275d38",
					"& .MuiPaper-root": {
						background: "#275d38",
					},
				}}
				className="bg-primary"
			>
				<div className="mx-auto max-w-screen-xl p-4  border border-gray-300 mt-20 relative bg-white">
					<div className="absolute top-0 left-0 w-full h-full bg-black opacity-0 z-10"></div>
					{/* Title / Header section */}
					<div className="text-center mb-4">
						<h1 className="text-xl font-bold">{header?.title}</h1>
						{/* <p className="text-sm">
							The University of Alberta&apos;s Instructor-Designed Questionnaire System
						</p> */}
						{/* <p className="text-sm">
							Requisition Number: <span className="font-bold">{header?.requisition}</span>
						</p> */}
					</div>

					{/* Top Row: Left (Dept Chair info) and Right (Date / Catalog) */}
					<div className="flex justify-between items-start mb-4">
						<div className="space-y-1">
							<p>
								Instructor: <span className="font-semibold">{header?.instructor}</span>
							</p>
							{/* <p className="font-semibold">Attn: Department Chair</p> */}

							<p>
								Class: <span className="font-semibold">{header?.class_title}</span>
							</p>
							<p>
								Department: <span className="font-semibold">{header?.department}</span>
							</p>
							<p>
								Faculty: <span className="font-semibold">{header?.faculty}</span>
							</p>
						</div>
						<div className="text-right space-y-1">
							{/* <p className="font-semibold">USRI Report</p> */}
							<p>{header?.report_date}</p>
							<p>
								Academic Year: <span className="font-semibold">{header?.academic_year}</span>
							</p>
							<p>
								Catalog: <span className="font-semibold">{header?.catalog}</span>
							</p>
						</div>
					</div>

					{/* Example table replicating "Item / Responses / Class Median" */}
					<div className="overflow-x-auto">
						<table className="w-full text-sm border-collapse border border-gray-400">
							<thead>
								<tr className="bg-gray-100">
									<th className="border border-gray-300 px-2 py-1 text-left w-auto">ITEM</th>
									<th colSpan="5" className="border border-gray-300 px-2 py-1 text-center">
										RESPONSES FROM THIS CLASS
										<div className="text-xs font-normal">(SD, D, N, A, SA)</div>
									</th>
									<th className="border border-gray-300 px-2 py-1 text-center w-20">
										CLASS MEDIAN
									</th>
								</tr>
							</thead>
							<tbody>
								{rows.map((row) => (
									<tr key={row.itemno} className="odd:bg-white even:bg-gray-50">
										<td className="border border-gray-300 px-2 py-1">{row.item_text}</td>
										<td className="border border-gray-300 px-2 py-1 text-center">{row.rsp1}</td>
										<td className="border border-gray-300 px-2 py-1 text-center">{row.rsp2}</td>
										<td className="border border-gray-300 px-2 py-1 text-center">{row.rsp3}</td>
										<td className="border border-gray-300 px-2 py-1 text-center">{row.rsp4}</td>
										<td className="border border-gray-300 px-2 py-1 text-center">{row.rsp5}</td>
										<td className="border border-gray-300 px-2 py-1 text-center">{row.median}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{/* Footer info or summary */}
					{class_data && (
						<div className="mt-4 text-sm space-y-2 ">
							<p>
								Number of students responding to questionnaire:{" "}
								<span className="font-semibold">
									{class_data.qtally} out of {class_data.csize} (
									{((Number(class_data.qtally) / Number(class_data.csize)) * 100).toFixed(1)}%)
								</span>
								{class_data.enrollment_date &&
									` Enrolment updated on ${class_data.enrollment_date}`}
							</p>
							<p>
								Responses from this class were collected{" "}
								{class_data.webct === "Yes" ? "online" : "in person"}.
							</p>
							{/* <p className="font-semibold">{labels.label}</p> */}
							{/* <p>
							The reference data for the Universal Items is based on results beginning with Academic
							Year 2005/06 while other items use all data available.
						</p> */}
						</div>
					)}
				</div>
				<p className="mx-auto max-w-3xl text-white pt-2">
					Information about the contents of this report may be found on the Web at:{" "}
					<a
						href="https://www.ualberta.ca/information-services-and-technology/services/test-scoring-questionnaire-services/student-perspectives-of-teaching.html"
						className="text-blue-300 underline"
					>
						https://www.ualberta.ca/information-services-and-technology/services/test-scoring-questionnaire-services/student-perspectives-of-teaching.html
					</a>
				</p>

				{/* <DialogActions className="absolute bottom-10 right-10">
					<button
						onClick={handleClose}
						className="py-2 px-4  text-primary bg-white border-white border-2 rounded-md hover:ring-2"
					>
						Close
					</button>
				</DialogActions> */}
				<DialogActions className="absolute top-2 right-10">
					<button onClick={handleClose} className="p-2  text-white">
						<CloseIcon size={60} sx={{ width: 50, height: 50 }} />
					</button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
