import React from "react";
import styles from "../styles/info.module.css";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import {
	Select,
	FormHelperText,
	FormLabel,
	FormControl,
	Switch,
	Stack,
	Typography,
	InputLabel,
	ListSubheader,
} from "@mui/material";
import dayjs from "dayjs";

// Define a color mapping for terms
const termColors = {
	"(current)": "black",
	"(future)": "gray",
};

function ClassDropDown(props) {
	// Group classes by term
	const today = dayjs();
	const groupedClasses = React.useMemo(() => {
		const today = dayjs();
		return props.listValues.reduce((groups, item) => {
			let term = item.term || "Unknown Term";
			const term_start = dayjs(item.term_start);
			const term_end = dayjs(item.term_end);
			if (today.isBefore(term_start)) {
				term += " (future)";
			} else if (today.isAfter(term_start) && today.isBefore(term_end)) {
				term += " (current)";
			}
			if (!groups[term]) {
				groups[term] = {
					classes: [],
					term_start,
					term_end,
				};
			}
			groups[term].classes.push(item);
			return groups;
		}, {});
	}, [props.listValues]);

	const sortedTerms = React.useMemo(() => {
		return Object.keys(groupedClasses).sort((a, b) => {
			const termAStart = dayjs(groupedClasses[a].term_start);
			const termBStart = dayjs(groupedClasses[b].term_start);
			if (termAStart.isBefore(termBStart)) return -1;
			if (termAStart.isAfter(termBStart)) return 1;
			return 0;
		});
	}, [groupedClasses]);

	const renderMenuItems = () => {
		return sortedTerms.flatMap((term) => [
			<ListSubheader key={`header-${term}`}>{term}</ListSubheader>,
			...groupedClasses[term].classes.map((option) => (
				<MenuItem
					key={option.key}
					value={option.value}
					style={{ color: termColors[term.split(" ")[2]] || "black" }}
				>
					{option.label} : {option.name} : {option.value}
				</MenuItem>
			)),
		]);
	};

	return (
		<div className={props.className}>
			<FormControl
				variant="outlined"
				sx={{ width: "100%" }}
			>
				<InputLabel sx={{ color: "#275d38" }}>{props.inputlabel}</InputLabel>
				<Select
					value={props.value}
					name={props.name}
					error={props.error}
					label={props.inputlabel}
					multiple={props.multiple}
					onChange={props.onChange}
					variant="outlined"
					sx={{
						color: "theme.palette.secondary",
					}}
				>
					{!props.multiple && (
						<MenuItem value="">
							<em>Select a class</em>
						</MenuItem>
					)}
					{renderMenuItems()}
				</Select>
			</FormControl>
			<div className="group flex items-center border-2 px-2 justify-center rounded-bl-lg rounded-br-lg bg-gray-100 ">
				<p className="group font-semibold">Multiple Classes</p>
				<Switch
					className="group"
					checked={props.multiple}
					onChange={props.onChangeSwitch}
					disabled={props.disabled}
				/>
			</div>
		</div>
	);
}

export default ClassDropDown;
