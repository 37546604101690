import React from "react";
import { Button, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

const computeRowName = (row) => {
	if (row.roles && row.roles.length === 1) {
		const role = row.roles[0];
		return role.name
			? role.name
			: role.user && role.user.last_name && role.user.first_name
			? `${role.user.last_name}, ${role.user.first_name}`
			: "No Instructor Found";
	} else if (row.roles && row.roles.length > 1 && row.nameExpanded) {
		return row.teamName
			.split(/[\(\)]/)
			.filter((name) => name.trim() && name !== "," && name !== ", ")
			.join(", ");
	} else if (row.roles && row.roles.length > 1) {
		return "Several";
	} else {
		return "No Instructor Found";
	}
};

const InstructorCell = ({ row, handleTeamVisibility }) => {
	// Set row.name dynamically
	row.name = computeRowName(row);

	const instructorNamesTooltip = row.roles
		? row.roles
				.map((role) =>
					role.name
						? `(${role.name})`
						: role.user && role.user.last_name && role.user.first_name
						? `(${role.user.last_name}, ${role.user.first_name})`
						: ""
				)
				.join(", ")
		: "";

	if (row.roles && row.roles.length === 1) {
		const role = row.roles[0];
		return <p className="text-sm font-semibold">{row.name}</p>;
	} else if (row.roles && row.roles.length > 1 && !row.nameExpanded) {
		return (
			<strong title={instructorNamesTooltip}>
				<Button onClick={() => handleTeamVisibility(row)}>
					<p className="text-md font-semibold mr-2 -ml-2">{row.name}</p>
					<VisibilityIcon />
				</Button>
			</strong>
		);
	} else if (row.roles && row.roles.length > 1 && row.nameExpanded) {
		return (
			<button
				onClick={() => handleTeamVisibility(row)}
				title={instructorNamesTooltip}
				className="text-[10pt] flex flex-col items-start tracking-tightest w-full font-semibold max-h-[32px] overflow-y-auto overflow-x-hidden"
			>
				{row.name.split(", ").map((name, index) => (
					<p key={index}>{name.trim()}</p>
				))}
			</button>
		);
	} else {
		return (
			<strong>
				<Typography
					sx={{ marginRight: "10px", fontSize: "11pt" }}
					color="red"
				>
					{row.name}
				</Typography>
			</strong>
		);
	}
};

export default InstructorCell;
