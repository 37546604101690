import * as React from "react";
import { useEffect, useState } from "react";
import isAuthenticated from "../../../../components/Utils/isAuthenticated";
import {
	useFacultyRank,
	useAddInstructorToFacultyRank,
} from "../../../../Hooks/useFacultyRankQuery";
import FacultyRankAutocomplete from "../../../../components/Utils/FacultyRankAutocomplete";
import ListSelect from "../../../../components/Utils/ListSelect";
import { Button } from "@mui/material";

const roles = [
	"PI",
	"TA",
	"CC",
	"CO",
	"LA",
	"GL",
	"AI",
	"PG",
	"SG",
	"TC",
	"DA",
];

export default function ClassAdjustAddInstructor(props) {
	let auth = isAuthenticated();
	const token = auth.token;
	const { data: facultyRank } = useFacultyRank(token);

	const [deptId, setDeptId] = useState("");
	const [instructorRole, setInstructorRole] = useState("");
	const [existingRoles, setExistingRoles] = useState([]);
	const [newRoleValue, setNewRoleValue] = useState("");
	useEffect(() => {
		if (props.selectedClass?.dept) setDeptId(props.selectedClass.dept);
	}, [props.selectedClass]);

	const { addInstructorToFacultyRank, status: statusPostFacultyRank } =
		useAddInstructorToFacultyRank(token, deptId);

	const addInstructor = async (instructorToAdd) => {
		const success = await addInstructorToFacultyRank(instructorToAdd);
		return success;
	};

	const handleChangeRole = (event) => {
		setInstructorRole(event.target.value);
	};

	const handleAddInstructor = async () => {
		const { selectedClass, rows, setRows, originalRows, setUnsavedChanges } =
			props;
		const instructorToAdd = newRoleValue;

		let newRole = {};

		const updatedRows = rows.map((classObj) => {
			if (classObj.classid === selectedClass.classid) {
				newRole = {
					ccid: instructorToAdd.ccid,
					class_role: instructorRole,

					checked: false,
					classid: classObj.classid,
					disabled: false,
					empid: instructorToAdd.empid,
					name: instructorToAdd.name,
					first_name: instructorToAdd.name.split(", ")[0],
					last_name: instructorToAdd.name.split(", ")[1],
					role_description: "Primary Instructor",
					roleid: Number(instructorToAdd.empid) + classObj.classid,
					term: classObj.term,
					import_status: "MANUAL",
				};

				if (
					classObj.teamsize === 0 ||
					(classObj.teamsize !== 0 && classObj.roles.length === 0)
				) {
					return {
						...classObj,
						name: instructorToAdd.name,
						empid: instructorToAdd.empid,

						class_role: instructorRole,
						teamsize: 1,
						comb_classes: [],
						roles: [newRole],
					};
				} else {
					const updatedRoles = [...classObj.roles, newRole];
					const teamNames = updatedRoles
						.map((role) => `'${role.name}'`)
						.join(", ");
					return {
						...classObj,
						roles: updatedRoles,
						teamName: teamNames,
						teamsize: classObj.teamsize + 1,
					};
				}
			}
			return classObj;
		});

		// Create a new array instance to trigger re-render
		setRows([...updatedRows]);

		const isChanged =
			JSON.stringify(originalRows) !== JSON.stringify(updatedRows);
		if (!props.isInInstance) setUnsavedChanges(isChanged);

		if (!props.isInInstance) props.setAddInstructor(false);

		setInstructorRole("");
		/* await addRoles(newRole); */
		//if successful reset the search and role
	};

	const checkExistingFacultyRank = (empid) => {
		let exists = false;
		facultyRank.forEach((rank) => {
			if (Number(rank.empid) === Number(empid)) {
				exists = true;
			}
		});
		return exists;
	};

	return (
		<div>
			<div className="flex  w-full items-center gap-2 py-2">
				<FacultyRankAutocomplete
					roles={roles}
					options={facultyRank}
					currentRow={props.selectedClass}
					setExistingRoles={setExistingRoles}
					isInInstance={props.isInInstance}
					newRoleValue={newRoleValue}
					setNewRoleValue={setNewRoleValue}
					checkExistingFacultyRank={checkExistingFacultyRank}
					addInstructorToFacultyRank={addInstructor}
				/>
				<ListSelect
					required
					marginBottom="0"
					label="Role"
					value={instructorRole}
					onChange={handleChangeRole}
					list={roles}
					width={100}
				/>
			</div>
			<div className="flex items-center w-full gap-2">
				<button
					variant="contained"
					className="bg-red-500 text-white  py-2 rounded-md  hover:bg-red-600 w-1/2"
					onClick={() => props.setAddInstructor(false)}
				>
					Cancel
				</button>
				{props.isInInstance && (
					<button
						className="bg-white text-green-800  py-2 rounded-md hover:text-white hover:bg-green-900 w-1/2 border-2 border-green-900"
						onClick={() => {
							props.setAddInstructor(false);
						}}
					>
						Done
					</button>
				)}
				<Button
					variant="contained"
					disabled={!newRoleValue || !instructorRole}
					onClick={handleAddInstructor}
					sx={{ width: "50%" }}
				>
					Add
				</Button>
			</div>
		</div>
	);
}
